import React from 'react';
import ReactDOM from 'react-dom';
import _get from 'lodash/get';

import UPshowState from '../UPshowState';
import StateFactory from '../StateFactory';
import Logger from '../../Logger';

import iframeWithWhisper from '../iframe/WhisperIframe';
import Iframe from '../iframe/Iframe';

import { StatePreconditionError } from '../Errors';
import ApplicationService from '../../services/ApplicationService';
import { buildIframeUrl } from '../../libs/applications';
import AudioAnnouncementsService from '../../services/AudioAnnouncementsService';

class ApplicationState extends UPshowState {

    get name () {
        return `app-${_get(this.appInstance, 'application.name', 'application')}`;
    }

    async preload () {
        const currentId = _get(this.state, 'state.itemId', null);

        if (!currentId) {
            throw new StatePreconditionError('Application Id not found.');
        }

        this.appInstance = await ApplicationService.getApplicationInstance(currentId);

        if (this.appInstance && this.appInstance.application) {
            this.metricData = { ...this.metricData, id: parseInt(this.appInstance.application.id) };
        }

        if (!this.appInstance) {
            throw new StatePreconditionError('Application Instance was no provided');
        }

        this.instanceSettings = _get(this.appInstance, 'settings');
        this.iframeRef = null;
        this.iframeUrl = null;

        if (this.instanceSettings.audio && !this.instanceSettings.muted) {
            this.state.soundState.shouldSound = true;
            this.state.soundState.disableOverride = true;
            AudioAnnouncementsService.silence();
        }

        const appUrl = _get(this.appInstance, 'application.url');
        const appKey = _get(this.appInstance, 'app_key');

        if (!appUrl || !appKey) {
            throw new StatePreconditionError('url and app key are required');
        }

        this.iframeUrl = buildIframeUrl(this.appInstance);
        performance.mark(`state-preload-${this.name}`);
        return super.preload();
    }

    raiseReady (data) {
        let preloadMark = performance.getEntriesByName(`state-preload-${this.name}`, 'mark');

        if (!!preloadMark[0]) {
            performance.mark(`state-ready-${this.name}`);

            performance.measure('state-ready', `state-preload-${this.name}`, `state-ready-${this.name}`);

            const measures = performance.getEntriesByName('state-ready');
            const measure = measures[0];

            Logger.log(['state-ready-measure', this.name], `${this.name} state ready measure millis: ${measure.duration}`);

            // Clean up the stored markers.
            performance.clearMarks();
            performance.clearMeasures();
        }

        return super.raiseReady(data);
    }

    play () {
        Logger.debug('ApplicationState', `Will load url ${this.iframeUrl}`);
        this.metricData.start = new Date();

        try {
            const iframePromise = this.iframeRef.invoke('play');
            const timeOutPromise = new Promise((r, e) => setTimeout(() => e('timeout'), 5000));

            return Promise.race([iframePromise, timeOutPromise])
                .then((r) => {
                    Logger.debug('ApplicationState', `Called play and got ${r}`);
                    Logger.log(['ApplicationState', 'play'], `Called play ${this.name} with url ${this.iframeUrl}`);
                    this.state.playing = true;
                    return this.raisePlaying();
                })
                .catch((e) => {
                    Logger.error('ApplicationState', `errored with ${e.message ?? e}`);
                    this.raiseError(new Error('error application play'));
                });
        } catch (e) {
            Logger.error('ApplicationState', `Error ${e} calling play`);
            this.raiseError(new Error('error application play'));
        }

    }

    raiseDone (data) {
        super.raiseDone(data);
        this.iframeRef = null;
        const application = this.appInstance ? this.appInstance.application : {};

        Logger.log('ApplicationState', `ApplicationState ended: ${this.appInstance.application.id}`, { application });
    }

    raiseError (error) {
        super.raiseError(error);
        this.iframeRef = null;
    }

    raiseSkip (message) {
        super.raiseSkip(message);
        this.iframeRef = null;
    }

    pause () {
        Logger.info(['ApplicationState', 'pause'], 'Called pause - default behavior ' + this.name);
        this.state.playing = false;
        this._pauseDuration();
        this.raisePaused();
        return this.state.pausePromise;
    }

    _render (resolve) {
        const IframeComponent = iframeWithWhisper(Iframe);
        ReactDOM.render(<IframeComponent
            ref={(el) => this.iframeRef = el}
            disableWatchdog={true}
            onReady={this.raiseReady}
            onDone={this.raiseDone}
            onError={this.raiseError}
            onSkip={this.raiseSkip}
            onWarning={this.raiseWarning}
            src={this.iframeUrl}
        />, this.node);
    }

    static appliesTo (meta) {
        return true;
    }
}

StateFactory.register('application', ApplicationState, 10);

export default ApplicationState;
