import React, { Component } from 'react';
import _ from 'lodash';
import ReactPlayer from 'react-player';

import './SocialZoom.scss';
import { teslaCacheX } from '../../Upshow';
import HwFeatureService from '../../services/HwFeatureService';
import ScreenService from '../../services/ScreenService';
import ZoomCta from '../../components/Cta/ZoomCta/ZoomCta';
import Textfit from 'react-textfit';
import { StatePreconditionError } from '../Errors';
import { UpShowDataContext } from '../../UpshowContext';
import SayCheeseCta from '../../components/Cta/SayCheeseCta/SayCheeseCta';

class SocialZoom extends Component {
    static contextType = UpShowDataContext
    constructor (props) {
        super(props);
        this.state = {
            isVertical: ScreenService.isVertical,
            isVideo: _.get(this.props, 'post.type', 'image') === 'video',
            zoomLogo: _.get(this.props, 'zoomLogo', null),
        };
    }

    componentDidMount () {
        if (typeof this.props.onMounted === 'function') {
            this.props.onMounted();
        }
    }

    getContainerClassName () {
        let className = 'SocialZoom';
        className += this.state.isVertical ? ' vertical' : '';

        return className;
    }

    _getLogo () {
        let logo = !!this.state.zoomLogo ? this.state.zoomLogo : false;

        return (<div className={logo ? 'LogoZoom' : 'LogoZoom defaultLogoZoom'}>
            {logo ? <img alt="logo" src={logo}/> : <img alt="logo"/>}
        </div>);
    }

    errorHandler (err) {
        if (this.props.onError) {
            if (this.state.isVideo) {
                this.props.onError(err);
            } else {
                this.props.onError(new StatePreconditionError('Error loading image'));
            }
        }
    }

    loadHandler (data) {
        if (this.props.onReady) {
            this.props.onReady(data);
        }
        if (this.props.onPlay) {
            this.props.onPlay(data);
        }
    }

    readyHandler (data) {
        if (this.props.onReady) {
            this.props.onReady(data);
        }
    }

    playHandler (data) {
        if (this.props.onPlay) {
            this.props.onPlay(data);
        }
    }

    endedHandler (data) {
        if (this.props.onEnded) {
            this.props.onEnded(data);
        }
    }

    progressHandler (progress) {
        if (this.props.onProgress) {
            this.props.onProgress(progress);
        }
    }

    pauseHandler (pause) {
        if (this.props.onPause) {
            this.props.onPause(pause);
        }
    }

    durationHandler (duration) {
        if (this.props.onDuration) {
            this.props.onDuration(duration);
        }
    }

    _imageZoom () {
        return (
            <img
                className="ImageZoom"
                src={teslaCacheX(this.props.post.url)}
                alt={'Social Post Image - description' + this.props.post.description + ' - by ' + this.props.post.user?.userName}
                onError={this.errorHandler.bind(this)}
                onLoad={this.loadHandler.bind(this)}
            />
        );
    }

    _videoZoom () {
        const { contentVolume } = this.context;
        return (
            <ReactPlayer
                className="VideoZoom"
                height="100%"
                width="100%"
                volume={this.props.muted ? 0 : contentVolume}
                muted={this.props.muted}
                url={teslaCacheX(this.props.post.url)}
                playing={this.props.playing}
                onReady={this.readyHandler.bind(this)}
                onPlay={this.playHandler.bind(this)}
                onEnded={this.endedHandler.bind(this)}
                onError={this.errorHandler.bind(this)}
                onPause={this.pauseHandler.bind(this)}
                onProgress={this.progressHandler.bind(this)}
                onDuration={this.durationHandler.bind(this)}
            />
        );
    }

    render () {
        const { duration, post, playing, fullScreenCta } = this.props;
        const { isVertical } = this.state;

        if (fullScreenCta) {
            return <SayCheeseCta fullScreen={true} onError={this.props.onError} onLoad={this.loadHandler.bind(this)}/>
        }

        return (
            <div className={this.getContainerClassName()}>

                <div className="cta">

                    <ZoomCta
                        duration={duration}
                        playing={playing}
                        postDescription={post.description}
                        postNetwork={post.network}
                        postAuthor={post.user?.userName}
                        isVertical={isVertical}
                        onError={this.props.onError}
                    />

                </div>

                <div className="media">

                    <img
                        className={'background' + (HwFeatureService.isSupported('blur-background') ? ' blur' : ' opacity')}
                        src={teslaCacheX(this.props.post.thumbnail)}
                        alt="Social Post Background"
                    />

                    {this.state.isVideo ? this._videoZoom() : this._imageZoom()}

                    {!!post.user && <div className="attribution">
                        <i className={'icon-' + post.network}/>
                        <Textfit className="username" mode="single" max={58}> {post.user.userName} </Textfit>
                    </div>}
                    {this._getLogo()}

                </div>

            </div>
        );
    }
}

export default SocialZoom;
