import { getDeviceInfo } from '../Upshow';
import { gsap } from '@upshow/gsap';

const defaultDuration = 2;

function defaultAnimationProcess (timeLine, current) {
    return new Promise((resolve, reject) => {
        //animation timeout
        const timeout = setTimeout(() => reject('Animation Timeout'), 3000);

        timeLine.eventCallback('onComplete', () => {
            if (current.node) {
                current.node.classList.remove('hidden');
            }
            clearTimeout(timeout);
            resolve();
        });

        timeLine.play();
    });
}

export const Animators = {
    scrollRight: {
        animate (current, old) {
            const time = defaultDuration;

            const tl = gsap.timeline({ paused: true });

            gsap.set(current.node, { x: -1980, opacity: 1 });

            tl.to(current.node, { duration: time, x: 0 })
              .to(old.node, { duration: time, x: 1980 }, 0);

            return defaultAnimationProcess(tl, current);
        },

        get isSupported () {
            return getDeviceInfo().model !== 'BrightSign';
        }
    },

    scrollLeft: {
        animate (current, old) {
            const time = defaultDuration;

            const tl = gsap.timeline({ paused: true });

            gsap.set(current.node, { opacity: 1, x: 1980 });

            tl.to(current.node, { duration: time, x: 0 })
              .to(old.node, { duration: time, x: -1980 }, 0);

            return defaultAnimationProcess(tl, current);
        },

        get isSupported () {
            return getDeviceInfo().model !== 'BrightSign';
        }
    },

    appear: {
        animate (current, old) {
            const time = defaultDuration;

            const tl = gsap.timeline({ paused: true });

            tl.to(current.node, { duration: time, opacity: 1 })
              .to(old.node, { duration: time, opacity: 0 }, 0);

            return defaultAnimationProcess(tl, current);
        },

        get isSupported () {
            return !getDeviceInfo().model.startsWith('AF') && getDeviceInfo().model !== 'BrightSign';
        }
    }
};

function getAnimator (animation_name) {
    if (Animators[animation_name] && Animators[animation_name].isSupported) {
        return Animators[animation_name].animate;
    }
}

export default getAnimator;