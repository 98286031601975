import BackgroundMusicService from './BackgroundMusicService';
import StateService from './StateService';
import UpshowLogger from '../Logger';
import { volumeFading } from '../libs/volumeFading';
import { teslaCacheY } from '../Upshow';
import { ContentAudioService } from './ContentAudioService';
import SettingsService from './SettingsService';
import TrackerService from './TrackerService';

const DEFAULT_INTERVAL = parseInt(process.env.REACT_APP_AUDIO_ANNOUNCEMENT_FREQUENCY);

const createTimeout = () => {
    AudioAnnouncementsService.timer = setTimeout(() => {
        return AudioAnnouncementsService.run();
    }, AudioAnnouncementsService.frequencyInMin * 60 * 1000);
};

const checkFrequencyUpdate = () => {
    const frequencyInMin = SettingsService.getSetting('announcements_frequency') || DEFAULT_INTERVAL;

    if (frequencyInMin !== AudioAnnouncementsService.frequencyInMin) {
        UpshowLogger.debug(['AudioAnnouncements'], `Frequency updated to ${frequencyInMin}`);
        AudioAnnouncementsService.frequencyInMin = frequencyInMin;
        if (AudioAnnouncementsService.started && !AudioAnnouncementsService.announcementRunning) {
            clearTimeout(AudioAnnouncementsService.timer);
            createTimeout();
        }
    }
};

const AudioAnnouncementsService = {
    frequencyInMin: SettingsService.getSetting('announcements_frequency') || DEFAULT_INTERVAL,
    timer: null,
    currentAudio: null,
    announcements: [],
    started: false,
    announcementRunning: false,
    index: 0,
    start: () => {
        checkFrequencyUpdate();
        if (AudioAnnouncementsService.started || AudioAnnouncementsService.announcements.length === 0) return null;

        UpshowLogger.debug(['AudioAnnouncements'], `Started`, AudioAnnouncementsService.announcements);
        AudioAnnouncementsService.started = true;

        createTimeout();
    },
    run: async () => {
        if (AudioAnnouncementsService.announcements.length === 0) {
            AudioAnnouncementsService.started = false;
            return null;
        }

        if (AudioAnnouncementsService.announcements.length < (AudioAnnouncementsService.index + 1)) {
            AudioAnnouncementsService.index = 0;
        }

        const { disableOverride, shouldSound } = StateService.currentState.state.soundState;

        if (disableOverride && shouldSound) {
            return createTimeout();
        }

        UpshowLogger.debug(['AudioAnnouncements'], `Running Announcement`);
        let url = AudioAnnouncementsService.announcements[AudioAnnouncementsService.index];
        TrackerService.appEvents.logMetric({ action: 'audio_announcement', url });

        try {
            url = await teslaCacheY(AudioAnnouncementsService.announcements[AudioAnnouncementsService.index]);
        } catch (e) {
            UpshowLogger.warn(['AudioAnnouncements'], `can't cache ${url} because ${e}`);
        }

        const announcement = new Audio(url);
        announcement.onended = () => {
            UpshowLogger.debug(['AudioAnnouncements'], `Announcement Ended`);
            AudioAnnouncementsService.end();
        };
        announcement.onerror = (e) => {
            UpshowLogger.error(['AudioAnnouncements'], `Announcement Error`, e);
            AudioAnnouncementsService.end();
        };
        announcement.onloadedmetadata = () => {
            UpshowLogger.debug(['AudioAnnouncements'], `Announcement Loaded`);
            volumeFading((volume) => {
                AudioAnnouncementsService.currentAudio.volume = volume;
            }, 'in', AudioAnnouncementsService.currentAudio.duration);
        };

        BackgroundMusicService.setPause(true, true);
        ContentAudioService.decreaseAudioContent();
        AudioAnnouncementsService.announcementRunning = true;
        announcement.volume = 0;
        announcement.loop = false;
        if (window.sw_enabled) {
            announcement.crossOrigin = 'anonymous'
        }
        announcement.play();
        AudioAnnouncementsService.currentAudio = announcement;
    },
    silence: () => {
        if (AudioAnnouncementsService.currentAudio) {
            volumeFading((volume) => {
                AudioAnnouncementsService.currentAudio.volume = volume;
            }, 'out', AudioAnnouncementsService.currentAudio.duration);
        }
    },
    end: () => {
        AudioAnnouncementsService.currentAudio = null;
        AudioAnnouncementsService.announcementRunning = false;
        ContentAudioService.increaseAudioContent();
        const length = AudioAnnouncementsService.index + 1;
        if (length >= AudioAnnouncementsService.announcements.length) {
            AudioAnnouncementsService.index = 0;
        } else {
            AudioAnnouncementsService.index += 1;
        }

        createTimeout();
    }
};

window.audioAnnouncementsService = AudioAnnouncementsService;

export default AudioAnnouncementsService;