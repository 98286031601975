export function format_spotlight (raw_spotlight) {
    const spotlight = JSON.parse(JSON.stringify(raw_spotlight));

    if (!spotlight) return null;

    spotlight.spt_id = spotlight.id;

    return spotlight;
}

export function format_spotlight_schedule (raw_schedule) {
    const parsedSchedule = JSON.parse(JSON.stringify(raw_schedule));
    const spotlight = parsedSchedule.spotlight;

    const spt_id = spotlight.id;
    const properties = spotlight.properties;
    const requiresConnection = spotlight.background?.type === 'youtube';
    const TrackingCode = spotlight.TrackingCode;

    return { ...parsedSchedule, schedule_type: 'spotlight', properties, requiresConnection, TrackingCode, spt_id };
}