import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import './SocialGrid3.scss';
import TileGrid3 from '../../components/TileGrid3/TileGrid3';
import SettingsService from '../../services/SettingsService';
import ScreenService from '../../services/ScreenService';
import SayCheeseCta from '../../components/Cta/SayCheeseCta/SayCheeseCta';

class SocialGrid3 extends Component {

    constructor (props) {
        super(props);

        const imageContent = this.props.content.filter(content => content.type === 'image');
        const hasMinImages = imageContent.length >= 5;

        if (!hasMinImages) {
            this.fullScreenCta = true;
        }

        this.state = {
            longCta: SettingsService.getCta().long_cta,
            slimCta: SettingsService.getCta().slim_cta,
            columns: _.get(this.props, 'columns', ScreenService.isVertical ? 4 : 7),
            rows: _.get(this.props, 'rows', ScreenService.isVertical ? 7 : 4),
            playing: this.props.playing
        };
    }

    componentWillReceiveProps (newProps) {
        if (this.props.playing !== newProps.playing) {
            this.setState({
                playing: newProps.playing
            });
        }
    }

    componentDidMount () {
        this.props.onMounted();
    }

    getCtaDimensions (slimCta, longCta) {
        const dimensions = {};

        // calculate ctaWidth and ctaHeight
        if (slimCta) {
            dimensions.ctaWidth = 2;
            dimensions.ctaHeight = 3;
        } else if (longCta) {
            dimensions.ctaWidth = 4;
            dimensions.ctaHeight = 2;
        } else {
            dimensions.ctaWidth = 3;
            dimensions.ctaHeight = 3;
        }

        return dimensions;
    }

    render () {
        const { content, duration } = this.props;
        const { longCta, columns, rows, slimCta } = this.state;

        const { ctaWidth, ctaHeight } = this.getCtaDimensions(slimCta, longCta);

        if (this.fullScreenCta) return <SayCheeseCta onError={this.props.onError} fullScreen={true}/>

        return (
            <div className="SocialGrid3">
                <TileGrid3
                    playing={this.state.playing}
                    content={content}
                    duration={duration}
                    showCta={true}
                    maxBoxSize={_.get(this.props, 'maxBoxSize', 2)}
                    columns={columns}
                    rows={rows}
                    ctaHeight={ctaHeight}
                    ctaWidth={ctaWidth}
                    shouldTileFlip={_.get(this.props, 'shouldTileFlip', true)}
                    relaxed={_.get(this.props, 'relaxed', false)}
                    onError={this.props.onError}
                />
            </div>
        );
    }

}

SocialGrid3.propTypes = {
    onMounted: PropTypes.func,
    content: PropTypes.array.isRequired,
    duration: PropTypes.number.isRequired,
    relaxed: PropTypes.bool.isRequired,
};

SocialGrid3.defaultProps = {
    onMounted: () => null,
    relaxed: false
};

export default SocialGrid3;
