import React from 'react';
import PropTypes from 'prop-types';
import { Textfit } from 'react-textfit';
import SlimAnimatedGridCta from './SlimAnimatedGridCta';
import { getSocialBrandKitStyle } from "../../../../libs/helpers";

import './DefaultTextCta.scss';


const ZoomCTA = (props) => {
    const { type, className, headerText, hashtags } = props;
    const { backgroundColor, color, logo } = getSocialBrandKitStyle();
    return <div className={`DefaultTextCta ${className} ${type} `} style={{backgroundColor, color}}>
                <div className="header" style={{ borderBottom: `3px solid ${color}`}}>
                    { logo && <div className="social-logo-container">
                            <img src={logo} className="social-cta-logo" />
                    </div>}
                    <div className="cta-container">
                        <Textfit
                            className={'CtaText'}
                            max={148}>
                                {headerText}
                        </Textfit>
                    </div>
                </div>
                <div className="footer" >
                        <div className="top">
                            <span>Post on <span className="icon network-ig" /> with</span>
                        </div>
                        <div className="bottom">
                            <Textfit
                                className="CtaHashtags"
                                max={1000}>
                                <span className="hashtags">
                                    {hashtags.map((hashtag, i) => <div key={i} >#{hashtag}</div>)}
                                </span>
                            </Textfit>
                        </div>
                </div> 
            </div>;
};

class DefaultTextCta extends React.Component {

    render () {
        const { type, operator, hashtags } = this.props;

        const isWithBoth = operator === 'and' && hashtags.length === 2;
        const slicedHashtags = hashtags.slice(0,2);

        if (type === 'zoom') return <ZoomCTA {...this.props} isWithBoth={isWithBoth} hashtags={slicedHashtags}/>;

        return <SlimAnimatedGridCta {...this.props} isWithBoth={isWithBoth} hashtags={slicedHashtags}/>;
    }
}

DefaultTextCta.propTypes = {
    className: PropTypes.string,
    headerText: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    hashtags: PropTypes.arrayOf(PropTypes.string).isRequired,
    longCta: PropTypes.bool,
    isAltCta: PropTypes.bool,
    relaxed: PropTypes.bool,
};

DefaultTextCta.defaultProps = {
    className: '',
    ctaText: 'GET ON</br>THIS</br>SCREEN!',
    longCta: false,
    isAltCta: false,
    relaxed: false,
};

export default DefaultTextCta;