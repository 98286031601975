import _ from 'lodash';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'react-app-polyfill/stable';

import UpshowLogger from './Logger';
import AppEvents from './AppEvents';
import Activation from './Activation';
import * as UP from './Upshow';
import TrackerService from './services/TrackerService';
import fit_ui_container from './libs/fit_ui_container';
import initializeApplication from './libs/init';
import SocketService from './services/SocketService';

import './Common';
import './index.scss';

fit_ui_container(); // Fits the body to have the correct aspect ratio (also handles overscan)

window.onresize = fit_ui_container;
window.UP = UP;
const url = new URL(window.location.href);
window.inApp = url.searchParams.has('app_version'); // This is reused on different components, do not delete
window.sw_enabled = (!window.inApp || url.searchParams.has('sw_enabled')) && !url.searchParams.has('disableServiceWorker');
window.demo = url.searchParams.has('ui.demo');

// Log uncaught errors
window.addEventListener('error', function (e) {
    UpshowLogger.error('UncaughtError', e.error);
    TrackerService.appEvents.logMetric({ action: 'error', error_message: e.message, error_stack: e.stack, uncaught: true });
});

if (window.sw_enabled) {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.addEventListener('controllerchange', () => {
            window.location.reload();
        });

        navigator.serviceWorker.addEventListener('message', (event) => {
            if (event.data) {
                switch (event.data.type) {
                    case 'QUOTA_ESTIMATE':
                        const { quota, usage } = event.data.quota_estimate;
                        TrackerService.appEvents.logMetric({ action: 'sw_quota', quota, usage });
                        break;
                    case 'GET_KEYS':
                        TrackerService.appEvents.logMetric({ action: 'sw_keys', keys: event.data.requests });
                        break;
                    case 'LOG':
                        UpshowLogger.log('service-worker', event.data.message);
                    default:
                    // console.log('Event ',{event})
                }
            }
        });

        setTimeout(() => {
            navigator.serviceWorker.getRegistrations().then(function (registrations) {
                for (let registration of registrations) {
                    if (registration.active && !registration.active.scriptURL.includes(`${process.env.PUBLIC_URL}/service-worker.js`)) {
                        console.log(`Unregistering ${registration.active.scriptURL}`);
                        registration.unregister();
                    }
                }
            });
        }, 10000);
    }

    serviceWorkerRegistration.register();
}

if (url.searchParams.has('disableServiceWorker')) {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
            console.log(`A service worker is active: ${registration.active}`);
            registration.unregister().then((boolean) => {
                if (boolean) {
                    console.log('The service worker was unregistered');
                }
            });
        });
    }
}

(async function main () {
    const activator = new Activation();
    const api_token = await activator.start(); // Asks for activation or token

    UP.setToken(api_token); // Add the token to our API manager
    
    const appEvents = new AppEvents(); // Creating an instance of App Events
    TrackerService.appEvents = appEvents; // Adding app events to the Tracker Service
    appEvents.logMetric({ action: 'start', ui_version: UP.getDeviceInfo().uiVersion });
    
    if (!window.demo && !window.inactive && !window.static) SocketService.connectToSocket(api_token);

    UpshowLogger.log('autoactivate', 'API TOKEN ' + api_token);
    if (window.UPshowAndroidApp) UPshowAndroidApp.setApiKey(api_token);

    await initializeApplication(); // Initialize the application
})();

if ('wakeLock' in navigator && !window.UPshowAndroidApp) {

    let wakeLock = null;

    const requestWakeLock = async () => {
        try {
            wakeLock = await navigator.wakeLock.request('screen');
            UpshowLogger.debug(['requestWakeLock'], wakeLock);
        } catch (err) {
            UpshowLogger.error(['requestWakeLock'], err);
        }
    };

    // Request a screen wake lock…
    requestWakeLock();

    const handleVisibilityChange = async () => {
        if (wakeLock !== null && document.visibilityState === 'visible') {
            await requestWakeLock();
        }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
} // Screen Wake Lock API supported

window.pingCallback = function pingCallback (callback, payload) {
    UpshowLogger.debug('pingCallback', payload);
    setTimeout(() => _.invoke(window, callback, payload), 0);
};
