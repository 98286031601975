import { contentCrossFade, volumeFading } from '../libs/volumeFading';

export const ContentAudioService = {
    audioChannels: {
        ContentAudio: {
            currentVolume: 0,
        }
    },
    increaseAudioContent: () => {
        const volumeCallback = (volume) => {
            ContentAudioService.audioChannels.ContentAudio.currentVolume = volume;
        };

        contentCrossFade(volumeCallback, 'in');
    },
    decreaseAudioContent: () => {
        if (ContentAudioService.audioChannels.ContentAudio.currentVolume === 0) return;

        volumeFading((volume) => {
            ContentAudioService.audioChannels.ContentAudio.currentVolume = volume;
        }, 'out');
    }
};
