import { useState, useEffect } from 'react';
import ReactPlayer from 'react-player/file';
import { teslaCacheY } from '../../Upshow';
import audio_debug from '../../libs/audio_debug';
import BackgroundMusicService from '../../services/BackgroundMusicService';
import UpshowLogger from '../../Logger';

const randomize = (array = []) => {
    if (array.length > 0) {
        return array.sort(() => 0.5 - Math.random());
    }
    return [];
};

const AudioStats = props => {
    const { playing, cachedUrl, played, loadedSeconds, loaded, volumeState, contentInfo } = props;
    return (
        <div id="audio-stats">
            <p>Track: {playing ? cachedUrl : 'from content'}</p>
            <p>Background music playing: {playing.toString()}</p>
            {playing && <>
                <p>Played: {played}</p>
                <p>Loaded seconds: {loadedSeconds}</p>
                <p>Loaded: {loaded}</p>
            </>}
            <p>Volume: {playing ? volumeState : contentInfo?.volume}</p>
            <p>State with sound: {contentInfo?.soundState.shouldSound ? 'Yes' : 'No'}</p>
            <p>State disableOverride: {contentInfo?.soundState.disableOverride ? 'Yes' : 'No'}</p>
        </div>
    );
};

const AudioComponent = ({ playlist, playingParam, volume = 1, onWatchdogCallback, debugAudio, onReady, setWatchdog, clearWatchdog, contentInfo }) => {
    const [playing, setPlaying] = useState(false);
    const [songs, setSongs] = useState([]);
    const [index, setIndex] = useState(0);
    const [cachedUrl, setCachedUrl] = useState('');
    const [source, setSource] = useState(null);
    const [played, setPlayed] = useState(null);
    const [loadedSeconds, setLoadedSeconds] = useState(null);
    const [loaded, setLoaded] = useState(null);
    const [volumeState, setVolumeState] = useState(volume);

    const reactConfig = {
        file: {
            hlsOptions: {
                xhrSetup: function (xhr, url) {
                    xhr.withCredentials = true; // send cookies
                }
            }
        }
    };

    if (debugAudio) {
        reactConfig.file.attributes = {
            crossOrigin: 'true',
            id: 'music-player'
        };
    }

    const onReadyHandler = (event) => {
        if (debugAudio) {
            if (!source) {
                const internalElement = event.getInternalPlayer();
                const audioContext = new AudioContext();
                const src = audioContext.createMediaElementSource(internalElement);
                setSource(src);
                src.connect(audioContext.destination);
                audio_debug(debugAudio, src, audioContext);
            }
        }
        onReady(event);
    };

    useEffect(() => {
        setPlaying(playingParam);
        if (BackgroundMusicService.forwardOnPause && playingParam && songs.length > 0) {
            onEnded();
        }
    }, [playingParam]);

    useEffect(() => {
        setSongs(randomize([...playlist]));
    }, [playlist]);

    useEffect(() => {
        setVolumeState(volume);
    }, [volume]);

    useEffect(() => {
        const init = async () => {
            let url = songs[index];

            if (url) {
                if (BackgroundMusicService.isPremiumMusic() || debugAudio) {
                    UpshowLogger.debug(['BackgroundMusicService'], 'caching skipped for asset ' + url);
                } else {
                    try {
                        url = await teslaCacheY(songs[index]);
                        UpshowLogger.debug(['BackgroundMusicService'], 'cached asset ' + songs[index]);
                    } catch (e) {
                        UpshowLogger.warn(['BackgroundMusicService'], `can't cache ${url} because ${e}`);
                    }
                }

                setCachedUrl(url);
            }
        };

        init();
    }, [index, songs]);

    const onError = function (e) {
        UpshowLogger.error(['BackgroundMusicService'], `Error on react player ${e}`);
        setWatchdog(`Error starting new playlist because ${e}`);
    };

    const onEnded = function () {
        clearWatchdog();
        const len = songs.length - 1;

        if (index === len) {
            const newOrder = randomize(songs);
            setSongs(newOrder);
            setIndex(0);
        } else {
            setIndex(index + 1);
        }

        setWatchdog(`starting playing ${playing} index ${index} songs ${songs} cachedUrl ${cachedUrl}`);
    };

    const onPlay = function () {
        UpshowLogger.debug(['BackgroundMusicService'], `starting playing index ${index} song ${songs[index]} cachedUrl ${cachedUrl}`);
    };

    const onProgress = ({ playedSeconds, played, loadedSeconds, loaded }) => {
        onWatchdogCallback(playing, `in second ${playedSeconds} playing ${playing} index ${index} songs ${songs} cachedUrl ${cachedUrl}`);
        setPlayed(played);
        setLoadedSeconds(loadedSeconds);
        setLoaded(loaded);
    };

    return (
        <>
            <ReactPlayer
                onPlay={onPlay}
                url={cachedUrl}
                onReady={onReadyHandler}
                onError={onError}
                onEnded={onEnded}
                volume={volumeState}
                playing={playing}
                onProgress={onProgress}
                onPause={clearWatchdog}
                config={reactConfig}
            />
            {debugAudio &&
                <AudioStats
                    playing={playing}
                    cachedUrl={cachedUrl}
                    played={played}
                    loadedSeconds={loadedSeconds}
                    loaded={loaded}
                    volumeState={volumeState}
                    contentInfo={contentInfo}
                />}
        </>
    );
};

export default AudioComponent;