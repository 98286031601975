import React from 'react';
import ReactDOM from 'react-dom';
import UPshowState from '../UPshowState';
import StateFactory from '../StateFactory';
import LiveEvent from './LiveEvent';
import StateService from '../../services/StateService';
import AudioAnnouncementsService from '../../services/AudioAnnouncementsService';
import { setAuthCookies } from '../../libs/helpers';

export default class LiveEventState extends UPshowState {

    get name () {
        return 'live_event';
    }

    playerError = false;

    onError = (error) => {
        console.log('Fatal player error', error.message);
        this.playerError = true;
        this.renderLiveEvent();
    };

    solveError = (message) => {
        console.log('Fatal player error solved', message);
        this.playerError = false;
        this.renderLiveEvent();
    };

    renderLiveEvent () {
        ReactDOM.render(
            <LiveEvent
                {...this.state}
                playerError={this.playerError}
                done={() => { StateService.flagFinishedLiveEvent(this.state.state.liveEvent.id);}}
                error={this.onError}
                warning={(warning) => { console.error('warning', warning); }}
                solveError={this.solveError}
            />, this.node);
    }

    async preload () {
        const metadata = this.state.state.liveEvent.metadata;
        if (metadata.isSecure && metadata.secureUrl) {
            const { data } = await setAuthCookies(metadata.secureUrl);
            
            if (metadata.hasDrm) {
                console.log("save drm token");
                this.state.state.liveEvent.token = data.signed;
            }
        }

        this.state.soundState.shouldSound = true;
        this.state.soundState.disableOverride = true;
        AudioAnnouncementsService.silence();

        this.renderLiveEvent();
        return super.preload();
    }
}

StateFactory.register('live_event', LiveEventState, 10);
