import React from 'react';
import ReactDOM from 'react-dom';
import UPshowState from '../UPshowState';
import StateFactory from '../StateFactory';

function Inactive () {
    return (
		<div className="EverpassInactive">
            <div className='content'>
                <div>
                    <div className="first">
                        Please contact EverPass Support
                        <br />
                        to reactivate your device
                    </div>
                    <div className="second">
                        <p>Phone 855-787-7469</p>
                        <p>Email support@everpass.com</p>
                    </div>
                </div>
                <div className="logo">
                    <img alt="logo" src="https://cdn.upshow.tv/ui-assets/EP_PBU_Logo.png" />
                </div>
            </div>
		</div>
	);
}

class InactiveState extends UPshowState {

    get name () {
        return 'inactive';
    }

    preload () {
        ReactDOM.render(<Inactive/>, this.node);

        return super.preload();
    }
}

StateFactory.register('inactive', InactiveState, 10);

export default InactiveState;
