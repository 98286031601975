import _ from 'lodash';
import ScriptService from './ScriptService';
import StateService from './StateService';
import ApplicationService from './ApplicationService';

const OTWT_PLUGIN_IDS = process.env.REACT_APP_OTWT_PLUGIN_ID.split(',').map(id => parseInt(id));

let takeoverQueue = [];

async function getGranularOTWTApplicationInstance (script, appId) {
    const granular_application_instances = _.uniq(_.flatten(script
        .filter(script => script.state === 'application' && script.metadata && script.metadata.id && script.metadata.id.length > 0)
        .map(script => script.metadata.id)));

    for (let app_instance_id of granular_application_instances) {
        const appInstance = await ApplicationService.getApplicationInstance(app_instance_id);
        if (appInstance.application.id === appId) {
            return appInstance.id;
        }
    }

    return false;
}

async function takeOverOTWT (appId, forceAdvanceState = false) {
    const appInstanceId = await getGranularOTWTApplicationInstance(ScriptService.getActiveScheduledScript(), appId);

    if (appInstanceId) {
        StateService.otwtInstanceId = appInstanceId;
        if (forceAdvanceState) {
            return StateService.advanceState();
        }
    } else { //When it is not set in the current playlist as granular, we ignore it
        takeoverQueue = takeoverQueue.slice(1);
    }
}

/**
 * Handler for a notification of OTWT takeover (from the command processor)
 * @param id
 */
export function handleNewTakeover (id) {
    const appId = parseInt(id);

    if (
        takeoverQueue.length === 0 && // If we are the first takeover and
        !OTWT_PLUGIN_IDS.includes(StateService.currentState?.AppInstance?.application.id) //we are not playing the otwt plugin already
    ) {
        return takeOverOTWT(appId, true);
    } else { // Add the new takeover to the queue
        takeoverQueue.push(appId);
    }
}

/**
 * Handler for queued OTWT takeovers (used on the StateService when advancing the state)
 * This will run on every new advance state, if we have an enqueued otwt takeover then it will
 * @param oldState
 * @returns {Promise<void>}
 */
export function handleOTWTTakeovers () {
    if (takeoverQueue.length > 0 && !StateService.otwtInstanceId) {
        const appId = takeoverQueue.shift();
        return takeOverOTWT(appId);
    }
}