import * as UP from './Upshow';
import CommandProcessor from './CommandProcessor';
import UpshowLogger from './Logger';
import axios from 'axios';

const ANALYTICS_URL = process.env.REACT_APP_ANALYTICS_URL;

const MAX_ANALYTICS_BUFFER_LENGTH = 2_000;
const MAX_ANALYTICS_EVENTS = 50;

const commandsProcessor = new CommandProcessor();

class AppEvents {
    eventBuffer = [
        {
            name: 'event.heartbeat',
            ts: Date.now()
        }
    ];

    analyticsBuffer = [];
    metricsBuffer = [];
    errorCount = 0;
    pollingInterval = 3_000;

    constructor () {
        this.addEvent('Started app events');

        this.pollBackend();

        // send heartbeat to google analytics every 5 min
        setInterval(
            () => window.gtag('event', 'heartbeat', { non_interaction: true }),
            5 * 60 * 1_000
        );

        UpshowLogger.writers.push((tags, payload, level, error, extra) => {
            let procTags = typeof tags !== 'string' ? tags.join(',') : tags;

            const errorPayload = (error && error.stack && level !== -1) ? (error.message ? error.message + '\n' + error.stack : error.stack) : typeof payload === 'string' ? payload : JSON.stringify(payload);

            this.addEvent(UpshowLogger.loglevelToString(level) + ':: ' + procTags + ':: ' + errorPayload, extra);
        });

        UpshowLogger.writers.push((tags, payload, level, error, extra) => {
            if (tags === 'UncaughtError') return;

            if (!error && typeof payload === 'object') {
                error = payload;
                payload = undefined;
            }

            if (!payload && error?.payload) {
                payload = error.payload;
            }

            if (level === -2) {
                this.logMetric({ action: 'error', tags, payload, error, extra, uncaught: false });
            }
            if (level === -1) {
                this.logMetric({ action: 'warning', tags, payload, error, extra });
            }
        });

    }

    addEvent (name, extra) {
        if (window.st || window.inactive || window.demo) return; // Do not store on event Buffer if we are in static mode

        this.eventBuffer.push({
            name,
            stuff: extra,
            device_uptime: UP.getUptime(),
            ts: Date.now()
        });
    }

    logAnalyticsEvent (id, event) {
        if (window.st || window.inactive || window.demo) return; // Do not store on analytics Buffer if we are in static mode

        if (event && event.uid) {
            event.uid = String(event.uid);
        }

        this.analyticsBuffer.push({ ts: Date.now(), analytic: event, id: UP.sessionId + '-' + id });
    }

    logMetric (metric) {
        if (window.st || window.inactive || window.demo) return; // Do not store on analytics Buffer if we are in static mode

        if (typeof metric.payload === 'object') {
            if (metric.payload.stack) metric.error_stack = metric.payload.stack;
            if (metric.payload.message) metric.error_message = metric.payload.message;
            delete (metric.payload);
        }
        if (typeof metric.error === 'object') {
            if (metric.error.stack) metric.error_stack = metric.error.stack;
            if (metric.error.message) metric.error_message = metric.error.message;
            if (metric.error.payload && !metric.payload) metric.payload = metric.error.payload;
            delete (metric.error);
        }
        this.metricsBuffer.push({ ts: Date.now(), metric: { session: UP.sessionId, ...metric } });
    }

    pollBackend () {
        const accessToken = UP.getToken();
        if (!accessToken) return; // Do not poll backend if we don't have an accessToken

        if (window.st || window.inactive || window.demo) return; // Do not poll backend if we are in static mode
        let events = this.eventBuffer;
        this.eventBuffer = [
            {
                name: 'event.heartbeat',
                ts: Date.now()
            }
        ];

        const analytics = this.analyticsBuffer.splice(0, MAX_ANALYTICS_EVENTS);
        const metrics = this.metricsBuffer.splice(0, MAX_ANALYTICS_EVENTS);

        const bodyObj = {
            accessToken,
            DeviceInfo: UP.getDeviceInfo(),
            ts: Date.now(),
            events,
            analytics: analytics.length > 0 ? analytics : undefined,
            metrics: metrics.length > 0 ? metrics : undefined
        };

        axios.post(ANALYTICS_URL, JSON.stringify(bodyObj), { timeout: 10_000 })
            .then(result => {
                UP.setConnected(true);
                this.errorCount = 0;

                result.data?.commands?.forEach(command => commandsProcessor.process(command, 'app-events'));
            })
            .catch(error => {
                this.errorCount++;

                this.logMetric({ action: 'error', error, tags: ['AppEvents'], uncaught: false });

                UP.setConnected(false);

                if (!error.message.includes('timeout')) {
                    // Only if the error is other than timeout I want to restore the buffer

                    this.analyticsBuffer = analytics.concat(this.analyticsBuffer);
                    //Drop analytics if buffer is too long
                    if (this.analyticsBuffer.length > MAX_ANALYTICS_BUFFER_LENGTH) {
                        this.analyticsBuffer = this.analyticsBuffer.slice(-MAX_ANALYTICS_BUFFER_LENGTH);
                    }

                    this.metricsBuffer = metrics.concat(this.metricsBuffer);
                    //Drop metrics if buffer is too long
                    if (this.metricsBuffer.length > MAX_ANALYTICS_BUFFER_LENGTH) {
                        this.metricsBuffer = this.metricsBuffer.slice(-MAX_ANALYTICS_BUFFER_LENGTH);
                    }
                }
            })
            .finally(() => {
                const interval = Math.min(this.pollingInterval * (this.errorCount + 1), 60_000);
                window.setTimeout(() => this.pollBackend(), interval);
            });
    }
}

export default AppEvents;
