import * as UP from '../Upshow';
import SwearJarService from './SwearJarService';
import UpshowLogger from '../Logger';
import HwFeatureService from './HwFeatureService';
import _clone from 'lodash/clone';

// This could be a env or even better UI setting
const DEFAULT_UPSHOWNOW_CHANNEL_TTL = 30; // In minutes

function reformatContent (content) {
    return _clone(content).reduce(function (sum, post) {
        if (post.type === 2 && !HwFeatureService.isSupported('upshownow_videos')) return sum;

        post.description = post.description.replace(/[^\s]#/g, ' #');

        const hasSwear = SwearJarService.hasSwears(post.description);
        if (hasSwear) {
            UpshowLogger.info(['upshownow', 'censor'], `Censoring post ${post.id} because of swearword`);
            return sum;
        }

        post.url = post.url.replace(/^http:\/\//i, 'https://');
        post.thumbnail = post.thumbnail.replace(/^http:\/\//i, 'https://');

        sum.push(post);
        return sum;
    }, []);
}

function getCached (channel_id) {
    const now = Date.now();
    const cacheRaw = localStorage.getItem('upshownow-' + channel_id);
    try {
        const cache = JSON.parse(cacheRaw);
        if (cache && cache.ttl > now) return cache.channel;
    } catch (error) {
        UpshowLogger.error(['UPshowNowService', 'getCached'], error);
    }
}

function setCached (channel, ttl = DEFAULT_UPSHOWNOW_CHANNEL_TTL) {
    const now = Date.now();
    localStorage.setItem('upshownow-' + channel.id, JSON.stringify({ channel, ttl: now + ttl * 60000 }));
}

const UPshowNowService = {
    getChannel: (id) => {
        const cached_channel = getCached(id);
        if (cached_channel) return cached_channel;
        return UP.getUpshowNowChannel(id)
            .then((result) => {
                const { upshownow_channel: channel } = result;
                if (channel) setCached(channel);
                return channel;
            });
    },
    getChannelContent: async (channel) => {
        let content = [];
        if (channel?.content) {
            content = reformatContent(channel.content);
            if (channel.random) content = _.shuffle(content);
            return content;
        } else {
            const upshownow_channel = await UPshowNowService.getChannel(channel.id);
            if (upshownow_channel) {
                return UPshowNowService.getChannelContent(upshownow_channel);
            } else {
                return [];
            }
        }
    },
};

export default UPshowNowService;

