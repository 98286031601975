import Logger from '../Logger';
import _ from 'lodash';

import * as UP from '../Upshow';
import ContainerService from '../services/ContainerService';
import UpNextService from '../services/UpNextService';
import ScriptService from '../services/ScriptService';
import StateFactory from '../states/StateFactory';
import SettingsService from '../services/SettingsService';
import FixedContentService from './FixedContentService';
import TakeoverService from '../services/TakeoverService';

// eslint-disable-next-line
import SocialZoomState from '../states/socialzoom/SocialZoomState';
// eslint-disable-next-line
import SocialGrid3State from '../states/socialgrid3/SocialGrid3State';
// eslint-disable-next-line
import SocialGrid3SmallState from '../states/socialgrid3/SocialGrid3SmallState';
// eslint-disable-next-line
import SpotlightState from '../states/spotlight/SpotlightState';
// eslint-disable-next-line
import PlutoTVState from '../states/plutotv/PlutoTVState';
// eslint-disable-next-line
import LoadingState from '../states/loading/LoadingState';
// eslint-disable-next-line
import IframeState from '../states/iframe/IframeState';
// eslint-disable-next-line
import TriviaState from '../states/trivia/TriviaState';
// eslint-disable-next-line
import UPshowNow2State from '../states/upshownow2/UPshowNow2State';
// eslint-disable-next-line
import VistarState from '../states/vistar/VistarState';
// eslint-disable-next-line
import ApplicationState from '../states/application/ApplicationState';
// eslint-disable-next-line
import JukinState from '../states/jukin/JukinState';
// eslint-disable-next-line
import LiveEventState from '../states/live_event/LiveEventState';
// eslint-disable-next-line
import WatchToWinState from '../states/watch-to-win/WatchToWinState';
// eslint-disable-next-line
import InactiveState from '../states/inactive/InactiveState';
// eslint-disable-next-line
import EmptyState from '../states/empty/EmptyState';

import { randomStart } from '../libs/randomize_start';
import TrackerService from './TrackerService';
import { fadeBGMusic } from '../libs/volumeFading';
import MediaService from './MediaService';
import SpotlightService from './SpotlightService';
import ScreenService from './ScreenService';
import { handleOTWTTakeovers } from './OTWTService';

const StateService = {
    settings: [],
    hasWatchToWinSettings: false,
    nextStateIdx: -1,
    state: { name: 'loading', duration: 5 },
    currentStateDef: { name: 'loading', duration: 10, takeover: false },
    loaded: false,
    liveEvent: null,
    liveEventLastSyncTS: null,
    script: [],
    disableig: false,
    spotlightOnly: false,
    otwtInstanceId: false,
    smartPlaylist: false,
    inactive: false,
    scriptHasContent: true,

    stop: function () {
        StateService.inactive = true;
        window.inactive = true;
    },

    setScript: function (script) {
        StateService.nextStateIdx = -1;
        StateService.script_stringified = JSON.stringify(script);
        script = randomStart(script);
        Logger.debug(['upshowstate', 'loadscript'], StateService.script_stringified);
        if (StateService.spotlightOnly) {
            Logger.log(['upshowstate'], 'Spotlight only flag detected, overriding script . ');
            StateService.script_stringified = JSON.stringify([{ name: 'spotlight', duration: 86400 }]);
            StateService.script = [{ name: 'spotlight', duration: 86400 }];
            StateService.disableig = true;
        } else {
            StateService.script = script.map(function (state) {
                state.name = state.state;
                state.duration = state.time;
                return state;
            });
        }
    },

    isPlayingLiveEvent () {
        return StateService.currentStateDef?.name === 'live_event';
    },

    updateSettings () {
        StateService.spotlightOnly = SettingsService.hasTrueUiSetting('spotlight_only');
        StateService.disableig = !SettingsService.hasTrueUiSetting('spotlight_only') && SettingsService.hasTrueUiSetting('disable_ig');
    },
};

StateService.nextStateQueue = [];

StateService.queueState = function (state) {
    if (StateService.nextStateQueue.find((item) => item.name === state.name)) {
        //if we already have queued a state of the same kind, do nothing
        Logger.log(['StateService', 'instantgratification'], 'Queuing state ' + state.name + '. Not queued. There is already same state in the queue.');
        return false;
    } else {
        let nextStateIdx = (StateService.nextStateIdx + 1) % StateService.script.length;
        let nextStateDef = Object.assign({}, StateService.script[nextStateIdx]);
        if (nextStateDef.name === state.name) {
            Logger.log(['StateService', 'instantgratification'], 'Queuing state ' + state.name + '. Not queued. Same state is upcoming.');
            return false;
        } else {
            Logger.log(['StateService', 'instantgratification'], 'Queuing state ' + state.name + '. Success.');
            StateService.nextStateQueue.push(state);
            return true;
        }

    }

};
StateService.correlativeNumber = 1;
StateService.nextSchMediaIdx = 0;

StateService.createNextState = async function createNextState () {
    const takeoverSchedule = TakeoverService.getNextTakeOverSchedule();
    let takeoverData;

    // Main hierarchy for what is going to be displayed next
    if (StateService.inactive) {
        StateService.currentStateDef = {
            name: 'inactive', duration: -1
        };
    } else if (StateService.liveEvent?.id) { //Live events over all (except inactive)
        StateService.currentStateDef = {
            name: 'live_event', duration: -1, liveEvent: StateService.liveEvent
        };
        Logger.debug(['StateService', 'createnextstate'], 'Create next state for live event.');
    } else if (!SettingsService.hasFeature('dr_time_based') && !!takeoverSchedule) { //Scheduled Takeovers only without dr_time_based feature flag
        takeoverData = _.cloneDeep(takeoverSchedule);
        StateService.currentStateDef = {
            name: takeoverData.schedule_type, takeover: true, duration: -1, source: 'scheduled_takeover', takeoverData, allowsIg: false,
        };
        Logger.debug(['StateService', 'createnextstate'], 'Create next state for scheduledmedia ' + StateService.currentStateDef.name);
        StateService.lastStateSource = 'scheduled_takeover';
    } else if (StateService.nextStateQueue.length > 0) { //States in queue
        StateService.currentStateDef = Object.assign({}, StateService.nextStateQueue.shift());
        Logger.debug(['StateService', 'createnextstate'], 'Create next state from queue ' + StateService.currentStateDef.name);
        StateService.lastStateSource = 'queue';
    } else if (StateService.otwtInstanceId) { //OTWT takeover
        Logger.debug(['StateService', 'createnextstate'], 'Create next state from OTWT instance ' + StateService.otwtInstanceId);

        StateService.currentStateDef = {
            metadata: { id: [StateService.otwtInstanceId] }, name: 'application', duration: -1, allowsIg: false
        };
        StateService.otwtInstanceId = false;
    } else { //Normal behavior
        if (StateService.scriptHasContent) {
            StateService.nextStateIdx = (StateService.nextStateIdx + 1) % StateService.script.length;
            StateService.currentStateDef = StateService.script[StateService.nextStateIdx];
            Logger.debug(['StateService', 'createnextstate'], 'Create next state from script ' + StateService.currentStateDef?.name);
            StateService.lastStateSource = 'script';
        } else {
            StateService.currentStateDef = {
                name: 'empty_state', duration: -1
            };
        }
    }

    if (!StateService.currentStateDef) {
        StateService.currentStateDef = {
            name: 'empty_state', duration: -1
        };
        Logger.log(['StateService', 'createnextstate'], 'No available state to create. Create empty state.');
    }

    const name = StateService.currentStateDef.name;
    let source;
    const currentId = ScriptService.getCurrentGranularityId(StateService.currentStateDef);

    switch (name) {
        case 'plutotv':
        case 'jukin':
        case 'upshownow':
            if (ScreenService.isVertical) return;

            const { channel_id, source: mediaChannelSource } = getMediaChannelInfo(takeoverData, StateService.currentStateDef, currentId);

            if (!channel_id) {
                Logger.info(['StateService', 'createnextstate'], `No available Channel found for state ${name}, source ${mediaChannelSource}`);
                return;
            }
            StateService.currentStateDef.itemId = channel_id;
            source = mediaChannelSource;

            if (name === 'plutotv') {
                StateService.currentStateDef.disableIg = true; // Super special case for plutotv to disable IG
            } else if (name === 'jukin' && SettingsService.hasSetting('media_channels_cutoff_time')) {
                StateService.currentStateDef.duration = SettingsService.getSetting('media_channels_cutoff_time', 180);
            }

            break;
        case 'application':
            const { application_instance_id, source: applicationSource } = getApplicationInfo(takeoverData, currentId, StateService.currentStateDef);
            if (!application_instance_id) {
                Logger.info(['StateService', 'createnextstate'], `No available Application found for state ${name}, source ${applicationSource}`);
                return;
            }
            StateService.currentStateDef.itemId = application_instance_id;
            source = applicationSource;
            break;
        case 'spotlight':
            const { spotlight_id, source: spotlightSource } = await getSpotlightInfo(takeoverData, currentId, StateService.currentStateDef);
            if (!spotlight_id) {
                Logger.info(['StateService', 'createnextstate'], `No available Spotlight found for state ${name}, source ${spotlightSource}`);
                return;
            }
            StateService.currentStateDef.itemId = spotlight_id;
            source = spotlightSource;
            break;
        case 'trivia':
            if (!SettingsService.hasUiSetting('game_id') || ScreenService.isVertical) return;

            if (StateService.currentStateDef.takeover) {
                source = 'takeover';
            } else {
                source = 'scheduled';
                let triviaScheduleScript = MediaService.getActiveItemsByBehavior('script', ['trivia'])[0];
                if (!triviaScheduleScript) {
                    Logger.info(['StateService', 'createnextstate'], `No available Trivia schedule found`);
                    return;
                }
            }
            break;
        case 'socialzoom':
            if (!SettingsService.hasSetting('has_instagram') && !SettingsService.hasTrueSetting('direct_share')) {
                Logger.info(['StateService', 'createnextstate'], `No direct share or instagram account found for socialzoom`);
                return;
            }

            UpNextService.getNext();
            StateService.currentStateDef.currentPost = UpNextService.pop();
            break;
        case 'socialgrid':
        case 'socialgrid3small':
            if (!SettingsService.hasSetting('has_instagram') && !SettingsService.hasTrueSetting('direct_share')) {
                Logger.info(['StateService', 'createnextstate'], `No direct share or instagram account found for socialgrid`);
                return;
            }

            StateService.currentStateDef.contentArr = UpNextService.content.slice(0);
            break;
        case 'watchtowin':
            if (ScreenService.isVertical || !StateService.hasWatchToWinSettings) return;

            break;
    }

    StateService.currentStateDef.source = source;

    const currentStateDef = StateService.currentStateDef;

    StateService.currentStateDef.metricData = {
        source: StateService.lastStateSource, schedule_id: currentStateDef.schedule_id, script_id: ScriptService.script_id, takeover: currentStateDef.takeover, takeover_id: currentStateDef.takeoverData?.id
    };

    let stateData = {
        duration: StateService.currentStateDef.duration,
        settings: Object.assign({}, SettingsService.settings),
        state: Object.assign({}, StateService.currentStateDef),
    };

    let nextState;
    const nextCorrelativeNumber = StateService.correlativeNumber++;

    let hidden_container = ContainerService.createContainer(name, nextCorrelativeNumber);
    try {
        nextState = StateFactory.create(name, {}, hidden_container, stateData);
    } catch (e) {
        hidden_container.remove();
        throw e;
    }

    nextState.correlativeNumber = nextCorrelativeNumber;
    nextState.allowsIg = !StateService.currentStateDef.disableig;
    nextState.stateCreationDate = UP.getCurrentDate();

    if (Logger.isDebugEnabled()) {
        Logger.debug(['StateService', 'debug', 'createnextstate'], 'created state', { name: nextState.name, correlative_number: nextState.correlativeNumber, state: stateData.state });
    }
    return nextState;
};

const getMediaChannelInfo = (takeoverData, state, currentId) => {
    const currentStateName = state.name;
    let channel_id;
    let source;
    if (takeoverData) {
        channel_id = takeoverData.channel_id;
        source = 'takeover';
    } else if (!!currentId) {
        channel_id = MediaService.getFirstMediaValidID(currentId, state, currentStateName);
        source = 'granular';
    } else if (SettingsService.hasFeature('dr_time_based')) {
        const channel = MediaService.getNextMediaItem('script', currentStateName);
        channel_id = channel?.id;
        source = 'displayRules';
    } else {
        const schedule = MediaService.getNextMediaItem('script', currentStateName);
        channel_id = schedule?.channel_id;
        source = 'scheduled';
    }
    return { channel_id, source };
};

const getApplicationInfo = (takeoverData, currentId, state) => {
    let application_instance_id;
    let source;

    const takeoverApplicationInstance = _.get(takeoverData, 'applicationInstance');

    if (takeoverApplicationInstance) {
        application_instance_id = takeoverApplicationInstance.id;
        source = 'takeover';
    } else if (!!currentId) {
        application_instance_id = MediaService.getFirstMediaValidID(currentId, state, 'application');
        source = 'granular';
    } else if (SettingsService.hasFeature('dr_time_based')) {
        const application_instance = MediaService.getNextMediaItem('script', 'application', state.type);
        application_instance_id = application_instance?.id;
        source = 'displayRules';
    } else {
        const schedule = MediaService.getNextMediaItem('script', 'application', state.type);
        application_instance_id = _.get(schedule, 'applicationInstance.id');
        source = 'scheduled';
    }
    return { application_instance_id, source };
};

const getSpotlightInfo = async (takeoverData, currentId, state) => {
    let source;
    let spotlight_id;

    if (takeoverData) {
        spotlight_id = takeoverData.spt_id;
        source = 'takeover';
    } else if (!!currentId) {
        spotlight_id = await SpotlightService.getFirstValidSpotlightID(currentId, state);
        source = 'granular';
    } else if (SettingsService.hasFeature('dr_time_based')) {
        const spotlight = SpotlightService.getNextFullscreenSpotlight(StateService.currentStateDef.type);
        spotlight_id = spotlight?.spt_id;
        source = 'displayRules';
    } else {
        const spotlight = SpotlightService.getNextFullscreenSpotlight(StateService.currentStateDef.type);
        spotlight_id = spotlight?.spt_id;
        source = 'scheduled';
    }

    // Quick fix to remove invalid spotlights for the current orientation
    if (SpotlightService.invalidOrientationSpotlights.includes(spotlight_id)) return { spotlight_id: null, source };

    return { spotlight_id, source };
};

const waitFor = (millis, result = null) => new Promise((resolve) => setTimeout(() => resolve(result), millis));

const failAfter = (millis, error = 'error') => waitFor(millis).then(() => Promise.reject(new Error(error)));

const getErrorLogger = (...keys) => (...errors) => Logger.error(['StateService', ...keys], errors.join(' '));

const resolveBeforeTimeout = (promise, millis = 1000) => Promise.race([failAfter(millis, 'timeout'), promise]);

const runBeforeTimeout = (action, millis = 1000) => resolveBeforeTimeout(action(), millis);

const logAndRethrow = logger => err => {
    logger(err);
    throw err;
};

StateService.preloadState = state => runBeforeTimeout(state.preload.bind(state), SettingsService.getUiSetting('preload_timeout', 15000))
    .catch(logAndRethrow(getErrorLogger('preloadstate')));

StateService.playState = state => runBeforeTimeout(state.play.bind(state), 10000)
    .catch(logAndRethrow(getErrorLogger('playstate')));

StateService.stopState = state => {
    return runBeforeTimeout(state.stop.bind(state), 10000)
        .catch(logAndRethrow(getErrorLogger('stopstate')));

};

StateService.takeover_failed_states = [];

StateService.logFailedState = function (stateDef, reason) {
    const isTakeover = _.get(stateDef, 'takeover', false);

    if (isTakeover) {
        const fail = {
            state: _.cloneDeep(stateDef), reason, ts: Date.now(),
        };

        StateService.takeover_failed_states.push(fail);

        setTimeout(() => {
            StateService.takeover_failed_states = StateService.takeover_failed_states
                .filter(f => f.ts !== fail.ts);
        }, 120 * 1000);
    }
};

StateService.swapState = async () => {
    Logger.log('StateService', 'Swap state: ' + (StateService.currentState ? StateService.currentState.name : '') + ' to ' + (StateService.nextState ? StateService.nextState.name : ''));

    if (Logger.isDebugEnabled()) {
        Logger.debug(['StateService', 'debug', 'swapstate'], 'SwapState ' + StateService.currentState.name + '  ' + StateService.currentState.correlativeNumber + ' to: ' + StateService.nextState.name + '  ' + StateService.nextState.correlativeNumber);
    }

    if (StateService.currentState.correlativeNumber === StateService.nextState.correlativeNumber) {
        Logger.error(['StateService', 'error', 'swapstate'], 'ERROR: Swapping from state to same state.  ');
        return;
    }

    let oldState = StateService.currentState;
    StateService.currentState = StateService.nextState;

    const soundState = _.get(StateService.currentState, 'state.soundState');
    fadeBGMusic(soundState);

    try {
        await StateService.stopState(oldState);
    } catch (err) {
        Logger.error(`stateservice. Stop state ${err && err.message ? err.message : 'NO error provided'}`);
    }

    try {
        TrackerService.appEvents.logMetric({
            action: 'swapstate',
            inbound: {
                name: _.get(StateService, 'currentState.name', 'undefined'),

                cn: _.get(StateService, 'currentState.correlativeNumber'), ...{
                    ...StateService.currentState.metricData ? StateService.currentState.metricData : null, ...StateService.currentStateDef.metricData
                }
            },
            outbound: {
                name: _.get(oldState, 'name', 'undefined'), cn: _.get(oldState, 'correlativeNumber'), extra: oldState.metricData ? oldState.metricData : null
            }
        });
        FixedContentService.renderFixedContent();
        window.currentState = StateService.currentState;
        await ContainerService.switchState(StateService.currentState, oldState);

        if (oldState) {
            try {
                oldState.destroy();
                // delete(StateService.nextState)
            } catch (err) {
                Logger.error(['StateService', 'stop', 'destroy'], 'Destroy state ' + oldState.name + ' - ERROR ', err);
            }
        }

        await StateService.playState(StateService.currentState);

    } catch (err) {
        Logger.error(`stateservice Error trying to play state (will swap): ${_.get(StateService, 'currentState.name')}`, err);

        _.invoke(StateService?.currentState, 'errCallback', err);

        StateService.logFailedState(StateService.currentStateDef, 'done');

    }

    try {
        const current = StateService.currentState;

        current.doneCallback = () => {
            StateService.advanceState();
        };

        current.errCallback = (err) => {
            Logger.error(`stateservice Error playing state through (will swap): ${_.get(StateService, 'currentState.name')}`, err);
            StateService.logFailedState(StateService.currentStateDef, 'play');
            StateService.advanceState();
        };

        current
            .done()
            .then(doneData => current.doneCallback && current.doneCallback(doneData))
            .catch(doneError => current.errCallback && current.errCallback(doneError));

    } catch (error) {
        Logger.error(`stateservice ERROR Error trying to play state (will swap): ${_.get(StateService, 'currentState.name')}`, error);
        throw error;
    }

};

StateService.advanceState = async () => {
    const logError = getErrorLogger('advancestate');

    let oldState = StateService.currentState;

    await handleOTWTTakeovers();

    //We don't listen to what the old state has to say anymore
    //This takes control of the screen cycle away from the old state

    oldState.doneCallback = null;
    oldState.errCallback = null;

    let state;
    try {
        state = await StateService.createNextState();
        if (!state) {
            await waitFor(250);
            return StateService.advanceState();
        }
    } catch (e) {
        if (e?.upshowType === 'warning') {
            Logger.warn(['StateService', 'advancestate', 'createNextState'], e);
        } else {
            Logger.error(['StateService', 'advancestate', 'createNextState'], e);
        }

        StateService.logFailedState(StateService.currentStateDef, 'create');
        await waitFor(1000);
        return StateService.advanceState();
    }

    StateService.nextState = state;

    try {
        const result = await StateService.preloadState(state);

        if (result === 'skipState') {
            Logger.log('upshowstate', state.name + ' will be skipped');
            state.destroy();
            return StateService.advanceState();
        }

        Logger.info('index', 'State preloaded, will swap in 1 second - ' + state.name + ' : ' + state.mainContentUrl);
    } catch (err) {
        if (!!state && !!state.state && !!state.state.state) {
            let maxretries = state.state.state.maxretries;
            let retryState = state.state.state;

            if (!!maxretries && maxretries > 0) {
                Logger.log('upshowstate', state.name + ' failed and will retry ');
                retryState.maxretries = --maxretries;
                StateService.queueState(retryState);
            }
        }

        if (err?.upshowType === 'warning') {
            Logger.warn(['StateService', 'advancestate', 'preloadstate', state.name], err);
        } else {
            Logger.error(['StateService', 'advancestate', 'preloadstate', state.name], err);
        }

        try {
            if (state && state.destroy) {
                state.destroy();
            }
        } catch (error) {
            logError('destroy', error);

            Logger.error('upshowstate', 'Error unloading failed state ' + state.name);
        }

        await waitFor(1000);

        StateService.logFailedState(StateService.currentStateDef, 'preload');
        return StateService.advanceState();

    }

    try {

        await waitFor(1000);

        StateService.swapState();

    } catch (err) {

        logError('swap', err);

    }
};

StateService.showLoadingState = function showLoadingState () {
    let currentState = StateFactory.create('loading', {}, ContainerService.createContainer('loading', 0), {});
    currentState.node.classList.remove('hidden');
    StateService.currentState = currentState;
    StateService.currentStateDef = Object.assign({}, currentState);
    currentState.correlativeNumber = 0;
    currentState.preload()
        .then(() => currentState.play())
        .then(() => currentState.done())
        .catch((err) => {
            Logger.error(['StateService', 'Loading'], err);
        });
};

StateService.showInactiveState = function showInactiveState () {
    let currentState = StateFactory.create('inactive', {}, ContainerService.createContainer('inactive', 0), {});
    currentState.node.classList.remove('hidden');
    StateService.currentState = currentState;
    StateService.currentStateDef = Object.assign({}, currentState);
    currentState.correlativeNumber = 0;
    currentState.preload()
        .then(() => currentState.play())
        .catch((err) => {
            Logger.error(['StateService', 'Inactive'], err);
        });
};

StateService.gc = (force_cleanup = false) => {
    const cleanup_enabled = force_cleanup || !SettingsService.hasTrueUiSetting('gc_cleanup_disabled');

    const renderer_containers = Array.from(document.querySelectorAll('#root>.container'))
        ?.map(node => ({ node, name: node.getAttribute('state_name'), cn: parseInt(node.getAttribute('correlative_number'), 10) })) ?? [];
    const active_correlative_numbers = ['currentState.correlativeNumber', 'nextState.correlativeNumber', 'oldState.correlativeNumber']
        .map(cn_location => _.get(StateService, cn_location));

    renderer_containers.forEach(container => {
        if (!active_correlative_numbers.includes(container.cn)) {
            Logger.error(['gc'], new Error(`Found ${container.name} container not in StateManager state [#${container.cn}]`));
            if (cleanup_enabled && container.node) {
                container.node.remove();
            }
        }
    });
};

StateService.setLiveEventStatus = (activeLiveEvent, timestamp) => {
    if (StateService.liveEventLastSyncTS >= timestamp) return; // Ignore Old Data
    StateService.liveEventLastSyncTS = timestamp; // Update last available status timestamp

    if (!activeLiveEvent ) { // What happens if there is no live_event or the device is not allowed to play it
        if (StateService.liveEvent) { // Remove event from screen if there is a liveEvent currently running
            StateService.flagFinishedLiveEvent();
        }

        return; // Nothing else to do if there is no live_event to show
    }

    if (
        !StateService.liveEvent || // There is no live event currently running -OR-
        activeLiveEvent.id !== StateService.liveEvent.id || // The event is different -OR-
        activeLiveEvent.status !== StateService.liveEvent.status || // The status of the event is different -OR-
        activeLiveEvent.metadata.url !== StateService.liveEvent.metadata.url // The URL of the event is different
    ) {
        StateService.liveEvent = { ...activeLiveEvent };
        if (StateService.currentStateDef.name !== 'loading') {
            StateService.advanceState();
        }
    }
};

StateService.flagFinishedLiveEvent = () => {
    StateService.liveEvent = null;
    StateService.advanceState();
};

export default StateService;