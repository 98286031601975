import React from 'react';
import ReactDOM from 'react-dom';
import UpshowLogger from '../Logger';
import SettingsService from './SettingsService';
import HwFeatureService from './HwFeatureService';
import axios from 'axios';
import BGMusicPlayer from '../components/BGMusicPlayer';
import StateService from './StateService';
import { volumeFading } from '../libs/volumeFading';
import { getScheduleScriptsByPrecedence, scheduleisActive } from '@upshow/sched-utils';
import * as UP from '../Upshow';
import { basicIsValidUrl } from '@upshow/validators';
import AudioAnnouncementsService from './AudioAnnouncementsService';
import { ContentAudioService } from './ContentAudioService';
import { setAuthCookies } from '../libs/helpers';

const WATCHDOG_TIMEOUT = 3000;
const SCHEDULE_TIMEOUT = process.env.REACT_APP_AUDIO_SCHEDULE_TIMEOUT || 5000;
let watchdog;
const setWatchdog = (payload) => {
    watchdog = setTimeout(() => {
        UpshowLogger.error(['BackgroundMusicService'], `Watchdog Error ${payload}`);
        ReactDOM.render(<></>, BackgroundMusicService.bgMusicWrapper);
        BackgroundMusicService.actualPlaylist = '';
        BackgroundMusicService.play();
    }, WATCHDOG_TIMEOUT);
};

let scheduleWatcher;

const playMusic = async () => {
    try {
        await setPremiumMusicCookies();
        if (StateService.currentState && StateService.currentState.state.soundState.shouldSound) {
            UpshowLogger.debug(['BackgroundMusicService'], 'Content with sound is Playing');
            BackgroundMusicService.playing = false;
            BackgroundMusicService.volume = 0;
        } else {
            if (!AudioAnnouncementsService.announcementRunning) {
                BackgroundMusicService.volume = 1;
            } else {
                BackgroundMusicService.playing = false;
                BackgroundMusicService.volume = 0;
            }
        }
        renderReact();

    } catch (e) {
        UpshowLogger.error(['BackgroundMusicService'], `Error starting new playlist because ${e}`);
        setWatchdog(`Error starting new playlist because ${e}`);
    }
};

const setScheduleWatcher = () => {
    if (!scheduleWatcher && !hasCustomMusic()) {
        scheduleWatcher = setInterval(async () => {
            const activeSchedules = BackgroundMusicService.getActiveSchedule();

            if (activeSchedules) {
                const scheduledPlaylist = await getScheduledPlaylist(activeSchedules);
                if (BackgroundMusicService.actualPlaylist.toString() !== scheduledPlaylist.toString()) {
                    UpshowLogger.debug(['BackgroundMusicService'], 'New schedule playlist found' + scheduledPlaylist);
                    BackgroundMusicService.actualPlaylist = scheduledPlaylist;
                    if (scheduledPlaylist.length > 0 && scheduledPlaylist[0].includes('premium')) {
                        await callAuthCookies(scheduledPlaylist[0]);
                    }

                    await playMusic();
                }
            } else {
                BackgroundMusicService.play();
            }
        }, SCHEDULE_TIMEOUT);
    }

};

const getScheduledPlaylist = async (schedule) => {
    const musicSetting = schedule.channel_setting.url;
    if (schedule.channel_type === 'premium') return [musicSetting];
    else {
        const response = await axios.get(musicSetting);
        return response.data;
    }
};

const clearScheduleWatcher = () => {
    if (scheduleWatcher) {
        clearInterval(scheduleWatcher);
        scheduleWatcher = null;
    }
};

const clearWatchdog = () => {
    clearTimeout(watchdog);
};

const watchdogCallback = (playing, payload) => {
    clearWatchdog();
    if (playing) {
        setWatchdog(payload);
    }
};

const setPremiumMusicCookies = async () => {
    if (SettingsService.hasSetting('music')) {
        const type = SettingsService.getSetting('music')['type'];
        if (type === 'premium') {
            await callAuthCookies(SettingsService.getSetting('music')['url'])
        }
    }
};

const callAuthCookies = async (url_input) => {
    const url = new URL(url_input);
    await setAuthCookies(url.origin + '/auth');
};
const isAutoActivated = () => SettingsService.getSetting('type') === 'wvw_autogenerated';
const hasMusic = () => SettingsService.hasSetting('music') && SettingsService.getSetting('music')['enabled'] === true && basicIsValidUrl(SettingsService.getSetting('music')['url']);
const hasSound = () => HwFeatureService.isSupported('background_music') && !SettingsService.hasTrueUiSetting('muted');
const hasCustomPlaylist = () => SettingsService.hasUiSetting('bg-music-playlist') && Array.isArray(SettingsService.getUiSetting('bg-music-playlist'));
const hasCustomMusic = () => SettingsService.hasTrueUiSetting('bg-music') && hasCustomPlaylist();
const isPremiumMusic = () => SettingsService.getSetting('music')['type'] === 'premium';
const hasOnlySpotlightAudio = () => SettingsService.getSetting('music')['behavior'] === 'spotlight';
const hasDebugAudio = () => SettingsService.hasTrueUiSetting('debugAudio');
const hasToMuteState = () => BackgroundMusicService.hasToOverride() || hasOnlySpotlightAudio();

const getPlaylist = async () => {
    if (hasCustomMusic()) return SettingsService.getUiSetting('bg-music-playlist');

    if (SettingsService.hasSetting('music')) {
        const musicSetting = SettingsService.getSetting('music')['url'];
        const type = SettingsService.getSetting('music')['type'];

        if (type === 'premium') return [musicSetting];
        else return await axios.get(musicSetting).then((data) => { return data.data; });
    } else return null;
};

const fadeInRender = () => {
    if (BackgroundMusicService.volume > 0) {
        BackgroundMusicService.playing = true;
        volumeFading((volume) => {
            BackgroundMusicService.volume = volume;
            renderReact();
        }, 'in');
    }
};

const removeBGAudio = () => {
    document.getElementById('vu-meter')?.remove();
    ReactDOM.render(<></>, BackgroundMusicService.bgMusicWrapper);
    BackgroundMusicService.playing = false;
};

const BackgroundMusicService = {
    volume: 0,
    schedules: null,
    getActiveSchedule: () => {
        const default_tz = SettingsService.getTimezone();
        const currentIsoDate = UP.getCurrentISODate();

        if (!Array.isArray(BackgroundMusicService.schedules)) {
            return null;
        }

        const enabledSchedules = BackgroundMusicService.schedules.filter((sch) => sch.channel_setting.enabled);

        const activeSchedules = enabledSchedules.filter(schedule => {
            return scheduleisActive(currentIsoDate, schedule, default_tz);
        });

        if (activeSchedules.length > 0) {
            return getScheduleScriptsByPrecedence(activeSchedules);
        } else {
            return null;
        }
    },
    setDebugAudioContent: (contentInfo) => {
        if (hasDebugAudio()) {
            BackgroundMusicService.contentInfo = contentInfo;
            renderReact();
        }
    },
    isPremiumMusic,
    hasToMuteState,
    onReady: () => {
        fadeInRender();
    },
    forwardOnPause: true,
    playing: false,
    overrideAudio: true,
    playerRef: null,
    bgMusicWrapper: document.getElementById('bg-music'),
    actualPlaylist: '',
    async play () {
        clearWatchdog();

        UpshowLogger.debug(['BackgroundMusicService'], 'BackgroundMusicService hasBackgroundMusic', { hasBackgroundMusic: BackgroundMusicService.hasBackgroundMusic() });

        if (BackgroundMusicService.hasBackgroundMusic()) {
            UpshowLogger.debug(['BackgroundMusicService'], 'BackgroundMusicService is Playing.');

            const override = SettingsService.getSetting('music')['override'];

            BackgroundMusicService.overrideAudio = (override != null) ? !override : true;
            UpshowLogger.debug(['BackgroundMusicService'], 'BackgroundMusicService.overrideAudio', { overrideAudio: BackgroundMusicService.overrideAudio });

            const playlist = await getPlaylist();
            const activeSchedules = BackgroundMusicService.getActiveSchedule();

            if (BackgroundMusicService.actualPlaylist === '' || (!activeSchedules && BackgroundMusicService.actualPlaylist.toString() !== playlist.toString())) {
                UpshowLogger.debug(['BackgroundMusicService'], 'New playlist found' + playlist);
                BackgroundMusicService.actualPlaylist = playlist;
                setWatchdog(`when tried playing ${BackgroundMusicService.actualPlaylist}`);
                setScheduleWatcher();
                await playMusic();
            }

            return true;
        } else {
            clearScheduleWatcher();
            if (BackgroundMusicService.playing === true) {
                volumeFading((volume, fading) => {
                    clearWatchdog();
                    BackgroundMusicService.volume = volume;
                    renderReact();

                    if (fading === 'end') {
                        removeBGAudio();
                        ContentAudioService.increaseAudioContent();
                    }
                }, 'out');
            } else {
                removeBGAudio();
            }

            return false;
        }
    },
    setPause (pause, disableOverride = false) {
        const isPlaying = BackgroundMusicService.playing;
        const stopOnContent = disableOverride || !BackgroundMusicService.overrideAudio;
        const startMusic = BackgroundMusicService.overrideAudio && !isPlaying;

        if (BackgroundMusicService.hasBackgroundMusic() && !AudioAnnouncementsService.announcementRunning) {
            if (isPlaying !== !pause && stopOnContent || startMusic) {
                const fadeType = pause ? 'out' : 'in';

                volumeFading((volume, fading) => {
                    if (fading === 'start' && fadeType === 'in') {
                        BackgroundMusicService.playing = true;
                    }
                    if (fading === 'start' && fadeType === 'out') {
                        BackgroundMusicService.playing = false;
                    }

                    BackgroundMusicService.volume = volume;
                    renderReact();
                }, fadeType);
            }
        }
    },
    hasBackgroundMusic () {
        return Boolean(!isAutoActivated() && (hasSound() && (hasCustomMusic() || hasMusic())));
    },
    hasToOverride () {
        return Boolean(BackgroundMusicService.hasBackgroundMusic() && (BackgroundMusicService.overrideAudio || hasCustomMusic()));
    }
};

window.bgMusicService = BackgroundMusicService;

export default BackgroundMusicService;

const renderReact = () => {
    const soundState = StateService.currentState ? StateService.currentState.state.soundState : { shouldSound: false, disableOverride: false };
    const contentInfo = { volume: soundState.shouldSound ? 1 : 0, soundState };

    ReactDOM.render(<BGMusicPlayer
        playlist={BackgroundMusicService.actualPlaylist}
        playingParam={true}
        volume={BackgroundMusicService.volume}
        onWatchdogCallback={watchdogCallback}
        debugAudio={hasDebugAudio()}
        onReady={BackgroundMusicService.onReady}
        setWatchdog={setWatchdog}
        clearWatchdog={clearWatchdog}
        contentInfo={contentInfo}
    />, BackgroundMusicService.bgMusicWrapper);
};