import ReactDOM from 'react-dom';
import InstallPrompt from '../components/InstallPrompt/InstallPrompt';

const InstallPromptService = {

    init () {
        const pwaInstallPromptNode = document.getElementById('pwa-install-prompt-root');
        ReactDOM.render(<InstallPrompt/>, pwaInstallPromptNode);
    }
};

export default InstallPromptService;