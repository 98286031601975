import AudioAnnouncementsService from "../services/AudioAnnouncementsService";
import BackgroundMusicService from "../services/BackgroundMusicService";
import StateService from '../services/StateService';

const defaultFadeDuration = 2.5;
const minAudioDuration = defaultFadeDuration * 2;
const fadeValue = 0.1;
const maxVolume = 1;

export const fadeBGMusic = (soundState) => {
    if (BackgroundMusicService.hasBackgroundMusic()) {
        const { shouldSound, disableOverride } = soundState;
        BackgroundMusicService.setPause(shouldSound, disableOverride);
    }
};

export const volumeFading = (callback, fadeType, duration) => {
    const fadeDuration = duration < minAudioDuration ? duration / 2 : defaultFadeDuration;
    const time = fadeDuration * fadeValue / maxVolume;

    let volume = fadeType === 'in' ? 0 : maxVolume;
    let fading = 'start';

    callback(volume, fading);

    const interval = setInterval(() => {
        const newVolume = fadeType === 'in' ? volume + fadeValue : volume - fadeValue;

        if (newVolume <= maxVolume && fadeType === 'in' || newVolume >= 0 && fadeType === 'out') {
            fading = 'running';
            volume = parseFloat(newVolume.toFixed(2));
        } else {
            fading = 'end';
            clearInterval(interval);
        }

        callback(volume, fading);
    }, time * 1000);
};

export const contentCrossFade = (callback, fadeType) => {
    const { soundState, muted } = StateService.nextState.state;
    const time = defaultFadeDuration * fadeValue / maxVolume;
    const needToFade = soundState.shouldSound && !muted;

    if (AudioAnnouncementsService.announcementRunning) {
        callback(0, 'end');
        return;
    }

    if (!BackgroundMusicService.hasBackgroundMusic()) {
        callback(1, 'end');
        return;
    }

    if (['live_event', 'app-application'].includes(StateService.currentState.name) && needToFade) {
        callback(1, 'end');
        return;
    }

    if (StateService.currentState.name !== 'spotlight' && BackgroundMusicService.hasToMuteState()) {
        BackgroundMusicService.setPause(false);
        callback(0, 'end');
        return;
    }

    if (BackgroundMusicService.hasToOverride() || !needToFade) {
        BackgroundMusicService.setPause(false);
        callback(0, 'end');
        return;
    }

    let volume = fadeType === 'in' ? 0 : maxVolume;
    let fading = 'start';

    if (fadeType === 'in') BackgroundMusicService.setPause(true);

    BackgroundMusicService.setDebugAudioContent({ volume , soundState });
    callback(volume, fading);

    const interval = setInterval(() => {
        const newVolume = fadeType === 'in' ? volume + fadeValue : volume - fadeValue;

        if (newVolume <= maxVolume && fadeType === 'in' || newVolume >= 0 && fadeType === 'out') {
            fading = 'running';
            volume = parseFloat(newVolume.toFixed(2));
        } else {
            fading = 'end';
            clearInterval(interval);
        }

        BackgroundMusicService.setDebugAudioContent({ volume, soundState });
        callback(volume, fading);
    }, time * 1000);
};