import axios from 'axios'

var instance = axios.create({
    baseURL: process.env.REACT_APP_BOH_API_URL,
    timeout: 1000,
});

export const setAxiosAccessToken = token => {
    instance.defaults.headers.common['x-access-token'] = token;
}


export default instance
