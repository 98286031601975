import ReactDOM from 'react-dom';
import React, { useCallback, useEffect, useState } from 'react';
import * as UP from '../Upshow';
import SettingsService from '../services/SettingsService';
import './Debug.scss';
import { useInterval } from 'react-use';
import { $_GET } from '../Upshow';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

const STATIC_EXTENSION_ID = process.env.REACT_APP_KIOSK_EXTENSION_ID;

function millisecondsToStr (milliseconds) {
    function numberEnding (number) {
        return (number > 1) ? 's' : '';
    }

    let temp = Math.floor(milliseconds / 1000);

    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
        return days + ' day' + numberEnding(days);
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
        return hours + ' hour' + numberEnding(hours);
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
        return minutes + ' minute' + numberEnding(minutes);
    }
    const seconds = temp % 60;
    if (seconds) {
        return seconds + ' second' + numberEnding(seconds);
    }
    return 'less than a second';
}

function Debug () {
    const [deviceInfo, setDeviceInfo] = useState(UP.getDeviceInfo());
    const [settings, setSettings] = useState(SettingsService.settings);
    const [extension, setExtension] = useState(false);
    const [app, setApp] = useState(false);
    const [sw, setSw] = useState(false);
    const [cons, setCons] = useState([]);
    const [debugLevel, setDebugLevel] = useState(0);
    const [teslaEnabled, setTeslaEnabled] = useState(false);
    const [storageEstimate, setStorageEstimate] = useState();
    const [UPtime, setUPtime] = useState(UP.getUptime());

    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.addEventListener('message', (event) => {
            if (event.data && event.data.type === 'PONG') {
                setStorageEstimate(event.data.quota_estimate);
                setSw(event.data.version);
            }
        });
    }

    const console = window.console;

    const setConsole = useCallback((args) => {
        setCons((prev) => [args, ...prev].slice(0, 20));
    }, [cons]);

    useEffect(() => {
        ['log', 'warn', 'error', 'info'].forEach(level => intercept(level));

        function intercept (level) {
            const original = console[level];
            console[level] = function () {
                // do sneaky stuff
                if (original.apply) {
                    // Do this for normal browsers
                    original.apply(console, arguments);
                }

                const arg = Array.from(arguments);

                setConsole({ timestamp: new Date(), level, arg, key: uuidv4() });
            };
        }
    }, [console]);

    useInterval(() => {
        setDeviceInfo(UP.getDeviceInfo());
        setSettings(SettingsService.settings);
        setDebugLevel(SettingsService.getUiSetting('debug_level', 0));
        setApp(!!window.proxyBridge && !!window.proxyOrigin ? $_GET['app_version'] : false);
        setTeslaEnabled(!SettingsService.hasTrueUiSetting('disable_tesla_cache') && !!app);
        setUPtime(UP.getUptime());

        if ('serviceWorker' in navigator) {
            if (navigator.serviceWorker.controller) {
                navigator.serviceWorker.controller.postMessage({ type: 'PING' });
            } else {
                setSw(false);
            }
        } else {
            setSw(false);
        }

        if (window.chrome?.runtime) {
            chrome.runtime.sendMessage(STATIC_EXTENSION_ID, {
                methodName: 'ping',
            }, function (arg) {
                if (chrome.runtime.lastError) {
                    setExtension({ error: chrome.runtime.lastError });
                } else {
                    setExtension(arg);
                }
            });
        }

    }, 1000);

    return <div>
        <div className="wrapper">
            <dl>
                <dt>Version:</dt>
                <dd>{deviceInfo.uiVersion} </dd>

                <dt>Model:</dt>
                <dd>{deviceInfo.model}</dd>

                {deviceInfo.model !== 'WebView' && <>
                    <dt>Make:</dt>
                    <dd>{deviceInfo.make}</dd>
                </>}

                <dt>Serial:</dt>
                <dd>{deviceInfo.serial ?? 'Not provided'}</dd>

                <dt>Type:</dt>
                <dd>{settings.type}</dd>

                <dt>ID:</dt>
                <dd>{settings.device_id} </dd>

                <dt>ORG ID:</dt>
                <dd>{settings.organization_id}</dd>

                <dt>Debug Level</dt>
                <dd>{debugLevel}</dd>

                <dt>TZ:</dt>
                <dd>{settings.tz}</dd>

                <dt>UPtime:</dt>
                <dd>{millisecondsToStr(UPtime)}</dd>

                <dt>Chrome Extension</dt>
                <dd style={{ color: extension && !extension.error ? 'green' : 'red' }}>{!extension ? 'disabled' : <>
                    {extension.error ? `Error: ${extension.error.message}` : extension.version}
                </>}</dd>

                <dt>Service Worker</dt>
                <dd style={{ color: sw ? 'green' : 'red' }}>{sw ? sw : 'disabled'}</dd>

                {storageEstimate && <>
                    <dt>Disk Quota</dt>
                    <dd>
                        {Math.ceil(storageEstimate.usage / storageEstimate.quota * 100)}% <br/>
                        <small style={{ color: 'dimgrey', fontSize: 'smaller' }}>({Math.ceil(storageEstimate.usage / 1024 / 1024)}/{Math.floor(storageEstimate.quota / 1024 / 1024)} Mb)</small>
                    </dd>
                </>}

                <dt>Chrome APP</dt>
                <dd style={{ color: app ? 'green' : 'red' }}>{app ? app : 'disabled'}</dd>

                <dt>Tesla Cache</dt>
                <dd style={{ color: teslaEnabled ? 'green' : 'red' }}>{teslaEnabled ? 'enabled' : 'disabled'}</dd>
            </dl>

            <ul className="console">
                <center><small>{window.location.href}</small></center>

                {cons.map(({ timestamp, level, arg, key }) => <li key={key} className={level}>
                    <i className="muted">[{moment(timestamp).format('LTS')}]</i> {arg.map(a => {
                    if (typeof a === 'string' || typeof a === 'number') {
                        return a;
                    } else if (a instanceof Error) {
                        return a.message;
                    } else {
                        try {
                            return JSON.stringify(a);
                        } catch (error) {
                            return `Could not stringify ${error.message}`;
                        }
                    }
                }).join(' ')}
                </li>)}
            </ul>
        </div>
    </div>;
}

function setDebugMode (debug_mode) {
    if (debug_mode === window.debug_mode) return;

    const node = document.getElementById('debug_layer');
    if (debug_mode) {
        ReactDOM.render(<Debug/>, node);
        node.style.display = 'block';
        window.debug_mode = true;
    } else {
        node.style.display = 'none';
        window.debug_mode = false;
        ReactDOM.unmountComponentAtNode(node);
    }
}

export default setDebugMode;