import React from 'react';
import './EmptyStateComponent.scss';
import { teslaCacheX } from '../../Upshow';
import ReactPlayer from 'react-player';
import Logger from '../../Logger';
import ScreenService from '../../services/ScreenService';
class EmptyStateComponent extends React.Component {

    
    constructor(props) {
        super(props);
        
        this.resources = ScreenService.isVertical 
        ? { url: 'https://cdn.upshow.tv/ui-assets/Everpass_No_Content_Vertical.mp4', poster: 'https://cdn.upshow.tv/ui-assets/Everpass_No_Content_Vertical.jpg' }
        : { url: 'https://cdn.upshow.tv/ui-assets/Everpass_No_Content_Horizontal.mp4', poster: 'https://cdn.upshow.tv/ui-assets/Everpass_No_Content_Horizontal.jpg' };

        this.url = teslaCacheX(this.resources.url);
    }

    render() {
        return <div className="empty-state">
            <ReactPlayer
                width="100%" height="100%" volume={0} playing loop
                url={this.url}
                onError={(error) => Logger.warn('No Content state could not play loading media', error)}
                config={{
                    file: {
                        attributes: {
                            crossOrigin: 'anonymous',
                            poster: this.resources.poster
                        }
                    }
                }} />

        </div>;
    }
}

export default EmptyStateComponent;
