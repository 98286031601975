import { io } from "socket.io-client";
import UpshowLogger from '../Logger';
import CommandProcessor from '../CommandProcessor';

const commandsProcessor = new CommandProcessor();

const socket = io(process.env.REACT_APP_SOCKET_URL, {
    autoConnect: false,

    reconnection: true,
    reconnectionDelay: 30_000,
    reconnectionDelayMax: 600_000,
    randomizationFactor: 0.5,
    reconnectionAttempts: 60,

    withCredentials: true,
    auth: {
        accessToken: null
    }
}); 

socket.on("connect", () => {
    UpshowLogger.debug(['SocketService'], 'Device Connected to socket');
});

socket.on("disconnect", (reason) => {
    UpshowLogger.error(['SocketService'], 'Device socket disconnected ' + reason);
    if (reason === "io server disconnect") {
        // the disconnection was initiated by the server, you need to reconnect manually
        socket.connect();
      }
      // else the socket will automatically try to reconnect
});

socket.onAny((eventName, data) => {
    console.log('Socket Command received', eventName, data)
    UpshowLogger.debug(['SocketService'], 'Socket Command received' , { eventName, data });
    commandsProcessor.process({command: eventName, ...data}, 'socket');
});

socket.on("connect_error", (err) => {
    UpshowLogger.error(['SocketService'], 'Device socket connect_error ', err);
    if (err.data && err.data.type === 'Unauthorized') {
        socket.disconnect();
    }
});

const SocketService = {
    socket,
    connectToSocket(api_token) {
        this.socket.auth.accessToken = api_token;
        this.socket.open();
    }
}
export default SocketService;