import React from "react";
import PropTypes from "prop-types";
import { gsap } from '@upshow/gsap';
import "./Tile3.scss";
import TileFace from "../TileFace/TileFace";
import TileService3 from "../../../services/TileService3";
import HwFeatureService from "../../../services/HwFeatureService";
import Logger from "../../../Logger";

class Tile3 extends React.Component {

    constructor(props) {
        super(props);

        // Instance variables
        this.containerRef = null;
        this.facesRefs = new Map();
        this.timelineProgress = 0;
        this.timeline = null;

        // Component state
        this.state = {
            content: props.content.filter(c => !!c?.id),
            playing: false
        };

        // Method bindings
        this.saveContainerRef = this._saveContainerRef.bind(this);
        this.saveTileFaceRef = this._saveTileFaceRef.bind(this);
        this.flip = this._flip.bind(this);
        this.onTileFaceError = this._onTileFaceError.bind(this);
    }

    get shouldFlip() {
        return !!(this.props.flip && this.state.content.length > 1 && HwFeatureService.isSupported('tile-flip'));
    }

    componentDidMount() {
        this.flip();
    }

    componentWillUpdate(nextProps, nextState) {
        if (this.state.content !== nextState.content && this.timeline) {
            this.timelineProgress = this.timeline.progress();
            this.timeline.kill();
        }
    }

    play(){
        this.setState({
                playing: true
        });
    }

    stop(){
        this.setState({
                playing: false
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.content !== this.state.content) {
            // Clean up nulled refs
            for (var ref of this.facesRefs) {
                if (ref[1] === null) {
                    this.facesRefs.delete(ref[0]);
                }
            }

            this.flip();
        }
    }

    componentWillUnmount() {
        if (this.timeline) {
            this.timeline.kill();
        }
    }

    _saveContainerRef(el) {
        this.containerRef = el;
    }

    _saveTileFaceRef(el, id) {
        this.facesRefs.set(id, el);
    }

    _flip() {
        if (this.shouldFlip) {
            const nodes = [...this.facesRefs.values()];
            if (nodes.length > 1) {
                gsap.set(this.containerRef, { transformStyle: 'preserve-3d' });
                gsap.set(nodes, { transformStyle: 'preserve-3d', backfaceVisibility: 'hidden', rotationX: -180 });
                gsap.set(nodes[0], { rotationX: 0 });

                this.timeline = gsap.timeline({ paused: true })
                    .to(nodes[0], { duration: 1.5, rotationX: -180 }, this.props.flipDelay)
                    .to(nodes[1], { duration: 1.5, rotationX: 0 }, this.props.flipDelay)
                    .progress(this.timelineProgress)
                    .play();
            } else {
                Logger.debug(['tile3', 'flip'], 'Not enough nodes to flip tile');
            }
        }
    }

    _onTileFaceError(id) {
        const {tileService} = this.props;

        // Mark content as errored
        tileService.markContentAsErrored(id);

        // Replace errored content
        const newContent = tileService.getNextContentItem();
        if (!newContent) return; // There is no new content, sorry, nothing to do
        const index = this.state.content.findIndex(c => c.id === id);

        this.setState(prev => ({
            ...prev,
            content: [
                ...prev.content.slice(0, index),
                newContent,
                ...prev.content.slice(index + 1),
            ],
        }));
    }

    render() {
        const {content} = this.state;

        return (
            <div className="Tile3" ref={this.saveContainerRef}>

                {content.map((c, i) => (
                    <TileFace
                        key={`${i}-${c.id}`}
                        playing={this.state.playing}
                        containerRef={this.saveTileFaceRef}
                        onError={this.onTileFaceError}
                        id={c.id}
                        type={c.type}
                        thumbnail={c.thumbnail}
                        url={c.url}
                        typeCount={c.typeCount}
                    />
                ))}

            </div>
        );

    }
}

const ContentType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.oneOf(['image', 'video']).isRequired,
    thumbnail: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    typeCount: PropTypes.number.isRequired,
});

Tile3.propTypes = {
    content: PropTypes.arrayOf(ContentType).isRequired,
    tileService: PropTypes.instanceOf(TileService3).isRequired,
    flip: PropTypes.bool,
    flipDelay: PropTypes.number,
};

Tile3.defaultProps = {
    flip: true,
    flipDelay: 4,
};

export default Tile3;