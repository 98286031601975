import UpshowLogger from '../Logger';
import * as UP from '../Upshow';
import { scheduleisActive, getScheduleScriptsByPrecedence } from '@upshow/sched-utils';
import SettingsService from './SettingsService';
import StateService from './StateService';

import _ from 'lodash';
import { SmartPlaylist } from '@upshow/smart-playlist';
import { getAvailableStates } from '../libs/helpers';

const formatSequence = (sequence) => {
    return sequence?.map((state, idx) => ({ ...state, idx })) ?? [];
};

const ScriptService = {
    granular_states: new Map(),
    scripts: null,
    timeout_schedules_check: 15000,
    script_id: null,
    loadSchedulesScripts: function (scripts) {
        ScriptService.scripts = scripts;
    },
    getActivePlaylistId: () => {
        const schedules_scripts = _.get(ScriptService, 'scripts.schedules_scripts', []);

        if (schedules_scripts.length === 0) return _.get(ScriptService, 'scripts.org_default_script_id', null);

        const default_tz = SettingsService.getTimezone();
        const currentIsoDate = UP.getCurrentISODate();

        const apply_to_now = Array.isArray(schedules_scripts) ? schedules_scripts.filter(schedule => {
            return scheduleisActive(currentIsoDate, schedule, default_tz);
        }) : [];

        return apply_to_now[0]?.script_id;
    },
    getActiveScheduledScript: function () {
        let sequence;
        const schedules_scripts = _.get(ScriptService, 'scripts.schedules_scripts', null);
        const org_default_script = _.get(ScriptService, 'scripts.org_default_script', null);

        const default_tz = SettingsService.getTimezone();
        const currentIsoDate = UP.getCurrentISODate();

        const apply_to_now = Array.isArray(schedules_scripts) ? schedules_scripts.filter(schedule => {
            return scheduleisActive(currentIsoDate, schedule, default_tz);
        }) : [];

        if (apply_to_now.length > 0) {
            const scheduleScriptByPrecedence = getScheduleScriptsByPrecedence(apply_to_now);
            this.script_id = scheduleScriptByPrecedence.script_id;
            sequence = scheduleScriptByPrecedence.script.sequence;
        } else if (org_default_script) {
            this.script_id = _.get(ScriptService, 'scripts.org_default_script_id', null);
            sequence = _.cloneDeep(org_default_script).map(el => ({
                ...el,
                schedule_id: 0,
                script_id: 0
            }));
        } else {
            UpshowLogger.log(['ScriptService', 'getActiveScheduledScript'], `There is not a schedule or default script to load.`);
        }

        if (sequence && sequence.length > 0 && sequence[0].state === 'smart') {
            let statesPercentage;
            const metadata = sequence[0].metadata;

            if (metadata) {
                statesPercentage = metadata.statesPercentage;
            } else {
                const active_smart_script = _.get(ScriptService, 'scripts.active_smart_script', null);
                statesPercentage = active_smart_script[0].metadata.statesPercentage;
            }
        
            sequence = SmartPlaylist.getSmartScript(statesPercentage, getAvailableStates());
        }

        return sequence;
    },
    getScript: function () {
        const script = SettingsService.getUiSetting('script', []);
        if (!script || script?.length === 0) {
            const sequence = ScriptService.getActiveScheduledScript();
            return formatSequence(sequence);
        }
        return formatSequence(_.cloneDeep(script));
    },
    startScriptService: function () {
        const spotlightOnly = SettingsService.hasTrueUiSetting('spotlight_only');
        if (spotlightOnly || window.inactive) {
            return;
        }

        const nextScript = ScriptService.getScript();
        const nextScriptStringified = JSON.stringify(nextScript);

        if (!_.isEqual(nextScriptStringified, StateService.script_stringified)) {
            ScriptService.granular_states.clear();
            StateService.setScript(nextScript);

            const is_current_state_takeover = _.get(StateService, 'currentStateDef.takeover', false);
            if (!StateService.isPlayingLiveEvent() && !is_current_state_takeover) {
                StateService.advanceState();
            }
        }

        setTimeout(ScriptService.startScriptService, ScriptService.timeout_schedules_check);
    },
    getCurrentGranularityId: function (state) {
        const meta_ids = _.get(state, 'metadata.id');
        const idx = _.get(state, 'idx');

        if (!!meta_ids && !Array.isArray(meta_ids) && isFinite(meta_ids)) {
            return meta_ids;
        }

        if (Array.isArray(meta_ids) && meta_ids.length > 0) {
            const pastIdx = ScriptService.granular_states.get(idx) ?? meta_ids.length - 1;
            const nextIdx = (pastIdx + 1) % meta_ids.length;
            ScriptService.granular_states.set(idx, nextIdx);
            return meta_ids[nextIdx];
        }

        return null;
    }
};

export default ScriptService;
