import { cloneDeep, findIndex } from 'lodash';
import axiosInstance from '../libs/axios';
import UpshowLogger from '../Logger';

const hasPagination = [
  'boh_type_training_course'
];

export default function TemplateService() {
  this.spotlightPagination = []; //TODO: Use a dictionary instead of an array

  this.pagePositions = {};

  this.addSpotlight = (spotlight) => {
    const { boh_data } = spotlight;

    if (!boh_data) return;

    const index = findIndex(this.spotlightPagination, (s) => s.spt_id === spotlight.spt_id)
    const formattedSpotlight = this.formatSpotlight(spotlight);

    if (boh_data && index === -1) {
      this.spotlightPagination.push(formattedSpotlight);
    } else if(boh_data && index > -1) {
      this.spotlightPagination[index] = formattedSpotlight
    }
  };

  this.getNextDataForSpotlight = (spotlightId) => {
    const spotlight = this.spotlightPagination.find((s) => s.spt_id === spotlightId);

    if (!spotlight) return null;

    const { spt_id, boh_data } = spotlight;

    let currentPosition = this.pagePositions[spt_id.toString()];

    let nextPagePosition;

    if (typeof currentPosition == 'undefined') {
      nextPagePosition = 0;
    } else {
      nextPagePosition = currentPosition + 1;
    }

    this.pagePositions[spt_id.toString()] = nextPagePosition;

    return {...boh_data, hits: nextPagePosition };
  };

  this.formatSpotlight = (spotlight) => {
    const type = spotlight.properties.find(prop => prop.startsWith("boh_type_"));

    if (hasPagination.includes(type)) {
      const { spt_id, boh_data } = spotlight;
      return {spt_id, boh_data};
    }

    return spotlight;
  };


  this.populateTemplateSpotlight = async (spotlight) => {
    const newSpotlight = cloneDeep(spotlight)

    const spotlightId = spotlight.spt_id
    UpshowLogger.debug(['spotlightstate', 'preload'], `getting boh data for spotlight ${spotlightId}`);
    const boh_data = await this.getSpotlightBackOfHouseData(spotlightId)
    UpshowLogger.debug(['spotlightstate', 'preload'], `boh data for spotlight ${spotlightId} is:  ${JSON.stringify(boh_data)}`);

    this.addSpotlight({ ...newSpotlight, boh_data })

    const data = this.getNextDataForSpotlight(spotlightId);

    return {...newSpotlight, boh_data:data };
  };

  this.getSpotlightBackOfHouseData = async (spotlightId) => {
    const response = await axiosInstance.get(`/spotlight/${spotlightId}`)

    return response.data

  }
}
