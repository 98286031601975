"use strict";

var _lodash = require("lodash");
var _helpers = require("./helpers");
const SmartPlaylist = {
  currentScript: null,
  categoryStates: {
    social: ['socialgrid', 'socialzoom'],
    entertainment: ['jukin', 'upshownow', 'plutotv', 'trivia', 'watchtowin', 'sportsbook'],
    qr_campaigns: ['qr_campaign'],
    spotlights: ['spotlight'],
    plugins: ['application']
  },
  // Average duration of states in categories
  categoryAverageDuration: {
    qr_campaigns: 45,
    spotlights: 25,
    social: 14,
    entertainment: 168,
    plugins: 50
  },
  // Percentage desired of each category in time window
  categoryDesiredPercentage: {
    qr_campaigns: 20,
    spotlights: 20,
    social: 10,
    entertainment: 30,
    plugins: 20
  },
  // Desired sequence duration
  timeWindow: 3600,
  // Sliders weight is converted to percentages before saving a preset
  convertWeightsToPercentages(weights) {
    const totalSum = Object.values(weights).reduce((sum, weight) => sum + weight, 0);
    return (0, _helpers.calculatePercentages)(weights, totalSum);
  },
  // Validates sliders weights input
  validSmartWeight(slidersWeights) {
    const expectedProperties = Object.keys(this.categoryStates);
    const actualProperties = Object.keys(slidersWeights);
    const allPropertiesExist = expectedProperties.every(property => actualProperties.includes(property));
    const values = Object.values(slidersWeights);
    if (!allPropertiesExist || values.some(value => value > 100 || value < 0)) return false;
    return values.some(value => value > 0);
  },
  // Generate the full sequence and info of the sequence
  generateFullCategorySequence() {
    // Get appearances for each category
    const appearances = (0, _helpers.calculateCategoryAppearances)(SmartPlaylist);

    // Generate and merge the appearances into a sequence
    const sequence = (0, _helpers.evenlyDistributeCategories)({
      ...appearances
    });

    // Calculate the total duration for each category based on the appearances and the average duration
    const timeTotal = Object.entries(appearances).reduce((acc, [cat, count]) => {
      acc[cat] = count * this.categoryAverageDuration[cat];
      return acc;
    }, {});
    return {
      sequence,
      appearances,
      timeTotal,
      sequenceTotal: Object.values(timeTotal).reduce((sum, time) => sum + time, 0) //Total time of the sequence
    };
  },
  // Main function to get a smart script
  getSmartScript(newCategoryDesiredPercentage, newCategoryStates) {
    if (newCategoryStates) this.categoryStates = newCategoryStates;

    // If they ask for the same percentage that is already generated
    if (this.currentScript && (!newCategoryDesiredPercentage || (0, _lodash.isEqual)(newCategoryDesiredPercentage, this.categoryDesiredPercentage))) return this.currentScript;

    // New percentages loaded
    if (newCategoryDesiredPercentage) this.categoryDesiredPercentage = newCategoryDesiredPercentage;

    // Generate the sequence
    const result = this.generateFullCategorySequence();
    // Updates current script
    this.currentScript = (0, _helpers.buildScriptFromSequence)(result.sequence, this.categoryStates);
    return this.currentScript;
  },
  // Generate Percentage based on time total
  getTimeBasedPercentages(timeTotal, sequenceTotal) {
    return (0, _helpers.calculatePercentages)(timeTotal, sequenceTotal);
  }
};
module.exports = {
  SmartPlaylist
};