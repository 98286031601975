import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import lottie from 'lottie-web';
import Lottie from '@upshow/lottie';

import './Jukin.scss';

import jukinIntro from './animations_assets/lottie.json';
import jukinCover from './animations_assets/cover.png';

import { isVideoFile } from './utils';
import { UpShowDataContext } from '../../UpshowContext';

const START_DELAY_SECONDS = 3;
const FINISH_DELAY_SECONDS = 4;

class Jukin extends Component {
    static contextType = UpShowDataContext;

    constructor (props) {
        super(props);

        const step = 0;

        this.state = {
            step,
            show_start_plate: true
        };

        let resolveAnimationItem;
        this.animationItemPromise = new Promise((resolve, reject) => {
            resolveAnimationItem = resolve;
        });
        this.animationItemPromise.then(item => {
            setTimeout(() =>
                item.playSegments([
                    [0, 150],
                    [140, 150],
                    [140, 150],
                    [140, 150],
                    [140, 150]
                ], true), 1000
            );

        });

        this.resolveAnimationItem = resolveAnimationItem;

    }

    onEnded = (params) => {

        const { media } = this.props;
        const { step } = this.state;

        const isLastVideo = step === (media.length - 1);

        if (isLastVideo) {
            if (this.introanim) {
                this.introanim.destroy();
            }
            this.props.onEnded(params);

        } else {
            this.setState({
                step: step + 1,
                show_start_plate: true,
                show_finish_plate: false,
                played_through: false,
                played_through_should_pause: false
            });
        }
    };

    componentWillUnmount () {
        clearTimeout(this.animationTimeout);
    }

    playAnimation = async () => {
        if (this.animationTimeout) {
            clearTimeout(this.animationTimeout);
        }
        this.animationTimeout = setTimeout(this.playAnimation, 45000);

        const introAnimationItem = await this.animationItemPromise;
        introAnimationItem.playSegments([240, 690], true);
    };

    onPlay = () => {
        if (typeof this.props.onPlay === 'function') {
            this.props.onPlay();
        }

    };

    onProgress = async progress => {

        const change = {};
        let remove_cover = false;

        if (progress && progress.playedSeconds) {

            change.show_start_plate = progress.playedSeconds < START_DELAY_SECONDS;

            if (this.state.duration) {
                const remaining = Math.max(0, this.state.duration - progress.playedSeconds);
                change.played_through = remaining < FINISH_DELAY_SECONDS;
                change.show_finish_plate = change.played_through;

            } else {
                change.show_finish_plate = false;
            }

            const should_finish = change.played_through && !this.state.played_through;

            remove_cover = !change.show_start_plate && this.state.show_start_plate;

            // Only react on changes
            if (
                change.show_start_plate !== this.state.show_start_plate ||
                change.played_through !== this.state.played_through ||
                change.show_finish_plate !== this.state.show_finish_plate
            ) this.setState(change);

            // Set video as completed if it played through
            if (should_finish) {
                const introAnimationItem = await this.animationItemPromise;
                introAnimationItem.playSegments([690, 695], true);

                setTimeout(this.onEnded, 1000);
            }

        }

        if (remove_cover) {
            const introAnimationItem = await this.animationItemPromise;
            introAnimationItem.playSegments([150, 185], true);
            setTimeout(this.playAnimation, 4500);
        }

        if (typeof this.props.onProgress === 'function') {
            this.props.onProgress(progress);
        }
    };

    onDuration = duration => {

        this.setState({
            duration
        });

        if (typeof this.props.onDuration === 'function') {
            this.props.onDuration(duration);
        }
    };

    loadIntro = (ref, preRollAnimation) => {
        if (!ref) return;
        if (this.introanim) return;

        const animationData = !!preRollAnimation ? { path: preRollAnimation } : { animationData: jukinIntro };

        const animData = {
            container: ref,
            loop: false,
            autoplay: false,
            ...animationData,
        };

        this.introanim = lottie.loadAnimation(animData);
        this.introanim.addEventListener('DOMLoaded', () => {
            this.resolveAnimationItem(this.introanim);
        });
        window.introanim = this.introanim;

    };

    render () {
        const {
            media,
            playing,
            preRollImage,
            preRollAnimation,
            postRollAnimation,
            skipPlates,
            watermarkUrl,
            muted,
            onReady,
            onError,
        } = this.props;
        const {
            step,
            show_start_plate,
            show_finish_plate,
        } = this.state;

        const { contentVolume } = this.context;
        const plateImage = preRollImage ?? jukinCover;

        let url = media[step].url;
        let subtitles = media[step].subtitles_url;

        // If skipPlates is defined then rolls are included in steps so we have to check that.
        // Else we check the show and finish plate variables
        const isRolling = skipPlates
            ? (preRollAnimation && step === 0) || (postRollAnimation && step === media.length - 1)
            : show_start_plate || show_finish_plate;

        const is_muted = muted || (!skipPlates && (show_start_plate || show_finish_plate));

        let prerollComponent;

        if (show_start_plate && !skipPlates) {
            if (isVideoFile(preRollAnimation)) {
                prerollComponent = (
                    <ReactPlayer id="intro"
                                 key="cover"
                                 className={'intro_animation'}
                                 height="100%"
                                 width="100%"
                                 style={{ left: 0 }}
                                 url={preRollAnimation}
                                 playing={playing}
                                 muted={is_muted}
                    />
                );
            } else {
                prerollComponent = <div id="intro" className="intro_animation" ref={ref => this.loadIntro(ref, preRollAnimation)}/>;
            }
        }

        const config = {
            youtube: {
                playerVars: { cc_load_policy: 1 }
            },
            vimeo: {
                playerOptions: { texttrack: 'en' }
            },
            file: {}
        };

        // When using our own CDN for media channel content
        if (url.match('media\\.upshow\\.tv') && (window.sw_enabled || window.UPshowAndroidApp)) {
            config.file.attributes = {
                crossOrigin: 'anonymous',
                poster: 'https://cdn.upshow.tv/1x1.png'
            };
        }

        if (subtitles) {
            config.file.tracks = [
                { kind: 'subtitles', src: subtitles, srcLang: 'en', default: true },
            ];
        }

        return (
            <div className="Jukin">
                <ReactPlayer
                    width="100%"
                    height="100%"
                    onEnded={this.onEnded}
                    onReady={onReady}
                    onError={onError}
                    onPlay={this.onPlay}
                    onDuration={this.onDuration}
                    onProgress={this.onProgress}
                    playing={playing}
                    url={url}
                    muted={is_muted}
                    volume={is_muted ? 0 : contentVolume}
                    config={config}
                    key={url}
                />
                {watermarkUrl && !isRolling && (
                    <div className="overlay">
                        <Lottie
                            options={{
                                loop: true,
                                autoplay: true,
                                path: watermarkUrl
                            }}
                        />
                    </div>
                )}

                {prerollComponent}

                <img className={show_start_plate && !skipPlates ? 'start_plate' : 'start_plate hidden'} src={plateImage} alt="cover init"/>
                <img className={show_finish_plate && !skipPlates ? 'finish_plate' : 'finish_plate hidden'} src={plateImage} alt="cover end"/>

            </div>
        );
    }
}

export default Jukin;
