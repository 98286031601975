import HwFeatureService from "./HwFeatureService";

class TileService3 {

    static normalizeContent(item) {
        return {
            id: item.id,
            thumbnail: item.gridThumbnail,
            url: item.url,
            type: item.type,
            typeCount: item.typeCount,
        };
    }

    constructor(content = []) {
        this.erroredContent = new Set();
        this._content = [...content];
        this._iterator = this.circularIterator();
        this._tileContentGenerator = this.tileContentGenerator();
    }

    get content() {
        return this._content.filter(c => !this.erroredContent.has(c.id));
    }

    set content(value) {
        if (!Array.isArray(value)) {
            throw new Error(`[TileService3] Content must be an array, received ${value}`);
        }

        this._content = value;
        this._iterator = this.circularIterator();
        this._tileContentGenerator = this.tileContentGenerator();
        this.erroredContent.clear();
    }

    * circularIterator() {
        let i = 0;
        let videoCount = 0;
        let imageCount = 0;

        while (true) {

            if (this.content.length === 0) {
                throw new Error('[TileService3] No content to be iterated');
            }

            const item = this.content[i];

            yield {
                ...item,
                typeCount: item.type === 'video' ? ++videoCount : ++imageCount,
            };

            i = (i + 1) % this.content.length;

        }
    }

    * tileContentGenerator() {
        const maxContentItems = 2;
        const contentQueue = [];

        while (true) {

            // If content queue has no enough items, fill it up
            if (contentQueue.length < maxContentItems) {
                for (let i = contentQueue.length; i <= maxContentItems; i++) {
                    const item = this.getNextContentItem();
                    if (item) {
                        contentQueue.push(item);
                    } else {
                        break;
                    }
                }
            }

            // Generate tile content
            const tileContent = [];

            // Pull in the first item from the content queue
            tileContent.push(contentQueue.shift());

            // If it's an image, try to put more images so it can flip.
            if (tileContent[0].type === 'image' && HwFeatureService.isSupported('tile-flip')) {
                for (let i = tileContent.length; i < maxContentItems; i++) {

                    if (contentQueue[0].type === 'image') {
                        tileContent.push(contentQueue.shift());
                    } else {
                        break;
                    }

                }
            }

            yield tileContent;
        }
    }

    getNextContentItem() {
        const item = this._iterator.next().value;
        if (item) return TileService3.normalizeContent(item);
    }

    getNextTileContent() {
        return this._tileContentGenerator.next().value;
    }

    markContentAsErrored(id) {
        this.erroredContent.add(id);
    }

}

export default TileService3;