import React from 'react';
import PropTypes from 'prop-types';
import { gsap } from '@upshow/gsap';

import './ZoomCta.scss';
import ZoomTextCta from './ZoomTextCta/ZoomTextCta';
import SettingsService from '../../../services/SettingsService';
import HwFeatureService from '../../../services/HwFeatureService';

class ZoomCta extends React.Component {

    constructor (props) {
        super(props);

        this.cta = SettingsService.getCta();

        const showCaptionOnZoom = !!Number.parseInt(SettingsService.getUiSetting('show_caption_on_zoom'), 10);

        this.state = {
            shouldFlip: showCaptionOnZoom && props.postDescription,
        };
    }


    componentDidUpdate(prevProps) {
        if (prevProps.isVertical !== this.props.isVertical) {
            this.onOrientationChange();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.playing && nextProps.playing !== this.props.playing && this.state.shouldFlip) {
            this.flipper();
        }
    }

    flipper() {
        // Only animate if elements already exist
        const refsExist = this.flipperCtaRef && this.flipperContainerRef && this.flipperDescriptionRef;

        if (refsExist && HwFeatureService.isSupported('caption_flip')) {
            const delay = this.props.duration / 2;
            gsap.set(this.flipperContainerRef, { transformStyle: 'preserve-3d', perspective: 900 });
            gsap.set(this.flipperCtaRef, { rotationY: 180 });
            gsap.set(this.flipperDescriptionRef, { rotationY: 0 });

            const tl = gsap.timeline({ paused: true });

            tl.to(this.flipperContainerRef, { duration: 1, rotationY: -180, delay: delay })
              .play();
        }
    }

    render() {
        const { postDescription } = this.props;

        let headerText = this.cta.alt_cta || 'Get on this screen!';

        if (headerText.indexOf("<br>") > 0) {
            headerText = headerText.split('<br>').map((line, idx) => (
                <span key={idx}>
                    {idx === 0 ? null : <br/>}
                    {line}
                </span>
            ));
        }

        return (
            <div className="ZoomCta">
                <ZoomTextCta
                    flipperContainerRef={el => this.flipperContainerRef = el}
                    flipperDescriptionRef={el => this.flipperDescriptionRef = el}
                    flipperCtaRef={el => this.flipperCtaRef = el}
                    postDescription={postDescription}
                    headerText={headerText}
                    longCta={this.cta.long_cta}
                    hashtags={this.cta.tags}
                    isVertical={this.props.isVertical}
                    onError={this.props.onError}
                />
            </div>
        );
    }

}

ZoomCta.propTypes = {
    postNetwork: PropTypes.string.isRequired,
    postAuthor: PropTypes.string,
    postDescription: PropTypes.string,
    playing: PropTypes.bool.isRequired,
    duration: PropTypes.number.isRequired,
    isVertical: PropTypes.bool.isRequired,
};

export default ZoomCta;