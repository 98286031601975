import UpshowLogger from './Logger';
import fit_ui_container from './libs/fit_ui_container';
import { clearTeslaCache, detachMessageListener, spaceQuotaTeslaCache, getTeslaCache } from './Upshow';
import { getWebviewName } from './Activation';
import TrackerService from './services/TrackerService';
import { testSpeed } from './services/SpeedtestService';
import StateService from './services/StateService';
import { handleNewTakeover } from './services/OTWTService';

const STATIC_EXTENSION_ID = process.env.REACT_APP_KIOSK_EXTENSION_ID;
const processedCommandsIds = [];

const getDeactivatedUrl = () => {

    const searchParams = (new URL(location)).searchParams;
    searchParams.set('boot_source', 'ui-restart');
    let restart = window.location.origin;

    const webviewName = getWebviewName();

    if (webviewName) {

        if (webviewName.type === 'hash') {
            restart = restart.replace(`#${webviewName}`, '');
        }

        if (webviewName.type === 'params') {
            searchParams.delete('name');
        }
    }

    return `${restart}?${searchParams.toString()}`;
};

function sendToSW (message) {
    if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage(message);
    }
}

class CommandProcessor {
    process (command, source) {
        if (!command.hasOwnProperty('command')) {
            UpshowLogger.error(this.constructor.name, 'Command does not have correct format');
            return;
        }

        if (processedCommandsIds.includes(command.payload.uuid)) {
            return;
        }
        processedCommandsIds.push(command.payload.uuid);
        
        UpshowLogger.log(this.constructor.name, 'Processing ' + command.command + ' from source ' + source);
        TrackerService.appEvents.logMetric({ action: 'command', command, source });

        switch (command.command) {
            case 'identify':
                let element = document.createElement('div');
                element.setAttribute('id', 'identify_notification');
                element.innerHTML = '<i></i>';
                if (command.payload?.name) element.innerHTML = `<i/><span>${command.payload.name}</span>`;
                document.body.insertBefore(element, document.getElementById('root'));
                window.setTimeout(() => {
                    document.body.removeChild(element);
                }, 15000);
                break;
            case 'restart':
                //let's add the restart reason
                const restartUrl = `${window.location.href}${window.location.href.indexOf('?') === -1 ? '?' : '&'}boot_source=ui-restart`;
                window.location = restartUrl;
                break;
            case 'overscan':
                window.overscan = command.parameters.enabled;
                fit_ui_container();
                break;
            case 'fit_ui_container':
                fit_ui_container();
                break;
            case 'report_window_size':
                UpshowLogger.log(['report_window_size'], `width: ${window.innerWidth}, height: ${window.innerHeight}`);
                break;
            case 'clear_teslacache':
            case 'clearteslacache':
            case 'clear_tesla_cache':
                clearTeslaCache();
                break;
            case 'spacequota':
                spaceQuotaTeslaCache();
                break;
            case 'delete':
                clearTeslaCache();
                window.localStorage.clear();
                sendToSW({ type: 'CLEAR_CACHE' });

                const restart = getDeactivatedUrl();

                window.location = restart;
                break;
            case 'get_teslacache':
            case 'getteslacache':
                getTeslaCache();
                break;
            case 'cache_clear':
                //The token was deleted. We'll activate on reload @todo review
                UpshowLogger.log(['CommandProcessor', 'cache_clear'], 'Will clear localstorage ');
                const currentToken = window.localStorage.getItem('access-token');

                window.localStorage.clear();
                UpshowLogger.log(['CommandProcessor', 'cache_clear'], 'Cleared localstorage ');

                if (currentToken) {
                    window.localStorage.setItem('access-token', currentToken);
                }
                break;
            case 'clear_sw_cache':
                sendToSW({ type: 'CLEAR_CACHE' });
                break;
            case 'get_sw_quota':
                sendToSW({ type: 'QUOTA_ESTIMATE' });
                break;
            case 'get_sw_keys':
                sendToSW({ type: 'GET_KEYS', name: command.payload?.name });
                break;
            case 'delete_sw_key':
                if (!command.payload?.key) UpshowLogger.error('CommandProcessor', 'delete_sw_key missing key');
                sendToSW({ type: 'DELETE_KEY', name: command.payload?.name, key: command.payload.key });
                break;
            case 'deponger':
                detachMessageListener();
                break;
            case 'advance_state':
                StateService.advanceState();
                break;
            case 'speed_test':
                testSpeed(command.payload?.uuid, command.payload?.resource, command.payload?.resourceLength, command.payload?.idTag);
                break;
            case 'gc_cleanup':
                StateService.gc(true);
                break;
            case 'otwt_overtime':
                if (command.payload?.pluginId) {
                    handleNewTakeover(command.payload.pluginId);
                } else {
                    UpshowLogger.error('CommandProcessor', 'Skipping otwt_overtime command: missing plugin ID');
                }
                break;
            case 'clear_browser_cache':
                if (chrome.runtime) {
                    chrome.runtime.sendMessage(STATIC_EXTENSION_ID, {
                        methodName: 'removeCache',
                    }, function (arg) {
                        if (chrome.runtime.lastError) {
                            console.error({ error: chrome.runtime.lastError });
                        } else {
                            console.log(arg);
                        }
                    });
                }
                break;
            case 'update_live_event':
                if (command.payload) {
                    const { activeLiveEvent, timestamp } = command.payload;
                    StateService.setLiveEventStatus(activeLiveEvent, timestamp);
                } else {
                    UpshowLogger.error('CommandProcessor', 'Skipping update_live_event command: missing live event');
                }
                break;
            default:
                UpshowLogger.info('CommandProcessor', 'Skipping unknown command: ' + command.command);
                break;
        }
    }
}

export default CommandProcessor;
