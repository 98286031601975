import React, { useState } from 'react';
import './LiveEvent.scss';
import iframeWithWhisper from '../iframe/WhisperIframe';
import Iframe from '../iframe/Iframe';
import ReactPlayer from 'react-player';
import SettingsService  from '../../services/SettingsService';

export default function LiveEvent (props) {
    const [videoError, setVideoError] = useState(false);

    if (!props?.state?.liveEvent) return null;

    if (props.state.liveEvent.status === 'COMING') {
        const VideoUrl = 'https://cdn.upshow.tv/ui-assets/Everpass_Live_Event_60s.mp4';
        return <>
            {
                (!videoError)
                    ? <ReactPlayer
                        width="100%"
                        height="100%"
                        onError={() => setVideoError(true)}
                        playing={true}
                        url={VideoUrl}
                        muted={false}
                        volume={1}
                        key={VideoUrl}
                        loop={true}
                    />
                    : <img width="100%" height="100%" src="https://cdn.upshow.tv/ui-assets/Everpass_Live_Event.jpg" alt="Event coming soon"/>
            }
        </>;
    } else if (props.state.liveEvent.status === 'LIVE') {
        if (window.UPshowAndroidApp) { //we're in UPshow's android app, let the app take over
            return <img width="100%" height="100%" src="https://cdn.upshow.tv/ui-assets/Everpass_Live_Event.jpg" alt="Event coming soon"/>
        }

        const metadata = props.state.liveEvent.metadata ?? {};
        const token = props.state.liveEvent.token ?? "";

        const url = new URL(metadata.url);
        url.searchParams.set('device_id', props.settings.device_id);
        url.searchParams.set('evid', props.state.liveEvent.id);

        if (token) {
            url.searchParams.set('token', token);
        }

        const withCC = SettingsService.getSetting('withCC');

        if (withCC) {
            url.searchParams.set('withCC', withCC);
        }

        const playerUrl = url.toString();


        const IframeComponent = iframeWithWhisper(Iframe);

        return (
            <>
                {props.playerError && <img width="100%" height="100%" src={comingSoonImg} alt="Event coming soon"/>}
                <IframeComponent
                    className="LiveEvent"
                    disableWatchdog={true}
                    onDone={props.done}
                    onError={(error) => {
                        props.error(error);
                    }}
                    onProgress={() => {
                        if (props.playerError) {
                            props.solveError();
                        }
                    }}
                    src={playerUrl}
                />
            </>
        );
    }
}
