import UpshowLogger from '../Logger';
import * as UP from '../Upshow';

const ApplicationService = {
    applications: [],
    loadApplications: (applications) => {
        ApplicationService.applications = applications;
    },
    getApplicationInstance: async (id) => {
        const applicationInstance = ApplicationService.applications.find(app => app.id === id);

        if (!!applicationInstance) {
            return applicationInstance;
        }
        const response = await UP.getApplicationInstance(id);
        const { server_error, error } = response;
        if (server_error) {
            UpshowLogger.error(['ApplicationService', 'getApplicationInstance'], server_error);
        } else if (error) {
            UpshowLogger.warn(['ApplicationService', 'getApplicationInstance'], error);
        } else {
            ApplicationService.applications.push(response.application_instance);
        }

        return response.application_instance;
    },
};

export default ApplicationService;

