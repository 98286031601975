import Logger from '../Logger';
import _ from 'lodash';
import * as UP from '../Upshow';
import { scheduleisActive } from '@upshow/sched-utils';
import SettingsService from './SettingsService';
import DisplayRulesService from './DisplayRulesService';
import ScriptService from './ScriptService';

const _GetItemsByFirstIndex = (items, firstIndex) => {
    let itemsSorted = [];
    for (let i = 0; i < items.length; i++) {
        itemsSorted.push(Object.assign({}, items[(firstIndex + i) % items.length]));
    }
    return itemsSorted;
};

const MediaService = {
    media: [],
    stateKeeper: {
        plutotv: null,
        upshownow: null,
        application: null,
        jukin: null,
        trivia: null,
    },
    loadMedia: (media) => {
        MediaService.media = media;
        Logger.info(['scheduledmedia', 'loadMedia'], 'Loaded ScheduledMedia ' + media.length);
    },
    getAllActive: (types = []) => {
        //always enforce scheduling
        let activeMedia = MediaService.getActiveMedia(types);
        Logger.debug(['scheduledmedia', 'getcurrent'], 'Number of active media items ' + activeMedia.length + ' of ' + MediaService.media.length);
        return activeMedia;
    },
    getFirstMediaValidID: function getFirstMediaValidID (mediaId, state, type) {
        if (!SettingsService.hasFeature('dr_time_based')) return mediaId;

        const currentIsoDate = UP.getCurrentISODate();
        const ruleListValidator = DisplayRulesService.getRulesValidator(currentIsoDate);

        const mediaItem = MediaService.getMediaItem(mediaId, type);
        if (mediaItem && ruleListValidator(mediaItem.time_based_display_rules)) return mediaId;

        for (const step of state.metadata.id) {
            const nextMediaId = ScriptService.getCurrentGranularityId(state);
            const mediaItem = MediaService.getMediaItem(nextMediaId, type);
            if (mediaItem && ruleListValidator(mediaItem.time_based_display_rules)) return nextMediaId;
        }

        return null;
    },
    getNextMediaItem: function getNextMediaItem (behavior, state, type = null) {
        let activeItems = MediaService.getActiveItemsByBehavior(behavior, (type === 'sportsbook' || type === 'plugin') ? [type] : [state]);

        if (state === 'application') {
            activeItems = activeItems.filter(content => !content.is_only_playlist);
        }

        if (MediaService.stateKeeper[state] == null) {
            MediaService.stateKeeper[state] = activeItems.length > 0 ? activeItems[0] : null;
            return activeItems.length > 0 ? activeItems[0] : null;
        }

        const index = (_.findIndex(activeItems, MediaService.stateKeeper[state]) + 1) % activeItems.length;

        MediaService.stateKeeper[state] = activeItems[index];

        return Object.assign({}, MediaService.stateKeeper[state]);
    },
    getNextMediaItems: function getNextMediaItems (behavior, state) {
        const activeItems = MediaService.getActiveItemsByBehavior(behavior, [state]);

        if (activeItems.length === 0) return null;

        if (MediaService.stateKeeper[state] == null) {
            MediaService.stateKeeper[state] = activeItems[0];
            return _GetItemsByFirstIndex(activeItems, 0);
        }

        const indexPrev = activeItems.findIndex(fd => (fd.id === MediaService.stateKeeper[state].id));
        const index = (indexPrev + 1) % activeItems.length;

        MediaService.stateKeeper[state] = activeItems[index];

        return _GetItemsByFirstIndex(activeItems, index);
    },
    getActiveItemsByBehavior: (behavior = 'takeover', schedules_type = []) => {

        if (!SettingsService.hasFeature('dr_time_based')) {
            let schedules = MediaService.getActiveMedia(schedules_type).filter(sch => sch.behavior === behavior);
            return Array.isArray(schedules) ? schedules : [];
        }

        if (behavior === 'takeover') return [];

        if (behavior === 'script') return MediaService.getValidMedia(schedules_type[0]);
    },
    getValidMedia: (type) => {
        const currentIsoDate = UP.getCurrentISODate();
        const ruleListValidator = DisplayRulesService.getRulesValidator(currentIsoDate);

        if (type === 'sportsbook') return MediaService.media.filter(mediaChannel => mediaChannel.type === 'application' && mediaChannel.application.feedType === 'SPORTSBOOK' && ruleListValidator(mediaChannel.time_based_display_rules));
        if (type === 'plugin') return MediaService.media.filter(mediaChannel => mediaChannel.type === 'application' && mediaChannel.application.feedType !== 'SPORTSBOOK' && ruleListValidator(mediaChannel.time_based_display_rules));
        return MediaService.media.filter(mediaItem => mediaItem.type === type && ruleListValidator(mediaItem.time_based_display_rules));
    },
    getMediaItem: function getMediaItem (mediaId, mediaType) {
        return MediaService.media.find(mediaItem => mediaItem.id === mediaId && mediaItem.type === mediaType);
    },
    isOn: () => {
        return !!MediaService.getCurrentTakeoverItem();
    },
    getActiveMedia: (schedules_type) => {
        //get current datetime to filter media by schedule
        let currentIsoDate = UP.getCurrentISODate();
        let activeMedia = MediaService.filterMedia(MediaService.media, currentIsoDate, schedules_type);

        activeMedia = _.sortBy(activeMedia, 'position');

        return activeMedia;
    },
    filterMedia: (allMedia, currentIsoDate, schedules_type) => {

        // Guard against uninitialized or missing media
        if (!allMedia) {
            return [];
        }
        // No connection? no Scheduled Media for you!
        const connected = UP.getDeviceInfo().connected;
        const onlineOnlyMediaTypes = ['trivia', 'plutotv'];
        const dayFields = ['sundays', 'mondays', 'tuesdays', 'wednesdays', 'thursdays', 'fridays', 'saturdays'];
        const default_tz = SettingsService.getTimezone();

        return allMedia.filter((item) => {
            // If specified, filter by types
            if (schedules_type && schedules_type.length > 0 && !schedules_type.includes(item.schedule_type)) {
                return false;
            }

            if (!connected && (onlineOnlyMediaTypes.includes(item.schedule_type))) {
                return false;
            }

            const schedule = {
                validFrom: item.validFrom,
                validFromTime: item.validFromTime,
                validThrough: item.validThrough,
                validThroughTime: item.validThroughTime,
                ..._.pick(item, dayFields)
            };

            return scheduleisActive(currentIsoDate, schedule, item.tz || default_tz);
        });
    }
};

window.smservice = MediaService;
export default MediaService;

