import ReactDOM from 'react-dom';
import timer from 'react-native-timer';
import AbstractState from './AbstractState';
import Logger from '../Logger'
import TrackerService from "../services/TrackerService";
import {getCurrentTimestamp} from '../Upshow';
import ScriptService from '../services/ScriptService';
import { ContentAudioService } from '../services/ContentAudioService';

class UPshowState extends AbstractState {
    constructor(node, state) {
        super(node, state);
        this.node = node;
        this.state = state;
        this.state.playing = false;
        this.state.soundState = { shouldSound: false, disableOverride: false };

        this.handlers = {};

        this.state.readyPromise = new Promise((resolve, reject) => {
            this.handlers.rejectReady = reject;
            this.handlers.resolveReady = resolve;

        });

        this.state.playPromise = new Promise((resolve, reject) => {
            this.handlers.rejectPlay = reject;
            this.handlers.resolvePlay = resolve;

        });

        this.state.pausePromise = new Promise((resolve, reject) => {
            this.handlers.rejectPause = reject;
            this.handlers.resolvePause = resolve;

        });

        this.state.donePromise = new Promise((resolve, reject) => {
            this.handlers.rejectDone = reject;
            this.handlers.resolveDone = resolve;

        });

        this.raiseReady = this.raiseReady.bind(this);
        this.raisePlaying = this.raisePlaying.bind(this);
        this.raiseDone = this.raiseDone.bind(this);
        this.raiseError = this.raiseError.bind(this);
        this.raisePaused = this.raisePaused.bind(this);
        this.raiseWarning = this.raiseWarning.bind(this);
        this.raiseSkip = this.raiseSkip.bind(this);

        this.metricData = {}
    }

    get name() {
        return 'upshowstate';
    }

    get mainContentUrl() {
        return this.state.mainContentUrl;
    }

    get video() {
        return !!this.state.isVideo;
    }

    get playThrough() {
        return !!this.state.isPlayThrough;
    }

    get playlistOrTakeoverId() {
        if(this.state.state?.metricData?.takeover) {
            return -1;
        } else {
            return ScriptService.getActivePlaylistId();
        }
    }

    raiseReady(data) {
        Logger.debug(['upshowstate', 'ready'], 'Raise ready ' + this.name);
        if (this.state.logImpressions && !this.playThrough && !this.state.loggedImpression) {
            TrackerService.trackEvent('impression-' + this.correlativeNumber, {
                object: this.name,
                source: 'loaded',
                playlist_id: this.playlistOrTakeoverId,
                ...this.state.tracking
            });
            this.state.loggedImpression = true;
        }
        this.handlers.resolveReady(data);
    }

    raisePlaying(data) {
        Logger.debug(['upshowstate', 'playing'], 'Raise playing ' + this.name);
        this.handlers.resolvePlay(data);
        ContentAudioService.increaseAudioContent();
    }

    raiseWarning(data) {
        Logger.warn('Raise warning ' + this.name, data);
    }

    raisePaused(data) {
        Logger.debug(['upshowstate', 'paused'], 'Raise paused ' + this.name);
        this.handlers.resolvePause(data);
    }

    raiseDone(data) {
        Logger.debug(['upshowstate', 'done'], 'Raise done ' + this.name);
        this.handlers.resolveDone(data);
    }

    raiseError(error) {
        if (error && error.upshowType && error.upshowType === 'warning') {
            Logger.warn('upshowstate raise warning, reject all ' + this.name);
        } else {
            Logger.error(`upshowstate raise error, reject all ${this.name}`, error);
        }

        //TODO only reject promises that were returned to a client
        this.handlers.rejectReady(error);
        this.handlers.rejectPlay(error);
        this.handlers.rejectPause(error);
        this.handlers.rejectDone(error);
    }

    raiseSkip(message) {
        Logger.log(['upshowstate', 'skip'], message);
        if (this.state.playing) {
            const error = new Error('Skip state executed after playing');
            this.handlers.rejectReady(error);
            this.handlers.rejectPlay(error);
            this.handlers.rejectPause(error);
            this.handlers.rejectDone(error);
        } else {
            this.handlers.resolveReady('skipState')
        }
    }

    preload() {
        this._render(this.handlers.resolveReady);

        return this.state.readyPromise;

    }

    setTimeout = (...args) => {
        return timer.setTimeout(this, ...args);
    };

    clearTimeout = (...args) => {
        return timer.clearTimeout(this, ...args);
    };

    setInterval = (...args) => {
        return timer.setInterval(this, ...args);
    };

    clearInterval = (...args) => {
        return timer.clearInterval(this, ...args);
    };


    _render(resolve) {
        Logger.debug(['upshowstate', '_render'], '_render ' + this.name);
        resolve();
    }

    _playDuration() {

        this.state.playStart = getCurrentTimestamp();

        this.setInterval('duration', () => {

            this.state.lastTick = getCurrentTimestamp();

            if (this.state.logImpressions) {
                // Tracking real duration time of the appearances
                TrackerService.trackEvent('impression-' + this.correlativeNumber, {
                    object: this.name,
                    source: 'playing',
                    duration: this.state.lastTick - this.state.playStart,
                    playlist_id: this.playlistOrTakeoverId,
                    ...this.state.tracking
                });
                this.state.loggedImpression = true;
            }

        }, 10000);

        //Set a timer to complete the state when duration has elapsed
        if (this.state.duration && this.state.duration > 0) {

            Logger.info(['upshowstate', 'playduration'], "Completed Duration " + this.name + ' duration: ' + this.state.duration);

            const durationTimeOut = () => {
                Logger.info(['upshowstate', 'playduration'], "Completed Duration " + this.name + ' duration: ' + this.state.duration);
                this.raiseDone('duration');
                this.clearInterval('duration');
            };

            this.setTimeout('duration', durationTimeOut, this.state.duration * 1000);
        }
    }

    play() {
        Logger.info(['upshowstate', 'play'], "Called play " + this.name);

        this.state.playing = true;

        this._playDuration();

        this._render(this.handlers.resolvePlay);

        this.metricData.start = new Date();
        return this.state.playPromise;
    }

    done() {
        Logger.info(['upshowstate', 'done'], "Called done " + this.name);
        return this.state.donePromise;
    }

    _pauseDuration() {
        this.clearTimeout('duration');
        this.clearInterval('duration');

    }

    pause() {
        Logger.info(['upshowstate', 'pause'], "Called pause - default behavior " + this.name);
        this.state.playing = false;
        this._render(this.handlers.resolvePlay);
        this._pauseDuration();
        this.raisePaused();
        return this.state.pausePromise;
    }

    stop() {
        Logger.info(['upshowstate', 'stop'], "Called stop " + this.name);

        this.metricData.stop = new Date();
        this.metricData.duration = this.metricData.stop - this.metricData.start;

        if (this.state.logImpressions) {
            TrackerService.trackEvent('impression-' + this.correlativeNumber, {
                object: this.name,
                source: 'done',
                duration: this.metricData.duration,
                playlist_id: this.playlistOrTakeoverId,
                ...this.state.tracking
            });
            this.state.loggedImpression = true;
        }

        return this.pause();
    }

    destroy() {
        Logger.info(['upshowstate', 'destroy'], "Called destroy, clear timers " + this.name);
        this.clearInterval();
        this.clearTimeout();
        let parentNode = this.node.parentNode;
        if (parentNode) {
            parentNode.removeChild(this.node);
        }
        let nodeRemove = ReactDOM.unmountComponentAtNode(this.node);
        this.node = null;
        return nodeRemove;
    }

    static appliesTo() {
        return true;
    }

}

export default UPshowState;
