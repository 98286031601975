import { ContentAudioService } from './services/ContentAudioService';
import { createContext, useState, useEffect } from "react";

const UpShowDataContext = createContext({ 
    contentVolume: ContentAudioService.audioChannels.ContentAudio.currentVolume
});

const UpshowContextProvider = ({ children }) => {

    const [contentVolume, setContentVolume] = useState(ContentAudioService.audioChannels.ContentAudio.currentVolume);

    useEffect(() => {
        const interval = setInterval(() => {
            setContentVolume(ContentAudioService.audioChannels.ContentAudio.currentVolume)
        }, 100);
        return () => clearInterval(interval);
    }, []);

    return (
        <UpShowDataContext.Provider value={{ contentVolume }}>
            {children}
        </UpShowDataContext.Provider>
    );
};

export { UpShowDataContext, UpshowContextProvider };