import { StatePreconditionError } from "./Errors";

const StateFactory = {
    registeredTypes: new Map(),
    register(clazzname, clazz, priority) {
        let clazzDefinition = {
            clazzname: clazzname,
            clazz: clazz,
            priority: priority
        };

        if (!(StateFactory.registeredTypes.has(clazzname))) {
            StateFactory.registeredTypes.set(clazzname, [clazzDefinition]);
        } else {
            let types = this.registeredTypes.get(clazzname);
            if (!types.find(x => x.clazz === clazz)) {
                types.push(clazzDefinition);
                types.sort((a, b) => a.priority - b.priority);
            }
        }
    },
    create(clazzname, meta, ...options) {
        if (!StateFactory.registeredTypes.has(clazzname)) {
            throw new Error('State not found ' + clazzname);
        }

        let types = this.registeredTypes.get(clazzname);
        let idx = 0;
        while (idx < types.length) {
            if (types[idx].clazz.appliesTo(meta, ...options)) {
                let clazz = types[idx].clazz;
                return new clazz(...options);
            }
            idx++;
        }

        throw new StatePreconditionError('No valid state found ' + clazzname);
    }
};

export default StateFactory;
