import React from 'react';
import ReactDOM from 'react-dom';
import UPshowState from '../UPshowState';
import StateFactory from '../StateFactory';

import Iframe from './Iframe';
import iframeWithWhisper from './WhisperIframe';

import _ from 'lodash';
import UpshowLogger from '../../Logger';
import ScreenService from "../../services/ScreenService";

export default class IframeState extends UPshowState {

    iframe = null;

    get name() {
        return 'iframe';
    }

    play() {

        UpshowLogger.debug('IframeState', `Will load url ${this.state.state.src}`);

        if (!_.get(this.state.state, 'disableWatchdog', false)) {
            try{
                Promise.race([this.iframe.invoke("play"), new Promise((r,e)=>setTimeout(()=>e("timeout"), 5000))])
                    .then((r)=>{
                        UpshowLogger.debug('IframeState', `Called play and got ${r}`);
                        this.state.playing = true;

                        this._playDuration();

                        this.handlers.resolvePlay();
                    })
                    .catch((e)=>{
                        UpshowLogger.error('IframeState', `errored with ${e}`);
                        this.raiseError(new Error("error iframe play"));
                    });
            } catch (e) {
                UpshowLogger.error('IframeState', `Error ${e} calling play`);
                this.raiseError(new Error("error iframe play"));
            }
        } else {
            return super.play();
        }

        UpshowLogger.log(['IframeState', 'play'], `Called play ${this.name} with url ${this.state.state.src}`);

        return this.state.playPromise;
    }

    preload() {

        let IframeComponent = Iframe;

        if (!_.get(this.state.state, 'disableWatchdog', false)) {
            IframeComponent = iframeWithWhisper(IframeComponent);
        }

        ReactDOM.render(<IframeComponent
            ref={ (el) => {this.iframe = el}}
            src={this.state.state.src}
            disableWatchdog={_.get(this.state.state, 'disableWatchdog', false)}
            onReady={this.raiseReady}
            onDone={this.raiseDone}
            onError={this.raiseError}
            onPlay={this.raisePlaying}
        />, this.node);

        return super.preload();

    }

    static appliesTo(meta, node, state) {
        return !ScreenService.isVertical || !!_.get(state, 'state.allowVertical');
    }

}

StateFactory.register('iframe', IframeState, 10);
