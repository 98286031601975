import { SpotlightPlayer } from '@upshow/spotlight-player';
import SettingsService from '../services/SettingsService';
import MultipleLayoutService from '../services/MultipleLayoutService';
import Logger from '../Logger';
import Iframe from '../states/iframe/Iframe';
import iframeWithWhisper from '../states/iframe/WhisperIframe';
import { buildIframeUrl } from '../libs/applications';

import './LayoutQuadrantPlayer.scss';

const fixedQuadrantCompareToQuadrantContent = (fixedQuadrant, quadrantContent) => (fixedQuadrant.id === quadrantContent.id && fixedQuadrant.type === quadrantContent.content_type);
const getContentID = (id, type) => `${type}_${id}`;

export default function LayoutQuadrantPlayer ({ quadrantKey, renderQuadrant }) {
    const deviceId = SettingsService.getSetting('device_id');
    const organizationId = SettingsService.getSetting('organization_id');

    const brandkit = SettingsService.getSetting('brandKit');

    const layoutInput = MultipleLayoutService.layoutInput;
    const content = MultipleLayoutService.content;
    const deadContentIds = MultipleLayoutService.deadContentIds;

    const script = layoutInput.script;
    const fixedQuadrants = script.filter(s => s.fixed && s.content && !deadContentIds.includes(getContentID(s.content.id, s.content.type)));
    const quadrantContent = fixedQuadrants.reduce((acc, quadrant) => ({
        ...acc,
        [quadrant.name]: content.find(c => fixedQuadrantCompareToQuadrantContent(c, quadrant.content))
    }), {});

    const { logo, background_color = '#F40FFFFF' } = SettingsService.getSetting('brandKit');
    const parsedContent = quadrantContent[quadrantKey];

    const handleOnError = (error) => {
        const { id, content_type } = parsedContent;
        const content_id = getContentID(id, content_type);

        Logger.error([`multiple-layout ERROR on quadrant [KEY - ${quadrantKey}]`], error);
        deadContentIds.push(content_id);
        renderQuadrant();
    };

    switch (parsedContent?.content_type) {
        case 'spotlight':
            return (
                <SpotlightPlayer
                    brandkit={brandkit}
                    spotlight={parsedContent}
                    playing={true}
                    onError={handleOnError}
                    muted={true}
                    loop={true}
                    deviceId={deviceId}
                    organizationId={organizationId}
                />
            );
        case 'application':
            const IframeComponent = iframeWithWhisper(Iframe);
            const iFrameURL = buildIframeUrl(parsedContent);
            let iframeRef;

            const handleOnReady = () => {
                iframeRef.invoke('play');
            };

            return (
                <IframeComponent
                    ref={(el) => iframeRef = el}
                    disableWatchdog={true}
                    onError={handleOnError}
                    onReady={handleOnReady}
                    src={iFrameURL}
                />
            );
        default:
            return (
                <div className={'emptyState'} style={{ backgroundColor: background_color }}>
                    <img src={logo}/>
                </div>
            );
    }
}