import SettingsService from '../services/SettingsService';
import { getDeviceInfo } from '../Upshow';
import { StatePreconditionError } from '../states/Errors';
import qs from 'qs';

const getAppSettingValue = (name, isInternal, defaultValue, isRequired, instanceSettings) => {
    let value;

    if (isInternal) {
        if (SettingsService.getUiSetting(name) !== undefined) {
            value = SettingsService.getUiSetting(name);
        } else if (getDeviceInfo()[name] !== undefined) {
            value = getDeviceInfo()[name];
        } else if (SettingsService.getSetting(name) !== undefined) {
            value = SettingsService.getSetting(name);
        } else {
            value = defaultValue;
        }
    } else {
        value = instanceSettings[name];
        if (!value) {
            value = SettingsService.getSetting(name);
        }
    }

    if (!value && !!isRequired) {
        throw new StatePreconditionError('No value provided for setting ' + name);
    }

    return value;
};

export const buildIframeUrl = (applicationInstance) => {
    let upshowAppsUrl = SettingsService.getUiSetting('app_url') || SettingsService.getUiSetting('default_app_url');
    upshowAppsUrl = upshowAppsUrl.replace(/http:\/\/|https:\/\//gi, '');

    const appUrl = applicationInstance.application.url;
    const appSettings = applicationInstance.application.settings;
    const appKey = applicationInstance.app_key;
    const instanceSettings = applicationInstance.settings;

    const params = (appUrl.match(/#([^#])+#/g) || []);

    const paramsValues = appSettings
        .filter(x => !x.query)
        .reduce((acc, x) => ({
            ...acc,
            [x.name]: getAppSettingValue(x.name, x.internal, x.default_value, x.is_required, instanceSettings)
        }), {});

    let url = new URL(params.reduce((url, p) => {
        const paramValue = paramsValues[p.slice(1, -1)];
        return url.replace(p, paramValue);
    }, appUrl));

    const queryParams = {
        ...qs.parse(url.search.slice(1)),
        ...appSettings
            .filter(x => x.query)
            .reduce((acc, x) => ({
                ...acc,
                [x.name]: getAppSettingValue(x.name, x.internal, x.default_value, x.is_required, instanceSettings)
            }), {}),
        'app_key': appKey,
        'deviceId': getDeviceInfo()['serial'],
        'app_url': upshowAppsUrl
    };
    return `${url.origin}${url.pathname}?${qs.stringify(queryParams)}`;
};