const userAgent = navigator.userAgent || '';
export const isChromeOS = /\bCrOS\b/.test(userAgent);

export function getPWADisplayMode () {
    if (isChromeOS) {
        return 'ChromeOS PWA';
    }
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    if (navigator.standalone || isStandalone) {
        return 'Desktop';
    }
    return 'WebView';
}
