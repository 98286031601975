import ReactDOM from 'react-dom';
import { isEqual } from 'lodash';

import ContainerService from './ContainerService';
import SettingsService from './SettingsService';
import LayoutQuadrantPlayer from '../layouts/LayoutQuadrantPlayer';
import { QUADRANT_KEYS, LAYOUT_KEYS_MAP, getApplicationFromId, getSpotlightFromId } from '../libs/multiple_layout';
import Logger from '../Logger';

const MultipleLayoutService = {
    content: [],
    layoutInput: {},
    _brandkit: {},
    deadContentIds: [],

    updateSettings () {
        const layoutInput = SettingsService.getJsonUiSetting('layout', {});
        const brandkit = SettingsService.getSetting('brandKit');

        const layoutIsChanged = !isEqual(layoutInput, MultipleLayoutService.layoutInput);
        const brandkitIsChanged = !isEqual(brandkit, MultipleLayoutService._brandkit);

        if (layoutIsChanged) {
            MultipleLayoutService.layoutInput = layoutInput;

            if (!layoutInput.script) {
                MultipleLayoutService.renderLayout();
                return null;
            } else {
                const scriptContent = this.getContent();

                MultipleLayoutService.updateMultipleLayoutContentFromScript(scriptContent)
                    .then(() => MultipleLayoutService.renderLayout());
            }
        }
        if (brandkitIsChanged) {
            MultipleLayoutService._brandkit = brandkit;
            MultipleLayoutService.renderLayout();
        }
    },

    getContent () {
        return MultipleLayoutService.layoutInput.script
            .filter(s => s.fixed && s.content && s.content.type)
            .map(s => ({ id: s.content.id, type: s.content.type}));
    },

    updateMultipleLayoutContentFromScript (scriptContents) {
        return Promise.all(scriptContents.map(async ({ id, type }) => {
            let result;

            switch (type) {
                case 'spotlight':
                    result = await getSpotlightFromId(id);
                    break;
                case 'application':
                    result = await getApplicationFromId(id);
                    break;
                default:
                    Logger.error(`Unsupported content type. [id: ${id} type: ${type}]`);
            }

            return { ...result, content_type: type };
        }))
            .then(newlyArrivedContent => {
                if (!isEqual(newlyArrivedContent, MultipleLayoutService.content)) {
                    MultipleLayoutService.content = newlyArrivedContent.filter(cont => !!cont);
                    MultipleLayoutService.renderLayout();
                }
            });
    },

    updateMultipleLayoutContent () {
        MultipleLayoutService.updateMultipleLayoutContentFromScript(MultipleLayoutService.content
            .map(con => ({ id: con.id, type: con.content_type })));
    },

    renderLayout () {
        const multipleLayoutElement = document.getElementById('multipleLayout-root');

        const rootElement = ContainerService.rootWrapper;

        if (MultipleLayoutService.layoutInput.key === LAYOUT_KEYS_MAP['4_SCREEN']) {
            const script = MultipleLayoutService.layoutInput.script;
            const dynamicQuadrant = script.find(s => !s.fixed);
            const dynamicQuadrantKey = dynamicQuadrant.name;

            if (QUADRANT_KEYS.includes(dynamicQuadrantKey)) {
                rootElement.classList.add(dynamicQuadrantKey);
            }

            if (multipleLayoutElement.childElementCount === 0) {
                for (const quadrantKey of QUADRANT_KEYS.slice(0, 4)) {
                    const quadDiv = document.createElement('div');
                    quadDiv.id = quadrantKey;
                    quadDiv.className = quadrantKey;
                    multipleLayoutElement.appendChild(quadDiv);
                }
            }

            for (const quadrantKey of QUADRANT_KEYS.slice(0, 4)) {
                const renderQuadrant = () => ReactDOM.render(<LayoutQuadrantPlayer
                        quadrantKey={quadrantKey}
                        renderQuadrant={renderQuadrant}
                    />,
                    document.getElementById(quadrantKey));
                renderQuadrant();
            }

        } else {
            QUADRANT_KEYS.forEach(quadrantKey => {
                rootElement.classList.remove(quadrantKey);
            });

            multipleLayoutElement.innerHTML = '';
        }
    },

    init () {
        MultipleLayoutService.updateSettings();
    }
};

export default MultipleLayoutService;