import React from 'react';
import ReactDOM from 'react-dom';
import UPshowState from '../UPshowState';
import StateFactory from '../StateFactory';
import SettingsService from '../../services/SettingsService';
import Logger from '../../Logger';
import iframeWithWhisper from '../iframe/WhisperIframe';
import Iframe from '../iframe/Iframe';
import { getDeviceInfo } from '../../Upshow';
import ScreenService from '../../services/ScreenService';

class TriviaState extends UPshowState {

    preload () {
        performance.mark(`state-preload-${this.name}`);

        return super.preload();
    }

    raiseReady (data) {
        performance.mark(`state-ready-${this.name}`);

        performance.measure('state-ready', `state-preload-${this.name}`, `state-ready-${this.name}`);

        const measures = performance.getEntriesByName('state-ready');
        const measure = measures[0];

        Logger.info(['metric', 'trivia'], 'ready_state', { duration: measure.duration });

        // Clean up the stored markers.
        performance.clearMarks();
        performance.clearMeasures();

        return super.raiseReady(data);
    }

    constructor (node, state) {
        state.gameId = SettingsService.getUiSetting('game_id');

        const baseUrl = typeof SettingsService.getUiSetting('trivia_base_url') !== 'undefined' ? SettingsService.getUiSetting('trivia_base_url') : 'https://upshowgames.com';
        const deviceId = SettingsService.getSetting('device_id');
        const lod = getDeviceInfo().model.startsWith('AF') ? '&lod=1' : '';
        state.triviaUrl = `${baseUrl}/watch${state.gameId}?deviceId=${deviceId}${lod}`;
        super(node, state);
    }

    iframe = null;

    get name () {
        return 'trivia';
    }

    play () {
        this.metricData.start = new Date();
        
        Logger.debug('TriviaState', `Will load url ${this.state.triviaUrl}`);
        try {
            const iframePromise = this.iframe.invoke('play');
            const timeOutPromise = new Promise((r, e) => setTimeout(() => e('timeout'), 5000));
            return Promise.race([iframePromise, timeOutPromise])
                .then((r) => {
                    Logger.debug('TriviaState', `Called play and got ${r}`);

                    Logger.log(['TriviaState', 'play'], `Called play ${this.name} with url ${this.state.triviaUrl}`);

                    return this.raisePlaying();
                })
                .catch((e) => {
                    Logger.error('TriviaState', `errored with ${e}`);
                    this.raiseError(new Error('error trivia play'));
                });

        } catch (e) {
            Logger.error('TriviaState', `Error ${e} calling play`);
            this.raiseError(new Error('error trivia play'));
        }

    }

    _render (resolve) {
        const IframeComponent = iframeWithWhisper(Iframe);

        ReactDOM.render(<IframeComponent
            ref={(el) => this.iframe = el}
            disableWatchdog={false}
            onReady={this.raiseReady}
            onDone={this.raiseDone}
            onError={this.raiseError}
            onPlay={this.raisePlaying}
            src={this.state.triviaUrl}
            gameId={this.state.gameId}
        />, this.node);
    }

    static appliesTo (meta) {
        Logger.debug(['trivia', 'triviastate'], 'Checking if triviastate has a valid configuration, with a game_id setting');
        return SettingsService.hasUiSetting('game_id') && !ScreenService.isVertical;
    }

}

StateFactory.register('trivia', TriviaState, 10);

export default TriviaState;
