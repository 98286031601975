export default function (error) {

    if (typeof error === 'string') {
        return error;
    }

    if (error instanceof Error) {
          // Probably we need more than this
        return JSON.stringify(error, ["message", "arguments", "type", "name"])
    }

    return JSON.stringify(error, Object.getOwnPropertyNames(error))
}