import SettingsService from './SettingsService';
import { getTimeBasedRuleListValidatorFromDateAndTz } from '@upshow/display-rule-utils';

const getRulesValidator = isoDate => {
    const timezone = SettingsService.getTimezone();
    return getTimeBasedRuleListValidatorFromDateAndTz(isoDate, timezone);
};

const DisplayRulesService = {
    getRulesValidator
};

export default DisplayRulesService;