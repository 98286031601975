"use strict";

const Moment = require('moment-timezone');
const ONLY_DATE_FORMAT = 'YYYYMMDD';
const ONLY_TIME_FORMAT = 'HHmm';

/**
 * Creates a boolean function to check a rule and return true if it is expired,
 * defined as: has a date range, and its end date has already passed
 *
 * @param {moment.Moment} currentMoment
 * @returns function
 */
const getDateRangeExpiredCheckerFromMoment = currentMoment => {
  const dateNumber = parseInt(currentMoment.format(ONLY_DATE_FORMAT));
  return rule => {
    if (rule.type === 'date_range') {
      const {
        dateEnd
      } = rule.metadata;
      return !!dateEnd && dateEnd < dateNumber;
    } else {
      return false;
    }
  };
};

/**
 * Creates a boolean function to validate a time based rule at a moment in time
 * which returns true when a rule is valid, false otherwise
 *
 * @param {moment.Moment} currentMoment
 * @returns function
 */
const getTimeBasedRuleValidatorFromMoment = currentMoment => rule => {
  switch (rule.type) {
    case 'date_range':
      {
        const {
          dateEnd,
          dateStart
        } = rule.metadata;
        const currentDate = parseInt(currentMoment.format(ONLY_DATE_FORMAT), 10);
        return (!dateStart || dateStart <= currentDate) && (!dateEnd || currentDate <= dateEnd);
      }
    case 'time_range':
      {
        const {
          timeEnd,
          timeStart
        } = rule.metadata;
        if (!timeEnd && !timeStart) return true; // TODO: why not the same check on date_range for an early return

        const currentTime = parseInt(currentMoment.format(ONLY_TIME_FORMAT), 10);
        if (!timeEnd) return timeStart <= currentTime;
        if (!timeStart) return currentTime <= timeEnd;
        if (timeStart < timeEnd) {
          return timeStart <= currentTime && currentTime <= timeEnd;
        } else {
          return timeStart <= currentTime || currentTime <= timeEnd;
        }
      }
    case 'week_days':
      {
        const {
          days
        } = rule.metadata;
        const currentDayName = currentMoment.format('dddd').toLowerCase();
        return days.includes(currentDayName);
      }
    default:
      // TODO: what is the desired behavior here
      throw new Error('NOT IMPLEMENTED');
  }
};

/**
 * Creates a boolean function to validate a time based rule at a given datetime
 * and timezone, which returns true when a rule is valid, false otherwise
 *
 * @param {date|String} currentDate
 * @param {String} tz
 * @returns function
 */
const getTimeBasedRuleValidatorFromDateAndTz = (currentDate, tz) => {
  const currentMoment = Moment(currentDate).tz(tz);
  return getTimeBasedRuleValidatorFromMoment(currentMoment);
};

/**
 * Creates a boolean function to validate a list of time based rules at  given moment
 * returning true when all rules are valid, false otherwise
 *
 * @param {moment.Moment} currentMoment
 * @returns function
 */
const getTimeBasedRuleListValidatorFromMoment = currentMoment => {
  const timeBasedRuleValidator = getTimeBasedRuleValidatorFromMoment(currentMoment);
  return rules => rules.every(timeBasedRuleValidator);
};

/**
 * Creates a boolean function to validate a list of time based rules at given datetime
 * and timezone, which returns true when all rules are valid, false otherwise
 *
 * @param {date|String} currentDate
 * @param {String} tz
 * @returns function
 */
const getTimeBasedRuleListValidatorFromDateAndTz = (currentDate, tz) => {
  const currentMoment = Moment(currentDate).tz(tz);
  return getTimeBasedRuleListValidatorFromMoment(currentMoment);
};

/**
 * Creates a boolean function to validate if the schedule intersects with the Time Based Display Rules (TBDRs).
 * Returns true if schedule overlaps with TBDRs.
 * All TBDRs have to be checked to create a full set of possible overlaps.
 * Supports tz
 *
 * @param {object} schedule
 * @param {[Object]} timeBasedDisplayRules
 * @param {String} orgTz
 * @returns Boolean
 */
function scheduleSetIntersectsWithDisplayRules(schedule, timeBasedDisplayRules, orgTz) {
  return timeBasedDisplayRules.every(timeBasedDisplayRule => momentRangeIntersectsWithDisplayRule(schedule, timeBasedDisplayRule, orgTz));
}

/**
 *
 * Will validates that there is week days intersection within a date range considering timezones.
 *
 * @param {object} schedule
 * @param {object} dateRangeDisplayRule
 * @param {String} orgTz
 * @returns Boolean
 */
function isDaysIntersection(schedule, dateRangeDisplayRule, orgTz) {
  const {
    validFrom,
    validThrough,
    validFromTime,
    validThroughTime,
    tz
  } = schedule;
  const dateStartMoment = Moment.tz(dateRangeDisplayRule.metadata.dateStart, 'YYYYMMDD', orgTz);
  const dateEndMoment = Moment.tz(dateRangeDisplayRule.metadata.dateEnd + ' ' + '23:59', 'YYYYMMDD HH:mm', orgTz);
  let intersectionStart = Moment.max(dateStartMoment, convertDateTimeTimezone(validFrom, validFromTime, tz, orgTz));
  const intersectionEnd = Moment.min(dateEndMoment, convertDateTimeTimezone(validThrough, validThroughTime, tz, orgTz));
  if (intersectionStart > intersectionEnd) return true;
  while (intersectionStart <= intersectionEnd) {
    const dayOfTheWeek = intersectionStart.format('dddd').toLowerCase() + 's';
    if (schedule[dayOfTheWeek]) return true;
    intersectionStart = intersectionStart.add(1, 'days');
  }
  return false;
}

/**
 * Creates a boolean function to validate a time based rule (with tz support)
 * which returns true when the rule type match the schedule (with tz support), false otherwise
 *
 * @param {object} timeBasedDisplayRule
 * @param {object} schedule
 * @param {string} orgTz
 * @returns Boolean
 */
function momentRangeIntersectsWithDisplayRule(schedule, timeBasedDisplayRule, orgTz) {
  const {
    validFromTime,
    validThroughTime,
    validFrom,
    validThrough,
    tz
  } = schedule;
  switch (timeBasedDisplayRule.type) {
    case 'date_range':
      {
        const {
          dateStart,
          dateEnd
        } = timeBasedDisplayRule.metadata;
        const schStart = convertDateTimeTimezone(validFrom, validFromTime, tz, orgTz);
        const schFinish = convertDateTimeTimezone(validThrough, validThroughTime, tz, orgTz);
        const lowerComply = schFinish.isSameOrAfter(Moment(dateStart, ONLY_DATE_FORMAT), 'day');
        const upperComply = schStart.isSameOrBefore(Moment(dateEnd, ONLY_DATE_FORMAT), 'day');
        const checkDays = isDaysIntersection(schedule, timeBasedDisplayRule, orgTz);
        return lowerComply && upperComply && checkDays;
      }
    case 'time_range':
      {
        const {
          timeStart: drStart,
          timeEnd: drEnd
        } = timeBasedDisplayRule.metadata;
        if (drStart === undefined || drEnd === undefined) return true;
        const schStart = convertHourTimezone(validFromTime, tz, orgTz);
        const schEnd = convertHourTimezone(validThroughTime, tz, orgTz);
        const schFinishWithSafeguard = schEnd + (schStart >= schEnd ? 2400 : 0);
        const drEndWithSafeguard = drEnd + (drStart >= drEnd ? 2400 : 0);
        const lowerComply = drEndWithSafeguard > schStart;
        const upperComply = drStart < schFinishWithSafeguard;
        return lowerComply && upperComply;
      }
    case 'week_days':
      {
        return timeBasedDisplayRule.metadata.days.some(day => schedule[day + 's']);
      }
    default:
      throw new Error(`Rule type ${timeBasedDisplayRule.type} not supported.`);
  }
}

/**
 * Creates an int function to return int representation of current date in HHmm format.
 * It receives a tz from the schedule and initializes the time on that tz (if any) and changes it to orgTz (if any)
 *
 * @param {string} hmString
 * @param {string} tz
 * @param {string} orgTz
 * @returns Number
 */
function convertHourTimezone(hmString, tz, orgTz) {
  const time = Moment.tz(hmString.substring(0, 2) + '' + hmString.substring(3, 5), 'HHmm', tz);
  if (orgTz) time.tz(orgTz);
  return parseInt(time.format('HHmm'));
}

/**
 * Creates a function to return a Moment instance.
 * It receives a tz from the schedule and initializes the date with time on that tz (if any) and changes it to orgTz (if any)
 *
 * @param {string} date
 * @param {string} time
 * @param {string} tz
 * @param {string} orgTz
 * @returns Object
 */
function convertDateTimeTimezone(date, time, tz, orgTz) {
  const dt = Moment.tz(date + ' ' + time, 'YYYY-MM-DD HH:mm:ss', tz);
  if (orgTz) dt.tz(orgTz);
  return dt;
}
module.exports = {
  getDateRangeExpiredCheckerFromMoment,
  getTimeBasedRuleValidatorFromMoment,
  getTimeBasedRuleValidatorFromDateAndTz,
  getTimeBasedRuleListValidatorFromMoment,
  getTimeBasedRuleListValidatorFromDateAndTz,
  scheduleSetIntersectsWithDisplayRules
};