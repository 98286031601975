import SpotlightService from './SpotlightService';

class ScreenService {

    constructor (screenWidth, screenHeight) {
        this._screenWidth = screenWidth;
        this._screenHeight = screenHeight;
    }

    setScreenDimensions (width, height) {
        const wasVertical = this.isVertical;

        this._screenWidth = width;
        this._screenHeight = height;

        // What to do when the screen orientation changes (this should not happen on _normal_ devices)
        if (wasVertical !== this.isVertical) {
            SpotlightService.invalidOrientationSpotlights = [];
        }
    }

    get screenWidth () {
        return this._screenWidth;
    }

    get screenHeight () {
        return this._screenHeight;
    }

    get isVertical () {
        return this.screenWidth < this.screenHeight;
    }

    get screenRatio () {
        return this.screenWidth / this.screenHeight;
    }

    get uiWidth () {
        return this.isVertical ? 1080 : 1920;
    }

    get uiHeight () {
        return this.isVertical ? 1920 : 1080;
    }

    get uiRatio () {
        return this.uiWidth / this.uiHeight;
    }

}

export default new ScreenService(1920, 1080);