import React from "react";
import { Textfit } from "react-textfit";
import SettingsService from '../../../../services/SettingsService';
import HwFeatureService from "../../../../services/HwFeatureService";
import { gsap } from '@upshow/gsap';
import { getSocialBrandKitStyle } from "../../../../libs/helpers";
import ScreenService from "../../../../services/ScreenService";

const slimCtaClassName = "slimCta";
const longCtaClassName = "longCta";

class SlimAnimatedGridCta extends React.Component {

    getCustomClassName() {
        const slimCta = SettingsService.getCta().slim_cta;
        const longCta = SettingsService.getCta().long_cta;

        if (slimCta) {
            return slimCtaClassName;
        } else if (longCta) {
            return longCtaClassName;
        } else {
            return '';
        }

    }

    slidingTwistAnimation() {
        const initialTime = 3;
        const headerDelay = 6;
        const animationTime = 3;

        if (!this.HeaderText || !this.IconIG || !this.Hashtags) {
            return;
        }

        const tl = gsap.timeline({ paused: true });

        tl.add("initialTime", initialTime)
            .add("headerDelay", headerDelay)
            .fromTo(this.IconIG, animationTime, { x: '-900px' }, { x: '0px', ease: "linear" }, "initialTime")
            .fromTo(this.Hashtags, animationTime, { x: '900px' }, { x: '0px', ease: "linear" }, "initialTime")
            .fromTo(this.HeaderText, animationTime, { y: '-900px', rotation: 180 }, { y: '0px', ease: "bounce.out" }, "initialTime")
            .to(this.HeaderText, animationTime, { rotation: "+=180", ease: "elastic.out(1, 0.3)" }, "headerDelay");

        if (this.Bar) {
            tl.fromTo(this.Bar, animationTime, { opacity: 0 }, { opacity: 1, ease: "linear" }, "initialTime");
        }

        try {
            tl.play();
        } catch (error) {
            console.error(error);
        }
    }

    dropAndTwistAnimation() {
        const initialTime = 3;
        const headerDelay = 6;
        const animationTime = 3;

        if (!this.CTA) {
            return;
        }
    
        const tl = gsap.timeline({ paused: true });
    
        tl.addLabel("initialTime", initialTime)
          .addLabel("headerDelay", headerDelay)
          .set(this.CTA, { rotationY: "+=180" })
          .fromTo(this.CTA, { y: '-1000px' }, { duration: animationTime, y: '0px', ease: "elastic.out(1, 0.3)" }, "initialTime")
          .to(this.CTA, { duration: animationTime, rotationY: "+=180", ease: "elastic.out(1, 0.3)" }, "headerDelay");
    
        try {
            tl.play();
        } catch (error) {
            console.error(error);
        }
    }

    slidingAnimation() {
        const initialTime = 3;
        const headerDelay = 6;
        const animationTime = 3;

        if (!this.HeaderText || !this.IconIG || !this.Hashtags) {
            return;
        }
    
        const tl = gsap.timeline({ paused: true });
    
        tl.addLabel("initialTime", initialTime)
          .addLabel("headerDelay", headerDelay)
          .fromTo(this.IconIG, { x: '-900px' }, { duration: animationTime, x: '0px', ease: "none" }, "initialTime")
          .fromTo(this.Hashtags, { x: '900px' }, { duration: animationTime, x: '0px', ease: "none" }, "initialTime")
          .fromTo(this.HeaderText, { y: '-900px' }, { duration: animationTime - 1, y: '0px', ease: "bounce.out" }, "initialTime");
    
        if (this.Bar) {
            tl.fromTo(this.Bar, { opacity: 0 }, { duration: animationTime, opacity: 1, ease: "none" }, "initialTime");
        }
    
        try {
            tl.play();
        } catch (error) {
            console.error(error);
        }
    }

    slideLeftAnimation() {
        const initialTime = 0;
        const animationTime = 2;
    
        if (!this.CTA) {
            return;
        }
    
        const tl = gsap.timeline({ paused: true });
    
        tl.addLabel("initialTime", initialTime)
          .addLabel("initialTimeDelay", initialTime + 2)
          .set(this.CTA, { x: '-900px' }, "initialTime")
          .to(this.CTA, { duration: animationTime, x: '0px', ease: "power1.out" }, "initialTimeDelay");
    
        try {
            tl.play();
        } catch (error) {
            console.error(error);
        }
    }

    slideRightAnimation() {
        const initialTime = 0;
        const animationTime = 2;
    
        if (!this.CTA) {
            return;
        }
    
        const tl = gsap.timeline({ paused: true });
    
        tl.addLabel("initialTime", initialTime)
          .addLabel("initialTimeDelay", initialTime + 2)
          .set(this.CTA, { x: '900px' }, "initialTime")
          .to(this.CTA, { duration: animationTime, x: '0px', ease: "power1.out" }, "initialTimeDelay");
    
        try {
            tl.play();
        } catch (error) {
            console.error(error);
        }
    }

    bounceAppearAnimation() {
        const initialTime = 0;
        const animationTime = 4;
    
        if (!this.CTA) {
            return;
        }
    
        const tl = gsap.timeline({ paused: true });
    
        tl.addLabel("initialTime", initialTime)
          .addLabel("initialTimeDelay", initialTime + 2)
          .set(this.CTA, { scale: 0, opacity: 0 }, "initialTime")
          .to(this.CTA, { duration: animationTime, scale: 1, ease: "elastic.out(1, 0.3)" }, "initialTimeDelay")
          .to(this.CTA, { duration: animationTime - 2, opacity: 1, ease: "none" }, "initialTimeDelay");
    
        try {
            tl.play();
        } catch (error) {
            console.error(error);
        }
    }

    getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max));
    }


    randomAnimation() {
        const animations = [
            this.slidingAnimation.bind(this),
            this.slidingTwistAnimation.bind(this),
            this.bounceAppearAnimation.bind(this),
            this.slideRightAnimation.bind(this),
            this.slideLeftAnimation.bind(this),
            this.dropAndTwistAnimation.bind(this)
        ];
        let randomInt = this.getRandomInt(animations.length);
        animations[randomInt]();

    }

    componentDidMount() {
        if (HwFeatureService.isSupported('cta-animations')
            && SettingsService.getCta().animated_cta
            && !this.props.relaxed) {
            this.randomAnimation();
        }
    }

    renderGridCta() {
        const { type, className, headerText, hashtags } = this.props;
        const { backgroundColor, color, logo } = getSocialBrandKitStyle();
        const showOnLeftSide = ScreenService.isVertical || SettingsService.getCta().long_cta;

        return (<div ref={ref => this.CTA = ref} className={`DefaultTextCta ${className} ${type} ${this.getCustomClassName()}`} style={{ backgroundColor, color }}>
            <div className="header" ref={ref => this.HeaderText = ref} style={{ borderBottom: `3px solid ${color}` }} >
                {logo && <div className="social-logo-container">
                    <img src={logo} className="social-cta-logo" />
                </div>}
                <div className="cta-container">
                    <Textfit
                        className={'CtaText'}
                        max={148}>
                        {headerText}
                    </Textfit>
                </div>
            </div>
            <div className="footer" >
                <div className="top">
                    <span>Post on <span ref={ref => this.IconIG = ref} className="icon network-ig" /> with</span>
                </div>
                <div className="bottom" ref={ref => this.Hashtags = ref} style={{ borderLeft: showOnLeftSide ? `3px solid ${color}` : '' }} >
                    <Textfit
                        className="CtaHashtags"
                        max={148}>
                        <span className="hashtags">
                            {hashtags.map((hashtag, i) => <div key={i} >#{hashtag}</div>)}
                        </span>
                    </Textfit>
                </div>
            </div>
        </div>)

    }

    render() {
        return this.renderGridCta();
    }
}


export default SlimAnimatedGridCta;