const getCanvas = () => {
    const canvas = document.createElement('canvas');
    canvas.id = 'vu-meter';
    canvas.width = '150';
    canvas.height = '300';
    return canvas;
};

const setCanvasContext = (canvas, average) => {
    const canvasContext = canvas.getContext('2d');
    canvasContext.clearRect(0, 0, 150, 300);
    canvasContext.fillStyle = '#BadA55';
    canvasContext.fillRect(0, 300 - average, 150, 300);
    canvasContext.fillStyle = '#262626';
    canvasContext.font = '48px impact';
    canvasContext.fillText(Math.round(average - 40), -2, 300);
};

const vu_meter = (enable, mediaAudio, audioContext) => {
    if (!enable) {
        document.getElementById('vu-meter')?.remove();
    } else {
        console.log('vu-meter enabled');
        const canvas = getCanvas();

        document.body.appendChild(canvas);

        const analyser = audioContext.createAnalyser();
        const javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

        analyser.smoothingTimeConstant = 0.8;
        analyser.fftSize = 1024;

        mediaAudio.connect(analyser);
        analyser.connect(javascriptNode);
        javascriptNode.connect(audioContext.destination);

        javascriptNode.onaudioprocess = function () {
            const array = new Uint8Array(analyser.frequencyBinCount);
            analyser.getByteFrequencyData(array);
            let values = 0;

            const length = array.length;
            for (let i = 0; i < length; i++) {
                values += (array[i]);
            }

            const average = values / length;
            setCanvasContext(canvas, average);
        };
    }
};

export default vu_meter;