"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getHighestValueKey = exports.evenlyDistributeCategories = exports.calculatePercentages = exports.calculateCategoryAppearances = exports.buildScriptFromSequence = void 0;
var _lodash = require("lodash");
// Custom data of each state
const states = {
  qr_campaign: {
    state: 'spotlight',
    type: 'qr_code',
    time: 15
  },
  spotlight: {
    type: 'announcement',
    time: 15
  },
  jukin: {
    time: 180
  },
  plutotv: {
    time: 600
  },
  application: {
    time: 30,
    type: 'plugin' // In this case we want only plugins not Sportsbook
  },
  socialgrid: {
    time: 10
  },
  upshownow: {
    time: 120
  },
  socialzoom: {
    time: 10
  },
  trivia: {
    time: 600
  },
  sportsbook: {
    state: 'application',
    time: 30,
    type: 'sportsbook' // In this case we want only Sportsbook
  }
};

// Takes a sequence of state names and return a valid ui_script sequence.
const buildScriptFromSequence = (sequence, categoryStates) => {
  const script = [];
  const lastUsedSubCategoryIndex = {}; // To alternate categoryStates with more than 1 state

  sequence.forEach((category, index) => {
    if (categoryStates[category].length > 0) {
      // By default the first state
      let state = categoryStates[category][0];

      // If the category has more than 1 state we shuffle them
      if (categoryStates[category].length > 1) {
        if (lastUsedSubCategoryIndex[category] === undefined) {
          lastUsedSubCategoryIndex[category] = -1;
        }

        // This will iterate over all the states inside a category
        const nextIndex = (lastUsedSubCategoryIndex[category] + 1) % categoryStates[category].length;
        lastUsedSubCategoryIndex[category] = nextIndex;
        state = categoryStates[category][nextIndex];
      }

      // Get the state Info to add in the script
      const stateInfo = states[state];
      script.push({
        state: state,
        type: null,
        i: index,
        ...stateInfo
      });
    }
  });
  return script;
};

// Returns the amount of appearances that each category needs to accomplish the percentages
exports.buildScriptFromSequence = buildScriptFromSequence;
const calculateCategoryAppearances = ({
  categoryDesiredPercentage,
  timeWindow,
  categoryAverageDuration
}) => {
  const categoryAppearances = {};
  for (const [category, percentage] of Object.entries(categoryDesiredPercentage)) {
    const duration = categoryAverageDuration[category];
    const avgAppearances = percentage / 100 * timeWindow / duration;
    const appearances = avgAppearances > 0 && avgAppearances < 1 ? 1 : Math.round(avgAppearances);
    categoryAppearances[category] = appearances;
  }
  return categoryAppearances;
};
exports.calculateCategoryAppearances = calculateCategoryAppearances;
const evenlyDistributeCategories = appearances => {
  // Converts number of appearances in array of items
  const categorySequences = appearancesToItems(appearances);

  // This is going to merge all the items by pairs
  while (categorySequences.length > 1) {
    const pair = categorySequences.splice(-2);
    categorySequences.push(evenMerge(pair[0], pair[1]));
  }
  return (0, _lodash.flatten)(categorySequences);
};

// Take the number of appearances and return arrays filled with the category name.
// The category name will appear the amount of appearances required.
exports.evenlyDistributeCategories = evenlyDistributeCategories;
const appearancesToItems = appearances => {
  const orderedCats = Object.keys(appearances)
  // Removes the categories with no appearances
  .filter(cat => appearances[cat] > 0)
  // Order the categories by amount of appearances
  .sort((a, b) => appearances[a] - appearances[b]);
  // Fill the array with category name
  return orderedCats.map(cat => Array(appearances[cat]).fill(cat));
};

// This merge two arrays into one
const evenMerge = (a, b) => {
  const ratio = b.length / a.length;
  const mergedArray = [];
  while (a.length) {
    mergedArray.push(a.pop());
    // This gives a spice off randomness to the position merged
    mergedArray.push(...b.splice(-1 * randomIntFromInterval(Math.floor(ratio), Math.ceil(ratio))));
  }
  mergedArray.push(...b);
  return mergedArray;
};

// Returns random number between min and max 
const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

/**
 * Receives an object with categories values.
 * Determines the category key with the highest value.
 *
 * @param {Object.<string, number>} obj - An object with categories and their values.
 * @returns {number} - The key of the highest value.
 */
const getHighestValueKey = obj => {
  let highest = null;
  for (const key in obj) {
    const value = obj[key];
    if (highest === null || value > obj[highest]) {
      highest = key;
    }
  }
  return highest;
};

/**
 * Receives an object with categories values and a total.
 * Creates an object with categories, with their respective percentage over the total param.
 *
 * @param {Object.<string, number>} obj - An object with categories and their values.
 * @param {number} total - The total value for calculating percentages.
 * @returns {Object} - An object with categories and their respective percentages.
 */
exports.getHighestValueKey = getHighestValueKey;
const calculatePercentages = (obj, total) => {
  const percentages = {};
  for (const cat in obj) {
    const percentage = obj[cat] / total * 100;
    percentages[cat] = percentage > 0 && percentage < 1 ? 1 : Math.round(percentage);
  }
  const totalPercentageSum = Object.values(percentages).reduce((sum, percentage) => sum + percentage, 0);
  if (totalPercentageSum !== 100) {
    const highestPercentage = getHighestValueKey(percentages);
    percentages[highestPercentage] += 100 - totalPercentageSum;
  }
  return percentages;
};
exports.calculatePercentages = calculatePercentages;