import UpshowLogger from '../Logger';
import * as UP from '../Upshow';
import _find from "lodash/find";
import MediaService from "./MediaService";
import SettingsService from './SettingsService';

const PlutoTVService = {
    getPlutoTVChannel: async (id) => {
        let channel;

        if (SettingsService.hasFeature('dr_time_based')) {
            channel = MediaService.getValidMedia('plutotv').find(plutoTvChannel => plutoTvChannel.id === id);
        } else {
            const scheduledChannel = _find(MediaService.getActiveMedia(['plutotv']),
                sch => sch?.channel?.id === id) ?? {} ;

            channel = scheduledChannel.channel;
        }

        if(!!channel){
            return channel;
        }

        const response = await UP.getPlutoChannel(id);
        const {server_error, error} = response;
        if(server_error){
            UpshowLogger.error(['PlutoTVService','getPlutoTVChannel'], server_error);
        } else if (error) {
            UpshowLogger.warn(['PlutoTVService','getPlutoTVChannel'], error);
        }
        return response.pluto_channel;
    },
}

export default PlutoTVService;

