import React from 'react';
import ReactDOM from 'react-dom';
import UPshowState from '../UPshowState';
import StateFactory from '../StateFactory';
import Loading from './Loading';

class LoadingState extends UPshowState {

  get name() {
    return 'loading';
  }


  preload() {

    ReactDOM.render(<Loading />, this.node);

    return super.preload();

  }

}

StateFactory.register('loading', LoadingState, 10);

export default LoadingState;
