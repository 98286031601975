"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.basicIsValidUrl = basicIsValidUrl;
exports.displayRulesMeetTags = displayRulesMeetTags;
exports.isValidEmail = isValidEmail;
exports.isValidFormField = isValidFormField;
exports.isValidSfId = isValidSfId;
exports.isValidTelephoneNumber = isValidTelephoneNumber;
exports.isValidTitle = isValidTitle;
exports.isValidUrl = isValidUrl;
exports.isValidZipcode = isValidZipcode;
exports.validateSpotlightBackground = validateSpotlightBackground;
exports.validateSpotlightDuration = validateSpotlightDuration;
exports.validateSpotlightForPlaying = validateSpotlightForPlaying;
function validateSpotlightBackground({
  background
}) {
  const isImageOrVideo = ['image', 'video'].includes(background?.type);
  return !isImageOrVideo || !!background.id;
}
function validateSpotlightDuration({
  background,
  duration
}) {
  if (isNaN(duration)) return false;
  if (!background || !background.id) return duration > 0;
  const {
    type: backgroundType
  } = background;
  if (['image', 'solid', 'gradient'].includes(backgroundType)) {
    return duration > 0;
  }
  return true;
}
function validateSpotlightForPlaying(spotlight) {
  return validateSpotlightDuration(spotlight) && validateSpotlightBackground(spotlight);
}
function isValidEmail(email) {
  const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/;
  return !!email && email.length !== 0 && regex.test(email);
}
function basicIsValidUrl(url) {
  try {
    /* eslint-disable no-new */
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
}
function isValidUrl(url) {
  const regex = /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2})+(:([\d\w]|%[a-fA-f\d]{2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2})*)?/;
  const noSpaceRegex = /^[\S]+$/;
  return !!url && url.length !== 0 && regex.test(url) && noSpaceRegex.test(url);
}
function isValidTelephoneNumber(number) {
  const regex = /^[+]?[(]?[0-9]{3}[)]?[-.]?[0-9]{0,3}[-.]?[0-9]{0,6}$/im;
  return !!number && number.length !== 0 && regex.test(number);
}
function isValidZipcode(zipcode) {
  const regex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
  if (!regex.test(zipcode)) return false;
  return zipcode.slice(0, 5) > 0 && zipcode.slice(0, 5) <= 99950;
}
function displayRulesMeetTags(displayRules, orgTags) {
  if (!displayRules || displayRules.length === 0 || !orgTags) return true;
  return displayRules.slice(0).reduce((acc, displayRule, i, arr) => {
    if (displayRule.type !== 'tag') return acc;
    const tagFound = !!orgTags.find(tag => tag === displayRule.value);
    if (displayRule.filter === 'exclude' && !tagFound) return acc;
    if (displayRule.filter === 'include' && tagFound) return acc;
    arr.splice(1);
    return false;
  }, true);
}
function isValidFormField(field, value) {
  switch (field) {
    case 'email':
      return isValidEmail(value);
    case 'zipcode':
      return isValidZipcode(value);
    default:
      return true;
  }
}
function isValidTitle(title) {
  return !!title && /[^\s]/.test(title);
}

/**
 * Validates a Salesforce Id
 * https://stackoverflow.com/a/29299786/1333724
 * @param {string} str
 * @return {boolean}
 */
function isValidSfId(str) {
  if (typeof str !== 'string' || str.length !== 18) {
    return false;
  }
  // Basic validation
  if (!/^[a-zA-Z0-9]+$/.test(str)) return false;

  // For 18 characters ids we have to do a proper checksum check,
  const upperCaseToBit = char => char.match(/[A-Z]/) ? '1' : '0';
  const binaryToSymbol = digit => digit <= 25 ? String.fromCharCode(digit + 65) : String.fromCharCode(digit - 26 + 48);
  const parts = [str.slice(0, 5).split('').reverse().map(upperCaseToBit).join(''), str.slice(5, 10).split('').reverse().map(upperCaseToBit).join(''), str.slice(10, 15).split('').reverse().map(upperCaseToBit).join('')];
  const check = parts.map(str => binaryToSymbol(parseInt(str, 2))).join('');
  return check === str.slice(-3);
}