import { getCurrentTimestamp } from '../Upshow';
import UpshowLogger from '../Logger';

class WatchDog {
    constructor () {
        this.lastPlayingTime = 0;
        this.lastPlayedSeconds = 0;
        this.playErrors = -1;

    }

    watchdogOK = (playing, playStart, playedSeconds, type, mediaURL) => {
        if (playing) {
            let currentTime = getCurrentTimestamp();

            let playingTime = (currentTime - playStart) / 1000;
            if (playingTime > 3) {

                let elapsed = playingTime - this.lastPlayingTime;
                let advanced = playedSeconds - this.lastPlayedSeconds;
                if (advanced < elapsed * 0.9) {
                    this.playErrors++;
                } else if (this.playErrors > 0) {
                    this.playErrors--;
                }

                const debugInfo = JSON.stringify({ playingTime, playedSeconds, advanced, elapsed, type, mediaURL, errors: this.playErrors });

                if (this.playErrors > 7 || isNaN(playedSeconds)) {
                    UpshowLogger.error(['watchdog', 'error'], debugInfo);
                    return false;
                } else if (this.playErrors > 0) {
                    UpshowLogger.debug(['watchdog', 'warning'], debugInfo);
                }

                this.lastPlayingTime = playingTime;
                this.lastPlayedSeconds = playedSeconds;

            }
        }
        return true;
    };

}

export default WatchDog;
