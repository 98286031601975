import UpshowLogger from '../Logger';
import _ from 'lodash';
import 'moment-timezone';
import SettingsService from '../services/SettingsService';
import { getCurrentDate } from '../Upshow';
import StateService from './StateService';

export function formatContent (res) {
    return res.reduce(function (sum, post) {
        post.url = post.url.replace(/^http:\/\//i, 'https://');
        if (post.type !== 2) {
            post.thumbnail = post.url;
        } else {
            post.thumbnail = post.thumbnail.replace(/^http:\/\//i, 'https://');
        }

        post.gridThumbnail = post.thumbnail;

        if (post.type === 2) {
            post.zoomURL = post.thumbnail;
        } else {
            post.zoomURL = post.url;
        }

        post.description = post.description.replace(/[^\s]#/g, ' #');

        sum.push(post);
        return sum;
    }, []);
}

const UpNextService = {
    upNext: [],
    content: [],

    removeCurrent: () => {
        if (UpNextService.current) {
            UpshowLogger.info('up_next', 'removing content: ' + UpNextService.current.id);

            UpNextService.upNext.splice(_.findIndex(UpNextService.upNext, post => post.id === UpNextService.current.id), 1);

            if (UpNextService.upNext.length <= 3) {
                UpNextService.upNext = _.concat(UpNextService.upNext, UpNextService.content);
            }
        }
    },

    pop: () => {
        let post = UpNextService.getNext();
        UpNextService.removeCurrent();
        return post;
    },

    getNext: () => {
        UpNextService.setNext();
        return UpNextService.current;
    },

    setNext: () => {
        if (UpNextService.upNext.length > 0) {
            UpNextService.current = UpNextService.upNext[0];
            UpshowLogger.info('up_next', 'up next is: ' + UpNextService.current.id);
        }
    },

    updateContent: (content, added, remaining, removed) => {
        UpNextService.content = [...added, ...remaining]; //This makes sure that newer content is added first to the queue

        const random_start = SettingsService.hasTrueUiSetting('random_start_script');

        // If random start is enabled, randomize start of the posts list
        const first_post_index = random_start && content.length > 1 ? Math.floor(Math.random() * content.length - 1) : 0;

        let gotNew = false;
        if (UpNextService.upNext.length === 0) {
            UpshowLogger.log('up_next', 'Setting up up_next for first time');
            UpNextService.upNext = content.slice(first_post_index);
        } else {
            // Remove removedContent
            UpNextService.upNext = UpNextService.upNext.filter(function (post) {
                const found = _.find(removed, ['id', post.id]);
                if (found) {
                    UpshowLogger.info('up_next', 'Removing content from up netxt: ' + post.id.toString());
                }
                return !found;
            });

            // From right to left, add double dip and new content or add to end
            _.eachRight(added, function (post) {
                if ((getCurrentDate() - (new Date(post.createdAt))) > (6 * 60 * 60 * 1000)) {
                    UpshowLogger.info('up_next', 'Adding to the bottom: ' + post.id.toString());
                    UpNextService.upNext.push(post);
                } else {
                    gotNew = true;
                    if (!SettingsService.hasTrueUiSetting('disable_ig')) {
                        UpshowLogger.info('up_next', 'Adding double dip: ' + post.id.toString());
                        const doubleDipPost = Object.assign({}, post);
                        UpNextService.upNext.splice(2, 0, doubleDipPost);
                    }

                    const igPost = Object.assign({}, post,
                        {
                            new_content: true,
                            got_content_at: Date.now()
                        });

                    UpshowLogger.info('up_next', 'Adding content as first in up next: ' + post.id.toString());
                    UpNextService.upNext.unshift(igPost);
                }
            });
        }

        if (gotNew && !StateService.disableig && StateService.currentState.allowsIg) {
            StateService.queueState({ name: 'socialzoom', duration: 5 });
            StateService.advanceState();
        }
    },
    /**
     * Main entry point to update new content into the service
     * @param content
     */
    setContent: (content) => {
        if (!content || content.length === 0) {
            UpNextService.content = [];
            UpNextService.upNext = [];
            return;
        }

        const formatted_content = formatContent(content);

        const removed = [];
        const remaining = [];

        //Remove from the content stuff that is not on the content array anymore
        //This could be due to banned users, removed content, old content, etc
        UpNextService.content.forEach((oldPost) => {
            const matchingPost = formatted_content.find(p => p.id === oldPost.id);
            if (!matchingPost) {
                UpshowLogger.log(['content'], 'Removing content: ' + oldPost.id);
                removed.push(oldPost);
            } else {
                remaining.push(matchingPost);
            }
        });

        const added = _.differenceWith(formatted_content, remaining, (post1, post2) => post1.id === post2.id);

        UpshowLogger.debug(['up_next', 'newcontent', 'parsenewcontent'], 'Parse new content :  added: ' + added.length + ', remaining: ' + remaining.length + ' removed: ' + removed.length);

        // Update service with new content provided
        UpNextService.updateContent(formatted_content, added, remaining, removed);
    }
};

export default UpNextService;

