import React from 'react';
import ReactDOM from 'react-dom';
import SocialZoom from './SocialZoom';
import UpshowLogger from '../../Logger';
import MediaPlayingState from '../MediaPlayingState';
import StateFactory from '../StateFactory';
import TrackerService from '../../services/TrackerService';
import { getCurrentDate, canReportTts } from '../../Upshow';
import SettingsService from '../../services/SettingsService';
import { nullOrTrue } from '../../libs/helpers';
import { UpshowContextProvider } from '../../UpshowContext';
class SocialZoomState extends MediaPlayingState {

    constructor (node, state) {
        const post = state.state.currentPost ?? {};
        const isVideo = post.type === 2;
        super(
            node,
            state,
            post.url,
            isVideo,
            false,
            {
                uid: post.id,
                type: isVideo ? 'video' : 'image',
                track: false
            }
        );

        if (!post.id) this.fullScreenCta = true;
        
        this.state.muted = nullOrTrue(!post.audio) || SettingsService.hasTrueUiSetting('muted');
        if (!this.state.muted) {
            this.state.soundState.shouldSound = true;
        }
        this.metricData = { ...this.metricData, id: post.id };
        this.state.post = post;
    }

    get name () {
        return 'socialzoom';
    }

    _render (resolve) {
        ReactDOM.render(<UpshowContextProvider>
            <SocialZoom
                post={this.state.post}
                ref={el => this.state.component = el}
                playing={this.state.playing}
                duration={this.state.duration}
                onPlay={this.raisePlaying.bind(this)}
                onReady={this.raiseReady.bind(this)}
                onEnded={this.raiseDone.bind(this)}
                onError={this.raiseError.bind(this)}
                onPause={this.raisePaused.bind(this)}
                onProgress={this.onProgress.bind(this)}
                onDuration={this.onDuration.bind(this)}
                zoomLogo={this.state.settings.zoom_logo}
                muted={this.state.muted}
                fullScreenCta={this.fullScreenCta}
            />
        </UpshowContextProvider>, this.node);
    }

    play () {
        let post = this.state.post;
        // Update TTS
        if (canReportTts() && post.new_content) {
            try {
                const posted = new Date(post.postedAt);
                const collected = new Date(post.createdAt);
                const device = new Date(post.got_content_at);
                const screen = getCurrentDate();

                const metrics = {
                    name: 'tts',
                    content_id: post.id,
                    posted_date: posted.toISOString(),
                    collected_date: collected.toISOString(),
                    device_date: device.toISOString(),
                    screen_date: screen.toISOString(),
                    network: post.network
                };

                metrics.ptd = device.getTime() - posted.getTime();
                metrics.pts = screen.getTime() - posted.getTime();

                metrics.ctd = device.getTime() - collected.getTime();
                metrics.cts = screen.getTime() - collected.getTime();

                metrics.dts = screen.getTime() - device.getTime();

                TrackerService.trackEvent(post.id, metrics);
            } catch (err) {
                UpshowLogger.error(['TTS', 'SocialZoom'], err);
            }

            post.new_content = false;
        }
        return super.play();
    }

}

StateFactory.register('socialzoom', SocialZoomState, 10);

export default SocialZoomState;
