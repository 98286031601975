import React from 'react';
import ReactDOM from 'react-dom';
import _get from 'lodash/get';
import PlutoTVPlayer from './PlutoTVPlayer';
import StateFactory from '../StateFactory';
import UPshowState from '../UPshowState';
import ScreenService from '../../services/ScreenService';
import { StatePreconditionError } from '../Errors';
import SettingsService from '../../services/SettingsService';
import PlutoTVService from '../../services/PlutoTVService';
import { UpshowContextProvider } from '../../UpshowContext';

class PlutoTVState extends UPshowState {

    constructor (node, state) {
        super(
            node,
            state
        );
    }

    get name () {
        return 'plutotv';
    }

    async preload () {
        this.state.muted = SettingsService.hasTrueUiSetting('muted');
        if (!this.state.muted) {
            this.state.soundState.shouldSound = true;
        }
        const channel_id = _get(this.state, 'state.itemId', null);

        if (!channel_id) {
            throw new StatePreconditionError('Channel Id not found.');
        }

        // Get Schedule when the PlutoTv is not in Script
        const channel = await PlutoTVService.getPlutoTVChannel(channel_id);
        this.state.channelUrl = _get(channel, 'url', null);


        if (!this.state.channelUrl) {
            throw new StatePreconditionError('No PlutoTV channel to play');
        } else {
            this._render(this.handlers.resolveReady);
        }

        this.metricData = { ...this.metricData, id: parseInt(channel_id) };

        return this.state.readyPromise;

    }

    _render () { 
        ReactDOM.render(<UpshowContextProvider>
            <PlutoTVPlayer 
            muted={this.state.muted}
            url={this.state.channelUrl}
            playing={this.state.playing}
            onPlay={this.raisePlaying}
            onReady={this.raiseReady}
            onEnded={this.raiseDone}
            onError={this.raiseError}
            ref={(el) => this.plutotv = el}/> 
        </UpshowContextProvider>, this.node);
    }

    _playDuration () {
        if (this.state.duration && this.state.duration > 0) {
            this.setTimeout('duration', () => {
                this.raiseDone('duration');
            }, this.state.duration * 1000);
        }
    }

    static appliesTo () {
        return !ScreenService.isVertical;
    }
}

StateFactory.register('plutotv', PlutoTVState, 10);

export default PlutoTVState;
