import React from 'react';
import ReactDOM from 'react-dom';
import SocialGrid3 from './SocialGrid3';
import UPshowState from '../UPshowState';
import StateFactory from '../StateFactory';

class SocialGrid3State extends UPshowState {

    constructor(node, state) {
        super(node, state);
        this.state.state.contentArr.shuffle();
        this.onSocialGridMounted = this._onSocialGridMounted.bind(this);
    }

    get name() {
        return 'socialgrid3';
    }

    _render(resolve) {
        ReactDOM.render(
            <SocialGrid3
                content={this.state.state.contentArr}
                onMounted={this.onSocialGridMounted}
                playing={this.state.playing}
                onError={this.raiseError}
                duration={this.state.duration}
            />,
            this.node
        );
    }

    _onSocialGridMounted() {
        this.raiseReady();
        this.raisePlaying();
    }

}

//This is the default grid. Applies after every other "grid" doesn't
StateFactory.register('socialgrid', SocialGrid3State, 1000);

export default SocialGrid3State;
