"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getActiveTimeRangesFromSchedules = exports.SHORT_MONTHS_NAMES = exports.MAX_DAYS_PER_MONTH = exports.LONG_MONTHS_NAMES = exports.DAY_FIELDS = void 0;
exports.getDateRange = getDateRange;
exports.getDayActiveRanges = getDayActiveRanges;
exports.getDayFieldName = getDayFieldName;
exports.getFormattedTime = exports.getDayShortName = void 0;
exports.getListOfDaysInMonth = getListOfDaysInMonth;
exports.getTimeIntersections = exports.getTimeFormatted = exports.getSecondsToEnd = exports.getScheduleScriptsByPrecedence = exports.getScheduleActiveRangeString = exports.getMixDayActiveRanges = void 0;
exports.getTimeRange = getTimeRange;
exports.getWeekRange = void 0;
exports.get_overlapping_schedules = get_overlapping_schedules;
exports.hasNoStartAndNoEndDate = exports.hasActiveSchedulesNow = void 0;
exports.hasRollOver = hasRollOver;
exports.hasUpcomingSchedules = void 0;
exports.many_to_many_overlapping = many_to_many_overlapping;
exports.moment = void 0;
exports.one_to_many_overlapping = one_to_many_overlapping;
exports.one_to_one_overlapping = one_to_one_overlapping;
exports.recalculateFreeDaysTime = void 0;
exports.scheduleAppliesToFuture = scheduleAppliesToFuture;
exports.scheduleisActive = scheduleisActive;
exports.timeRangeIsValid = timeRangeIsValid;
exports.validFromIsValid = validFromIsValid;
exports.validThroughIsValid = validThroughIsValid;
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
var _momentRange = require("moment-range");
var _lodash = require("lodash");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const moment = exports.moment = (0, _momentRange.extendMoment)(_momentTimezone.default);
const DAY_FIELDS = exports.DAY_FIELDS = ['sundays', 'mondays', 'tuesdays', 'wednesdays', 'thursdays', 'fridays', 'saturdays'];
const SHORT_MONTHS_NAMES = exports.SHORT_MONTHS_NAMES = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'];
const LONG_MONTHS_NAMES = exports.LONG_MONTHS_NAMES = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const MAX_DAYS_PER_MONTH = exports.MAX_DAYS_PER_MONTH = {
  1: 31,
  2: 29,
  3: 31,
  4: 30,
  5: 31,
  6: 30,
  7: 31,
  8: 31,
  9: 30,
  10: 31,
  11: 30,
  12: 31
};
function getListOfDaysInMonth(month) {
  return Array(MAX_DAYS_PER_MONTH[month]).fill(0).map((_, i) => i + 1);
}
function getDayActiveRanges(ISODate, schedule, schedule_tz) {
  const {
    validFromTime,
    validThroughTime
  } = schedule;
  let ranges = [];

  // Parse date
  const currentMoment = moment.tz(ISODate, schedule_tz);
  const currentDate = currentMoment.format('YYYY-MM-DD');

  // Get day of week
  const dayIndex = currentMoment.day();
  const previousDayIndex = (dayIndex + 7 - 1) % 7;
  const dayIsActive = schedule[getDayFieldName(dayIndex)];
  const previousDayIsActive = schedule[getDayFieldName(previousDayIndex)];

  // Regular active range for current day
  if (dayIsActive) {
    const startTime = validFromTime || '00:00:00';
    const endTime = validThroughTime && !hasRollOver(schedule) ? validThroughTime : '23:59:59'; // watch out the leap second

    const range = {
      start: moment.tz(`${currentDate} ${startTime}`, schedule_tz).toISOString(),
      end: moment.tz(`${currentDate} ${endTime}`, schedule_tz).toISOString()
    };
    ranges = [range, ...ranges];
  }

  // Rolled over range from previous day
  if (previousDayIsActive && hasRollOver(schedule)) {
    const range = {
      start: moment.tz(`${currentDate} 00:00:00`, schedule_tz).toISOString(),
      end: moment.tz(`${currentDate} ${validThroughTime}`, schedule_tz).toISOString()
    };
    ranges = [range, ...ranges];
  }
  return ranges;
}
const getScheduleScriptsByPrecedence = schedules => {
  if (!(schedules !== null && schedules !== void 0 && schedules.length)) throw new Error('Cannot get first schedule of empty list');
  const lockedSchedules = getLockedSchedules(schedules);
  if (lockedSchedules.length) {
    return getHigherAndMostRecentLockedMix(lockedSchedules);
  } else {
    return getCloserAndMostRecentMix(schedules);
  }
};
exports.getScheduleScriptsByPrecedence = getScheduleScriptsByPrecedence;
const getCloserOrgDistance = schedules => {
  const {
    org_distance
  } = (0, _lodash.maxBy)(schedules, 'org_distance');
  return org_distance;
};
const getFarthestOrgDistances = schedules => {
  const {
    org_distance
  } = (0, _lodash.minBy)(schedules, 'org_distance');
  return org_distance;
};
const getSchedulesByDistance = (schedules, org_distance) => {
  return (0, _lodash.filter)(schedules, {
    org_distance
  });
};
const getNewerSchedule = schedules => {
  const newerSchedulesById = (0, _lodash.sortBy)(schedules, ['id']);
  return newerSchedulesById.pop();
};
const getHigherAndMostRecentLockedMix = lockedSchedules => {
  // Get most recent and higher locked mix
  const org_distance = getFarthestOrgDistances(lockedSchedules);
  const schedulesByDistance = getSchedulesByDistance(lockedSchedules, org_distance);
  return getNewerSchedule(schedulesByDistance);
};
const getCloserAndMostRecentMix = schedules => {
  // Get most recent and closer mix
  const org_distance = getCloserOrgDistance(schedules);
  const schedulesByDistance = getSchedulesByDistance(schedules, org_distance);
  return getNewerSchedule(schedulesByDistance);
};
const getLockedSchedules = schedules => {
  return schedules.filter(schedule => !!schedule.lock);
};
function getDayFieldName(dayOfWeekIndex) {
  if (dayOfWeekIndex < 0 || dayOfWeekIndex > 6) {
    throw new Error('dayOfWeekIndex must be from 0 to 6');
  }
  return DAY_FIELDS[dayOfWeekIndex];
}
function hasRollOver(schedule) {
  const {
    validFromTime,
    validThroughTime
  } = schedule;
  return validFromTime && validThroughTime && validThroughTime <= validFromTime;
}
function scheduleisActive(ISODate, schedule, default_tz) {
  const schedule_tz = schedule.tz ? schedule.tz : default_tz;
  return validFromIsValid(ISODate, schedule, schedule_tz) && validThroughIsValid(ISODate, schedule, schedule_tz) && getDayActiveRanges(ISODate, schedule, schedule_tz).some(r => timeRangeIsValid(ISODate, r, schedule_tz));
}
function timeRangeIsValid(ISODate, range, schedule_tz) {
  const {
    start,
    end
  } = range;
  if (!start || !end) {
    throw new Error('Range must have both "start" and "end" dates');
  }
  const startMoment = moment.tz(start, schedule_tz);
  const endMoment = moment.tz(end, schedule_tz);
  const currentMoment = moment(ISODate).tz(schedule_tz);
  if (endMoment.isBefore(startMoment)) {
    throw new Error('End date can\'t be before start date');
  }
  return currentMoment.isSameOrAfter(startMoment) && currentMoment.isSameOrBefore(endMoment);
}
function validFromIsValid(currentISODate, schedule, schedule_tz) {
  let isValid = true;
  const {
    validFrom,
    validFromTime
  } = schedule;
  if (validFrom) {
    const date = validFrom;
    const time = validFromTime || '00:00:00';
    const validFromMoment = moment.tz(`${date} ${time}`, schedule_tz);
    const currentMoment = moment(currentISODate).tz(schedule_tz);
    isValid = !currentMoment.isBefore(validFromMoment);
  }
  return isValid;
}
function validThroughIsValid(currentISODate, schedule, schedule_tz) {
  let isValid = true;
  const {
    validThrough,
    validThroughTime
  } = schedule;
  if (validThrough) {
    const date = validThrough;
    const time = validThroughTime || '23:59:59';
    const validThroughMoment = moment.tz(`${date} ${time}`, schedule_tz);
    const currentMoment = moment(currentISODate).tz(schedule_tz);
    if (hasRollOver(schedule)) {
      validThroughMoment.add(1, 'd');
    }
    isValid = !currentMoment.isAfter(validThroughMoment);
  }
  return isValid;
}
function getTimeRange(schedule) {
  const ISODate = new Date().toISOString();
  const {
    validFromTime,
    validThroughTime,
    tz
  } = schedule;
  let range;

  // Parse date
  const currentMoment = moment.tz(ISODate, tz);
  const currentDate = currentMoment.format('YYYY-MM-DD');

  // Rolled over range from previous day
  if (hasRollOver(schedule)) {
    const endDayMoment = moment.tz(ISODate, tz).add(1, 'days');
    const endDay = endDayMoment.format('YYYY-MM-DD');
    range = moment.range(moment.tz(`${currentDate} ${validFromTime}`, tz), moment.tz(`${endDay} ${validThroughTime}`, tz));
  } else {
    const startTime = validFromTime !== null && validFromTime !== void 0 ? validFromTime : '00:00:00';
    const endTime = validThroughTime !== null && validThroughTime !== void 0 ? validThroughTime : '23:59:59';
    range = moment.range(moment.tz(`${currentDate} ${startTime}`, tz), moment.tz(`${currentDate} ${endTime}`, tz));
  }
  return range;
}
function getDateRange(schedule) {
  var _schedule$validFrom, _schedule$validThroug;
  const {
    tz
  } = schedule;
  const validFrom = (_schedule$validFrom = schedule === null || schedule === void 0 ? void 0 : schedule.validFrom) !== null && _schedule$validFrom !== void 0 ? _schedule$validFrom : '2018-01-01';
  const validThrough = (_schedule$validThroug = schedule === null || schedule === void 0 ? void 0 : schedule.validThrough) !== null && _schedule$validThroug !== void 0 ? _schedule$validThroug : '2099-01-01';
  const formatDate = 'YYYY-MM-DD';
  const start = moment.tz(validFrom, formatDate, tz);
  let end;
  if (hasRollOver(schedule)) {
    end = moment.tz(validThrough, formatDate, tz).add(1, 'days');
  } else {
    end = moment.tz(validThrough, formatDate, tz);
  }
  return moment.range(start, end).snapTo('day');
}
function is_overlapping_days_schedules(schedule_a, schedule_b) {
  return DAY_FIELDS.some(day => schedule_a[day] === schedule_b[day] && schedule_a[day]);
}
function is_overlapping_ranges(ranges_a, ranges_b, adjacent) {
  var _ranges_a$overlaps;
  return (_ranges_a$overlaps = ranges_a.overlaps(ranges_b, {
    adjacent
  })) !== null && _ranges_a$overlaps !== void 0 ? _ranges_a$overlaps : false;
}
function one_to_one_overlapping(one, another) {
  const range_date_one = getDateRange(one);
  const range_time_one = getTimeRange(one);
  const range_date_another = getDateRange(another);
  const range_time_another = getTimeRange(another);
  return one.id !== another.id && is_overlapping_days_schedules(one, another) && is_overlapping_ranges(range_date_one, range_date_another, true) && is_overlapping_ranges(range_time_one, range_time_another, false);
}
function one_to_many_overlapping(one, many = []) {
  return many.filter(another => one_to_one_overlapping(one, another));
}
function many_to_many_overlapping(many = [], others = []) {
  return many.map(schedule => ({
    schedule,
    overlap: one_to_many_overlapping(schedule, others)
  }));
}
function get_overlapping_schedules(schedules) {
  const overlapping_schedules = schedules.reduce((result, current, idx) => {
    const rest_schedules = schedules.slice(idx + 1);
    const overlapping_result = one_to_many_overlapping(current, rest_schedules);
    if (Array.isArray(overlapping_result) && overlapping_result.length > 0) {
      result.push(current);
      result = result.concat(overlapping_result);
    }
    return result;
  }, []);
  return (0, _lodash.uniqBy)(overlapping_schedules, 'id');
}
const getTimeFormatted = time => {
  return moment(time, 'HH:mm:ss').format('hh:mm A');
};
exports.getTimeFormatted = getTimeFormatted;
const getWeekRange = currentTime => {
  const startDate = moment(currentTime);
  const endDate = moment(currentTime);
  endDate.add(6, 'days');
  return moment.range(startDate, endDate);
};
exports.getWeekRange = getWeekRange;
const getSecondsToEnd = schedule => {
  var _schedule$tz;
  const tz = (_schedule$tz = schedule === null || schedule === void 0 ? void 0 : schedule.tz) !== null && _schedule$tz !== void 0 ? _schedule$tz : 'America/Chicago';
  const {
    validFrom,
    validThrough,
    validFromTime,
    validThroughTime
  } = schedule;
  const {
    sundays,
    mondays,
    tuesdays,
    wednesdays,
    thursdays,
    fridays,
    saturdays
  } = schedule;
  const active_all_days = sundays && mondays && tuesdays && wednesdays && thursdays && fridays && saturdays;
  const with_start_end_date = validFrom && validThrough;
  const without_start_end_date = hasNoStartAndNoEndDate(schedule);
  const without_start_end_time = !validFromTime && !validThroughTime;
  const rollover = hasRollOver(schedule);
  if (active_all_days) {
    if (without_start_end_date && (without_start_end_time || validFromTime === validThroughTime)) {
      return Infinity;
    }
    if (with_start_end_date && (without_start_end_time || validFromTime === validThroughTime)) {
      return moment.tz(`${validThrough}  ${'23:59:59'}`, 'YYYY-MM-DD HH:mm:ss', tz).diff(moment(), 'seconds');
    }
    if (without_start_end_date && !without_start_end_time && validFromTime !== validThroughTime) {
      const day = moment.tz(tz).add(rollover ? 1 : 0, 'days').format('YYYY-MM-DD');
      return moment.tz(`${day} ${validThroughTime}`, 'YYYY-MM-DD HH:mm:ss', tz).diff(moment.tz(tz), 'seconds');
    }
    if (with_start_end_date && !without_start_end_time && validFromTime !== validThroughTime) {
      const day = moment.tz(tz).add(rollover ? 1 : 0, 'days').format('YYYY-MM-DD');
      return moment.tz(`${day}  ${'23:59:59'}`, 'YYYY-MM-DD HH:mm:ss', tz).diff(moment(), 'seconds');
    }
    return moment.tz(`${validThroughTime}`, 'HH:mm:ss', tz).diff(moment(), 'seconds');
  }
  const current_day = moment.tz(tz).day();
  const days_order = DAY_FIELDS.slice(current_day).concat(DAY_FIELDS.slice(0, current_day));
  const active_days = days_order.findIndex(day => !schedule[day]);
  const end_date = moment.tz(tz).add(active_days - 1 >= 0 ? active_days - 1 : 0, 'days');
  if (without_start_end_time) {
    const end_date_formatted = end_date.format('YYYY-MM-DD');
    return moment(`${end_date_formatted}  ${'23:59:59'}`, 'YYYY-MM-DD HH:mm:ss').diff(moment(), 'seconds');
  }
  if (validFromTime !== validThroughTime) {
    if (rollover) {
      const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');
      return moment.tz(`${tomorrow} ${validThroughTime}`, 'YYYY-MM-DD HH:mm:ss', tz).diff(moment(), 'seconds');
    }
    const end_date_formatted = moment().format('YYYY-MM-DD');
    return moment.tz(`${end_date_formatted} ${validThroughTime}`, 'YYYY-MM-DD HH:mm:ss', tz).diff(moment(), 'seconds');
  }
  if (rollover) {
    const tomorrow = moment(end_date).add(1, 'days').format('YYYY-MM-DD');
    return moment.tz(`${tomorrow} ${validThroughTime}`, 'YYYY-MM-DD HH:mm:ss', tz).diff(moment(), 'seconds');
  }
  const end_date_formatted = end_date.format('YYYY-MM-DD');
  return moment.tz(`${end_date_formatted} ${validThroughTime}`, 'YYYY-MM-DD HH:mm:ss', tz).diff(moment(), 'seconds');
};
exports.getSecondsToEnd = getSecondsToEnd;
const getScheduleActiveRangeString = schedule => {
  var _schedule$tz2;
  const tz = (_schedule$tz2 = schedule === null || schedule === void 0 ? void 0 : schedule.tz) !== null && _schedule$tz2 !== void 0 ? _schedule$tz2 : 'America/Chicago';
  const {
    validFrom,
    validThrough,
    validFromTime,
    validThroughTime
  } = schedule;
  const {
    sundays,
    mondays,
    tuesdays,
    wednesdays,
    thursdays,
    fridays,
    saturdays
  } = schedule;
  const active_all_days = sundays && mondays && tuesdays && wednesdays && thursdays && fridays && saturdays;
  const with_start_end_date = validFrom && validThrough;
  const without_start_end_date = hasNoStartAndNoEndDate(schedule);
  const without_start_end_time = !validFromTime && !validThroughTime;
  const rollover = hasRollOver(schedule);
  const getTimeFormat = time => {
    return moment(`2020-01-01 ${time}`, 'YYYY-MM-DD HH:mm:ss').format('hh:mm a');
  };
  const getDateFormat = (date = moment.tz(tz).format('YYYY-MM-DD'), useRollover = false) => {
    return moment(`${date} 00:00:00`, 'YYYY-MM-DD HH:mm:ss').add(rollover && useRollover ? 1 : 0, 'days').format('MMM D');
  };
  if (active_all_days) {
    if (without_start_end_date && without_start_end_time) {
      return 'All days';
    }
    if (with_start_end_date && without_start_end_time) {
      return getDateFormat(validFrom) + ', ' + getTimeFormat(validFromTime) + ' - ' + getDateFormat(validThrough, true) + ', ' + getTimeFormat(validThroughTime);
    }
    return 'Today from, ' + getTimeFormat(validFromTime) + ' to ' + getTimeFormat(validThroughTime) + (rollover ? ' next day' : '');
  }
  const current_day = moment.tz(tz).day();
  const days_order = DAY_FIELDS.slice(current_day).concat(DAY_FIELDS.slice(0, current_day));
  const active_days = days_order.findIndex(day => !schedule[day]);
  const end_date = moment.tz(tz).add(active_days - 1 >= 0 ? active_days - 1 : 0, 'days');
  let days_order_back = days_order.slice();
  days_order_back.reverse();
  const active_days_back = days_order_back.findIndex(day => !schedule[day]);
  const start_date = moment.tz(tz).subtract(active_days_back - 1 >= 0 ? active_days_back - 1 : 0, 'days');
  if (without_start_end_date && without_start_end_time) {
    return getDateFormat(start_date.format('YYYY-MM-DD')) + ', ' + getTimeFormat('00:00:00') + ' - ' + getDateFormat(end_date.format('YYYY-MM-DD'), true) + ', ' + getTimeFormat('23:59:59');
  }
  if (without_start_end_date && !without_start_end_time && validFromTime === validThroughTime) {
    return getDateFormat(start_date.format('YYYY-MM-DD')) + ', ' + getTimeFormat(validFromTime) + ' - ' + getDateFormat(end_date.format('YYYY-MM-DD'), true) + ', ' + getTimeFormat(validThroughTime) + (rollover ? ' next day' : '');
  }
  if (!without_start_end_time && validFromTime !== validThroughTime) {
    const today = moment.tz(tz);
    return getDateFormat(today.format('YYYY-MM-DD')) + ', ' + getTimeFormat(validFromTime) + ' - ' + getDateFormat(today.format('YYYY-MM-DD'), true) + ', ' + getTimeFormat(validThroughTime) + (rollover ? ' next day' : '');
  }
  const active_range = moment.range(moment.tz(`${start_date.format('YYYY-MM-DD')} 00:00:00`, 'YYYY-MM-DD HH:mm:ss', tz), moment.tz(`${end_date.format('YYYY-MM-DD')} 23:59:59`, 'YYYY-MM-DD HH:mm:ss', tz));
  const schedule_range = moment.range(moment.tz(`${validFrom} ${validFromTime !== null && validFromTime !== void 0 ? validFromTime : '00:00:00'}`, 'YYYY-MM-DD HH:mm:ss', tz), moment.tz(`${validThrough} ${validThroughTime === '00:00:00' || !validThroughTime ? '23:59:59' : validThroughTime}`, 'YYYY-MM-DD HH:mm:ss', tz));
  const intersection = schedule_range.intersect(active_range);
  if (!intersection) return null;
  if (!without_start_end_time && validFromTime === validThroughTime) {
    return getDateFormat(intersection.start.format('YYYY-MM-DD')) + ', ' + getTimeFormat(validFromTime) + ' - ' + getDateFormat(intersection.end.format('YYYY-MM-DD'), false) + ', ' + getTimeFormat(validThroughTime) + (rollover ? ' next day' : '');
  }
  return getDateFormat(intersection.start.format('YYYY-MM-DD')) + ', ' + getTimeFormat('00:00:00') + ' - ' + getDateFormat(intersection.end.format('YYYY-MM-DD'), true) + ', ' + getTimeFormat('23:59:59');
};
exports.getScheduleActiveRangeString = getScheduleActiveRangeString;
const getActiveTimeRangesFromSchedules = (currentDay, schedule, daysOffset = 0) => {
  const {
    validFromTime,
    validThroughTime,
    tz
  } = schedule;
  const ranges = [];

  // Parse date
  const currentMoment = moment(currentDay).add(daysOffset, 'days');
  const currentDate = currentMoment.format('YYYY-MM-DD');

  // Get day of week
  const dayIndex = currentMoment.day();
  const previousDayIndex = (dayIndex + 7 - 1) % 7;
  const dayIsActive = schedule[getDayFieldName(dayIndex)];
  const previousDayIsActive = schedule[getDayFieldName(previousDayIndex)];
  const scheduleRange = getDateRange(schedule);
  if (scheduleRange.contains(currentMoment)) {
    // Regular active range for current day
    if (dayIsActive) {
      const startTime = validFromTime || '00:00:00';
      const endTime = validThroughTime && !hasRollOver(schedule) ? validThroughTime : '23:59:59';
      const range = moment.range(moment.tz(`${currentDate} ${startTime}`, tz), moment.tz(`${currentDate} ${endTime}`, tz));
      ranges.push(range);
    }

    // Rolled over range from previous day
    if (previousDayIsActive && hasRollOver(schedule)) {
      const range = moment.range(moment.tz(`${currentDate} 00:00:00`, tz), moment.tz(`${currentDate} ${validThroughTime}`, tz));
      ranges.push(range);
    }
  }
  return ranges;
};
exports.getActiveTimeRangesFromSchedules = getActiveTimeRangesFromSchedules;
const getMixDayActiveRanges = (currentDay, schedule) => {
  const ranges = [];
  const currentDayRange = moment.range(moment(currentDay), moment(currentDay)).snapTo('day');
  const tz = moment(currentDay).tz();
  const preRanges = getActiveTimeRangesFromSchedules(currentDay, schedule, -1);
  const currentRanges = getActiveTimeRangesFromSchedules(currentDay, schedule, 0);
  const nextRanges = getActiveTimeRangesFromSchedules(currentDay, schedule, 1);
  const calcIntersections = range => {
    const intersection = currentDayRange.intersect(range);
    if (!!intersection) {
      ranges.push(moment.range(moment.tz(intersection.start, tz), moment.tz(intersection.end, tz)));
    }
  };
  const allRanges = preRanges.concat(currentRanges).concat(nextRanges);
  const mergedRanges = allRanges.reduce((acc, current) => {
    const last = acc.pop();
    const merged = last === null || last === void 0 ? void 0 : last.add(current, {
      adjacent: true
    });
    if (!!merged) {
      acc.push(merged);
    } else {
      if (!!last) {
        acc.push(last);
      }
      acc.push(current);
    }
    return acc;
  }, []);
  mergedRanges.forEach(calcIntersections);
  return ranges;
};
exports.getMixDayActiveRanges = getMixDayActiveRanges;
const getDayShortName = date => {
  return date.format('ddd');
};
exports.getDayShortName = getDayShortName;
const getTimeIntersections = (ranges, freeDaySpaces) => {
  const intersections = [];
  if (Array.isArray(ranges) && Array.isArray(freeDaySpaces)) {
    ranges.forEach(range => {
      freeDaySpaces.forEach(freeDaySpace => {
        const tz = moment(freeDaySpace.start).tz();
        const intersection = freeDaySpace.intersect(range);
        if (!!intersection) {
          intersections.push(moment.range(moment.tz(intersection.start, tz), moment.tz(intersection.end, tz)));
        }
      });
    });
  }
  return intersections;
};
exports.getTimeIntersections = getTimeIntersections;
const recalculateFreeDaysTime = (intersections, freeDaySpaces) => {
  if (!Array.isArray(intersections) && !Array.isArray(freeDaySpaces)) {
    return freeDaySpaces;
  }
  return intersections.reduce((freeSpaces, intersection) => {
    let auxSpaces = [];
    freeSpaces.forEach(freeDaySpace => {
      const ranges = freeDaySpace.subtract(intersection);
      const tz = moment(freeDaySpace.start).tz();
      if (Array.isArray(ranges) && !!ranges[0]) {
        ranges.forEach(range => {
          auxSpaces.push(moment.range(moment.tz(range.start, tz), moment.tz(range.end, tz)));
        });
      }
    });
    return auxSpaces;
  }, (0, _lodash.cloneDeep)(freeDaySpaces));
};
exports.recalculateFreeDaysTime = recalculateFreeDaysTime;
const getFormattedTime = date => {
  return date.format('HH:mm:ss');
};
exports.getFormattedTime = getFormattedTime;
const hasNoStartAndNoEndDate = schedule => {
  const {
    validFrom,
    validThrough
  } = schedule;
  return !validFrom && !validThrough;
};
exports.hasNoStartAndNoEndDate = hasNoStartAndNoEndDate;
const hasActiveSchedulesNow = schedule => {
  var _schedule$tz3;
  const tz = (_schedule$tz3 = schedule === null || schedule === void 0 ? void 0 : schedule.tz) !== null && _schedule$tz3 !== void 0 ? _schedule$tz3 : 'America/Chicago';
  const ISODate = new Date().toISOString();
  const currentMoment = moment.tz(ISODate, tz);
  const activeTimeRanges = getActiveTimeRangesFromSchedules(currentMoment, schedule);
  if (activeTimeRanges.length > 0) {
    const currentActiveTimeRanges = activeTimeRanges.filter(r => r.contains(currentMoment));
    return currentActiveTimeRanges.length > 0;
  }
  return false;
};
exports.hasActiveSchedulesNow = hasActiveSchedulesNow;
const hasUpcomingSchedules = schedule => {
  if (!hasNoStartAndNoEndDate(schedule)) {
    var _schedule$tz4;
    const tz = (_schedule$tz4 = schedule === null || schedule === void 0 ? void 0 : schedule.tz) !== null && _schedule$tz4 !== void 0 ? _schedule$tz4 : 'America/Chicago';
    const ISODate = new Date().toISOString();
    const currentMoment = moment.tz(ISODate, tz);
    const scheduleDateRange = getDateRange(schedule);
    if (currentMoment.isBefore(scheduleDateRange.start)) {
      return true;
    } else {
      const activeTimeRanges = getActiveTimeRangesFromSchedules(currentMoment, schedule);
      if (activeTimeRanges.length > 0) {
        const upcomingActiveTimeRanges = activeTimeRanges.filter(r => currentMoment.isBefore(r.start));
        return upcomingActiveTimeRanges.length > 0;
      }
    }
  } else {
    const {
      sundays,
      mondays,
      tuesdays,
      wednesdays,
      thursdays,
      fridays,
      saturdays
    } = schedule;
    const activeAllDays = sundays && mondays && tuesdays && wednesdays && thursdays && fridays && saturdays;
    const activeSomeDays = containsSomeDays(schedule) && !activeAllDays;
    const {
      validFromTime,
      validThroughTime
    } = schedule;
    const allDaysAndValid = activeAllDays && !!validFromTime && !!validThroughTime;
    return allDaysAndValid || activeSomeDays;
  }
  return false;
};
exports.hasUpcomingSchedules = hasUpcomingSchedules;
function containsSomeDays(schedule) {
  return schedule.sundays || schedule.mondays || schedule.tuesdays || schedule.wednesdays || schedule.thursdays || schedule.fridays || schedule.saturdays;
}
function timeToMinutes(time) {
  if (typeof time === 'string') {
    const times = time.split(':');
    const minutes = parseInt(times[1], 10);
    const hours = parseInt(times[0], 10);
    return isNaN(minutes) || isNaN(hours) ? 0 : minutes + hours * 60;
  }
  return time.minutes() + time.hours() * 60;
}
function appliesAfterCurrentTime(schedule, tz) {
  if (!schedule.validFromTime || !schedule.validThroughTime) {
    return false;
  }
  const fromTime = timeToMinutes(schedule.validFromTime);
  const toTime = timeToMinutes(schedule.validThroughTime);
  const nowTime = timeToMinutes(moment().tz(tz));
  const fromTimeAfterCurrentTime = nowTime < fromTime && fromTime < toTime;
  const fromTimeAfterCurrentTime_NextDay = nowTime < fromTime && toTime < nowTime;
  return fromTimeAfterCurrentTime_NextDay || fromTimeAfterCurrentTime;
}

// Note: It's not a strict filter.
function scheduleAppliesToFuture(schedule, defaultTz = 'America/Chicago') {
  const tz = schedule.tz ? schedule.tz : defaultTz;
  const {
    validFrom,
    validThrough
  } = schedule;

  // It does not have start and end date and It apply to some days
  if (hasNoStartAndNoEndDate(schedule) && containsSomeDays(schedule)) {
    return true;
  }

  // It does not apply to any day
  if (!containsSomeDays(schedule)) {
    return false;
  }
  const formatDate = 'YYYY-MM-DD';
  const nowDate = moment().tz(tz);
  const fromDate = moment.tz(validFrom || '2018-01-01', formatDate, tz);
  const toDate = moment.tz(validThrough || '2099-01-01', formatDate, tz);

  // Today is before the start date.
  if (nowDate.isBefore(fromDate, 'day')) {
    return true;
  }

  // Today is the the start date and yet is not the end date
  if (nowDate.isSame(fromDate, 'day') && nowDate.isBefore(toDate, 'day')) {
    return true;
  }

  // Today is between start and end date
  if (nowDate.isBetween(fromDate, toDate, 'day')) {
    return true;
  }

  // Today is the end date and yet is not the schedule time
  return !!(nowDate.isSame(toDate, 'day') && appliesAfterCurrentTime(schedule, tz));
}