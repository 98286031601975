import React from 'react';
import './Loading.scss';
import { getDeviceInfo, teslaCacheX } from '../../Upshow';
import ReactPlayer from 'react-player';
import Logger from '../../Logger';

class Loading extends React.Component {
    constructor (props) {
        super(props);
        this.url = teslaCacheX('https://cdn.upshow.tv/ui-assets/Everpass_Logo_Animation.mp4');
    }

    render () {
        return <div className="Loading">
            {['AndroidSkykit','Skykit'].includes(getDeviceInfo().model)
                ? <></>
                : <ReactPlayer
                    width="100%" height="100%" volume={0} playing
                    url={this.url}
                    onError={(error) => Logger.warn('Loading state could not play loading media', error)}
                    config={{
                        file: {
                            attributes: {
                                crossOrigin: 'anonymous',
                                poster: 'https://cdn.upshow.tv/1x1.png'
                            }
                        }
                    }}/>
            }
        </div>;
    }
}

export default Loading;