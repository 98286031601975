import { useCallback, useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { getPWADisplayMode } from '../../state/deviceInfo';
import UpshowLogger from '../../Logger';

export default function useInstallPrompt () {
    const [installPrompt, setInstallPrompt] = useState(null);
    const [installationCanceled, setInstallationCanceled] = useLocalStorage('installationCanceled', 0);

    const onPromptCancel = useCallback(() => {
        setInstallPrompt(null);
        setInstallationCanceled(1);
    }, [setInstallPrompt, setInstallationCanceled]);

    useEffect(() => {
        const handleAppInstalled = () => {
            onPromptCancel();
            UpshowLogger.log(['PWA'], 'PWA was Installed');
        };

        if (installationCanceled !== 1 && getPWADisplayMode() === 'WebView') {
            setInstallPrompt(window.pwaInstallEvent);
        }

        window.addEventListener('appinstalled', handleAppInstalled);

        return () => {
            window.removeEventListener('appinstalled', handleAppInstalled);
        };
    }, [onPromptCancel, installationCanceled]);

    return [installPrompt, onPromptCancel];
}