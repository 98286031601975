import React from 'react';
import './InstallPrompt.scss';
import useInstallPrompt from './useInstallPrompt';

export default function InstallPrompt () {

    const [installPrompt, onPromptCancel] = useInstallPrompt();

    const callInstall = async () => {
        // Show the installation prompt
        installPrompt.prompt();
        const { outcome } = await installPrompt.userChoice;
        // Optionally, send analytics event with outcome of user choice
        console.log(`User response to the install prompt: ${outcome}`);
        // We've used the prompt, and can't use it again, throw it away
        onPromptCancel();
    };

    if (installPrompt) {
        return (
            <div className='pwa-install-prompt'>
                <div className='pwa-install-prompt__header'>
                    <button type="button" onClick={onPromptCancel}>
                        X
                    </button>
                </div>
                <h5>You need to install</h5>
                <p>before viewing UPshow's entertainment.</p>
                <button type="button" onClick={callInstall} className='pwa-install-prompt__button'>
                    Install
                </button>
            </div>
        );
    }

    return <></>;
    
}