import React from 'react';
import PropTypes from 'prop-types';

import './DefaultCta.scss';
import DefaultTextCta from './DefaultTextCta/DefaultTextCta';
import SettingsService from '../../../services/SettingsService';
import { alternateSocialCTA, getSocialBrandKitStyle } from '../../../libs/helpers';
import ScreenService from '../../../services/ScreenService';
import SayCheeseCta from '../SayCheeseCta/SayCheeseCta';

class DefaultCta extends React.Component {

    constructor (props) {
        super(props);

        this.cta = SettingsService.getCta();
        this.operator = SettingsService.getSetting('operator');

        this.lastCtaShown = alternateSocialCTA('lastSocialGridCtaShown', this.cta.tags);
        this.isVertical = ScreenService.isVertical;
    }

    render () {
        const { className, type, relaxed } = this.props;

        const { hidePowered } = this.props;

        let defaultText = 'Get on this screen!';

        let headerText = this.cta.alt_cta || defaultText;

        if (headerText.indexOf('<br>') > 0 && type !== 'media') {
            headerText = headerText.split('<br>').map((line, idx) => (
                <span key={idx}>
                    {idx === 0 ? null : <br/>}
                    {line}
                </span>
            ));
        } else if (headerText.indexOf('<br>') > 0 && type === 'media') {
            headerText = headerText.split('<br>').join(' ');
        }
        const { backgroundColor, color } = getSocialBrandKitStyle();
        const longCta = SettingsService.getCta().long_cta;
        const qrSize = longCta && !relaxed ? 270 : 350;
        const customClass = `small ${relaxed ? 'relaxed' : ''} ${longCta ? 'long-cta' : ''}`;
        
        return (
            <div className={`Cta DefaultCta ${this.isVertical ? 'vertical' : ''}`} style={{backgroundColor, color}}>
                { this.lastCtaShown === 'normal' ?
                    <DefaultTextCta
                        className={className}
                        headerText={headerText}
                        type={type}
                        operator={this.operator}
                        longCta={longCta}
                        hashtags={this.cta.tags}
                        relaxed={relaxed}
                        isAltCta={SettingsService.hasSetting('alt_cta')}
                    />
                    : <SayCheeseCta qrSize={qrSize} onError={this.props.onError} customClass={customClass} longCta={longCta}/>
                }
                {!hidePowered && <div className="pwby-logo"/>}
            </div>
        );
    }
}

DefaultCta.propTypes = {
    className: PropTypes.string,
    type: PropTypes.oneOf(['default', 'zoom', 'media', 'grid']),
    hidePowered: PropTypes.bool,
    relaxed: PropTypes.bool,
};

DefaultCta.defaultProps = {
    className: '',
    type: 'default',
    hidePowered: false,
    relaxed: false,
};

export default DefaultCta;