import React from 'react';
import QRCode from 'qrcode.react';

const getTrackingCode = (token) => {
    const url = new URL(process.env.REACT_APP_SAY_CHEESE_URL);
    url.searchParams.append('t', token);
    return url.toString();
};

const Qr = ({ token, size = 420 }) => {
    return (
        <div className='qr'>
            <QRCode
              id='UPcode'
              value={getTrackingCode(token)}
              size={size}
              level='L'
            />
        </div>
    );
};

export default Qr;
