import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import './PlutoTVPlayer.scss';
import { getDeviceInfo } from '../../Upshow';
import _ from 'lodash';
import UpshowLogger from '../../Logger';
import { UpShowDataContext } from '../../UpshowContext';
export default class PlutoTVPlayer extends Component {
    static contextType = UpShowDataContext
    isFireos = getDeviceInfo().model.startsWith("AF");
    watchdogTimeout = setTimeout(() => {
                                    _.invoke(this.props, 'onError', Error('PlutoTV timeout on play.'));
                                }, 20000);

    componentDidMount() {
        if(typeof this.props.onReady === 'function'){
            this.props.onReady();
        }

        if (this.isFireos) {
            //this is fireos
            this.onPlay();
            //set an initial longer timeout

            this.videoNode.ontimeupdate = () => {
                clearTimeout(this.watchdogTimeout);
                this.watchdogTimeout = setTimeout(() => {
                    _.invoke(this.props, 'onError', Error('PlutoTV timeout before ontimeupdate.'));
                }, 5000);
            };

            this.videoNode.onerror = () => {
                _.invoke(this.props, 'onError', Error("PlutoTv error " + this.videoNode.error.message));
            };
        }
    }

    componentWillUnmount() {
        clearTimeout(this.watchdogTimeout);
    }

    onPlay(){
        _.invoke(this.props, 'onPlay', "PlutoTv onPlay");
    }

    onError( error ){
        UpshowLogger.warn( ['PlutoTV','PlutoTVPlayer'], "ReactPlayer on Error: " + error);
    }

    onProgress( progress ){
        clearTimeout(this.watchdogTimeout);
        this.watchdogTimeout = setTimeout(() => {
                _.invoke(this.props, 'onError', Error('PlutoTV timeout after play on second: ' + progress.playedSeconds));
        }, 15000);
    }
    
    render() {
        const { contentVolume } = this.context;

        if (this.isFireos) {
            return (
                <video muted={this.props.muted} src={this._parsePlutoUrl(this.props.url)} autoPlay={true} ref={node => this.videoNode = node} className="plutoTvPlayer" />
            )
        } else {
            return (
                <ReactPlayer playing={true}
                             url={this._parsePlutoUrl(this.props.url)}
                             onStart={this.onPlay.bind(this)}
                             onError={this.onError.bind(this)}
                             onProgress={this.onProgress.bind(this)}
                             volume={this.props.muted ? 0 : contentVolume}
                             muted={this.props.muted}
                             className="plutoTvPlayer"
                             config={{
                                 file: {
                                    attributes: {
                                        poster: 'https://cdn.upshow.tv/1x1.png'
                                    }}
                                }} />
            )
        }
    }

    _parsePlutoUrl(inUrl) {
        const deviceInfo = getDeviceInfo();
        const placeholders = {
            '$protocol$': 'https',
            '$domain$': 'stitcher-upshow.pluto.tv',
            '$deviceId$': deviceInfo.serial || deviceInfo.sessionId,
            '$appVersion$': deviceInfo.version || deviceInfo.uiVersion,
            '$sid$': deviceInfo.sessionId,
            '$deviceType$': this._deviceType(deviceInfo),
            '$deviceModel$': deviceInfo.model,
            '$deviceMake$': deviceInfo.make
        };

        const replacers = Object.keys(placeholders);

        return replacers.reduce((url, replacer) => url.replace(replacer, placeholders[replacer]), inUrl);
    }

    _deviceType(deviceInfo) {
        if (deviceInfo.model === 'WebView') {
            return "web";
        }

        const fireOsModels = [
            "AFTRS",    //tv
            "AFTT",     // gen2 stick
            "AFTS",     // gen2 fire tv
            "AFTM",     // gen1 stick
            "AFTB",     // gen1 fire tv
        ];

        if (fireOsModels.indexOf(deviceInfo.model) > -1) {
            return "android,firetv,amazon";
        }

        return "chromeos,chromebit,asus";
    }
}