import Logger from '../Logger';
import { sampleSize, slice, findIndex } from 'lodash';
import * as UP from '../Upshow';
import MediaService from './MediaService';
import { teslaCacheX } from '../Upshow';

const JukinService = {
    stateChannelKeeper: {},
    loadChannelData: (channel) => {
        //basically in-memory network first cache
        const previousPromise = channel.dataPromise;

        channel.dataPromise = UP.getJukinChannelData(channel.id)
            .then((channelData) => {
                channel.data = channelData;
                return channelData;
            }).catch((err) => {
                Logger.error(['jukin', 'loadChannelData'], err);

                channel.dataPromise = previousPromise;

                return channel.dataPromise;
            });

        return channel.dataPromise;
    },
    getFilteredContent: (channel_id, all_content, metadata) => {
        const number_of_videos_shown = metadata.number_of_videos_shown;
        const playback_order = metadata.playback_order;

        let content = [];

        if (playback_order === 'newest_first') {
            JukinService.stateChannelKeeper[channel_id] = ((JukinService.stateChannelKeeper[channel_id] ?? -1) + 1) % all_content.length;
            content = slice(all_content, JukinService.stateChannelKeeper[channel_id], JukinService.stateChannelKeeper[channel_id] + number_of_videos_shown);
        } else {
            content = sampleSize(all_content, number_of_videos_shown);
        }

        content = content.map(media => {
            if (media.url.match(/\.upshow\.tv\//)) {
                media.url = teslaCacheX(media.url);
            }

            return media;
        });

        return content;
    },
    getChannelData: async (channel) => {
        let data;
        if (!channel.dataPromise) {
            data = await JukinService.loadChannelData(channel);
        } else {
            data = await channel.dataPromise;
        }

        const { jukin_channel_content, metadata, error, server_error } = data;
        if (!!server_error || !!error) {
            throw new Error(server_error ?? error);
        }

        if (!Array.isArray(jukin_channel_content) || jukin_channel_content.length === 0) {
            throw new Error(`Media channel has no content`);
        }
        return {
            content: JukinService.getFilteredContent(channel.id, jukin_channel_content, metadata),
            metadata,
        };
    },

    currentScheduleItem: null,

    getNextScheduleItem: () => {
        const allActive = MediaService.getActiveItemsByBehavior('script', ['jukin']);
        Logger.info(['jukin'], `jukin ${allActive.length} schedules `);

        if (JukinService.currentScheduleItem === null) {
            JukinService.currentScheduleItem = allActive.length > 0 ? allActive[0] : null;
        } else {
            //index will be 0 if the current item is not found in the list
            const index = (findIndex(allActive, { channel_id: JukinService.currentScheduleItem.channel_id }) + 1) % allActive.length;
            Logger.info(['jukin'], 'getNextScheduleItem Set next item: ' + JukinService.currentScheduleItem?.channel_id);

            JukinService.currentScheduleItem = allActive[index];

        }
        //return a copy of the item
        return Object.assign({}, JukinService.currentScheduleItem);

    }
};

export default JukinService;

