import React from 'react';
import ReactDOM from 'react-dom';

import { default as WatchToWin } from '@upshow/w2w';
import UPshowState from '../UPshowState';
import StateFactory from '../StateFactory';
import MediaService from '../../services/MediaService';
import ScreenService from '../../services/ScreenService';
import SettingsService from "../../services/SettingsService";
import { teslaCacheX , getToken } from '../../Upshow';

import UpshowLogger from '../../Logger';

class WatchToWinState extends UPshowState {
    currentToken = getToken();
    deviceId = SettingsService.getSetting('device_id');
    organizationId = SettingsService.getSetting('organization_id');
    brandKit = SettingsService.getSetting('brandKit');

    get name () {
        return 'watchtowin';
    }

    _render (resolve) {
        const w2wBrandKit = this.brandKit?.w2w ? this.brandKit : {};
        ReactDOM.render(<WatchToWin
            ref={(el) => { this.component = el; }}
            onMount={(loaddata) => {
                this.raisePlaying(loaddata);
            }}
            onError={this.raiseError}
            onDone={this.raiseDone}
            onReady={this.raiseReady}
            play={this.state.playing}
            logError={(message) => UpshowLogger.error(['watch-to-win'], message)}
            logWarning={(message) => UpshowLogger.warn(['watch-to-win'], message)}
            logDebug={(message) => UpshowLogger.debug(['watch-to-win'], message)}
            url={process.env.REACT_APP_W2W_API_URL}
            organizationId={this.organizationId}
            deviceId={this.deviceId}
            token={this.currentToken}
            teslaCacheX={teslaCacheX}
            brandKit={w2wBrandKit}
        />, this.node);

        return resolve;
    }

    async stop () {
        UpshowLogger.info(['WatchToWinState', 'pause'], 'Called stop');
        this.state.playing = false;
        try {
            await this._render(this.handlers.resolvePlay);
            this._pauseDuration();
            this.raisePaused();
        } catch (error) {
            UpshowLogger.info(['WatchToWinState', 'pause'], 'Error - calling raise error');
            this.raiseError(error);
        }

        this.metricData.stop = new Date();
        this.metricData.duration = this.metricData.stop - this.metricData.start;

        return this.state.pausePromise;
    }

    preload () {
        this._render();
        return this.state.readyPromise;
    }

    play () {
        this.metricData.start = new Date();

        UpshowLogger.info(['upshowstate', 'play'], 'Called play ' + this.name);

        this.state.playing = true;

        if (this.state.state?.takeoverData?.behavior === 'takeover') {
            this.setInterval('checkschedule', () => {
                let takeOverSchedule = MediaService.getActiveItemsByBehavior('takeover', ['watch-to-win'])[0];

                if (takeOverSchedule?.channel.id !== this.state.state.takeoverData.channel.id) {
                    this.raiseDone('Schedule has finished');
                }
            }, 10000);
        }

        this.setTimeout("watch_to_win_watchdog", () => {
            this.raiseError(new Error("Watch to Win timeout"));
        }, 10 * 60 * 1000 )

        this._render(this.handlers.resolvePlay);

        return this.state.playPromise;
    }

    static appliesTo () {
        return !ScreenService.isVertical;
    }
}

StateFactory.register('watchtowin', WatchToWinState, 10);

export default WatchToWinState;
