import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import MediaPlayingState from '../MediaPlayingState';
import StateFactory from '../StateFactory';
import { getDeviceInfo, teslaCacheXI } from '../../Upshow';
import { SpotlightPlayer, spotlightValidator } from '@upshow/spotlight-player';

import _get from 'lodash/get';
import { StatePreconditionError } from '../Errors';
import SpotlightService from '../../services/SpotlightService';
import SettingsService from '../../services/SettingsService';
import StateService from '../../services/StateService';
import TakeoverService from '../../services/TakeoverService';
import { nullOrTrue } from '../../libs/helpers';
import { UpshowContextProvider, UpShowDataContext } from '../../UpshowContext';

import { validateSpotlightForPlaying } from '@upshow/validators';
import _ from 'lodash';
import UpshowLogger from '../../Logger';


const SpotlightPlayerWithVolume = props => {
    const { contentVolume } = useContext(UpShowDataContext);
    return <SpotlightPlayer {...props} volume={contentVolume}/>
};

class SpotlightState extends MediaPlayingState {
    constructor (node, state) {
        super(
            node,
            state,
            null,
            false,
            true,
            { track: false }
        );

        this.onDurationV2 = this.onDurationV2.bind(this);
        this.onProgressV2 = this.onProgressV2.bind(this);
        this.videoWillBeInterrupted = false;
        this.deviceId = SettingsService.getSetting('device_id');
        this.organizationId = SettingsService.getSetting('organization_id');
        this.playedSeconds = 0;
        this.isTakeover = 'takeover' === _get(this.state, 'state.takeoverData.behavior', 'script');
    }

    get name () {
        return 'spotlight';
    }

    get logImpressions () {
        return true;
    }

    async preload () {
        const spotlightId = this.state.state.itemId;

        if (!spotlightId) {
            throw new StatePreconditionError('Error loading SpotlightState without a valid spotlight ID');
        }

        this.state.spotlight = await SpotlightService.getSpotlight(spotlightId);

        if (!this.state.spotlight || !validateSpotlightForPlaying(this.state.spotlight)) {
            throw new StatePreconditionError('Error loading SpotlightState without a valid spotlight', spotlightId.toString());
        }

        this.state.spotlight = Object.assign({}, this.state.spotlight);
        this.state.allowsIg = !this.state.spotlight.no_content;

        if (this.state.spotlight.duration) {
            this.state.duration = this.state.spotlight.duration;
        }

        if (this.state.spotlight.requires_boh_data) {
            this.state.spotlight = await SpotlightService.templateService.populateTemplateSpotlight(this.state.spotlight);
        }

        const check = spotlightValidator(this.state.spotlight);
        if (check !== true) throw new StatePreconditionError('Invalid spotlight', check?.error);

        const backgroundType = this.state.spotlight.background?.type;
        const isVideoFile = backgroundType === 'video';
        const isYoutube = backgroundType === 'youtube';
        const isTypeVideo = isVideoFile || isYoutube;

        if (isTypeVideo && nullOrTrue(this.state.spotlight.background?.audio) && !this.state.spotlight.muted) {
            this.state.soundState.shouldSound = true;
        }

        const isTakeover = 'takeover' === _get(this.state, 'state.takeoverData.behavior', 'script');

        this.setupMediaState(this.state.spotlight.spotlightUrl,
            isVideoFile, isYoutube,
            {
                uid: parseInt(this.state.spotlight.spt_id),
                type: backgroundType,
                track: true,
                campaign_id: this.state.spotlight.campaign_id,
            }, isTakeover && (isVideoFile || isYoutube));

        const isGranularity = this.state.state.metadata?.id.length > 0;

        this.metricData = {
            ...this.metricData,
            id: parseInt(this.state.spotlight.spt_id),
            takeover: isTakeover,
            granularity: isGranularity
        };

        this.state.muted = SettingsService.hasTrueUiSetting('muted')
            || !nullOrTrue(this.state.spotlight.background?.audio)
            || this.state.spotlight.muted;

        this._render();

        return this.state.readyPromise;
    }

    onProgressV2 (progress) {
        const { playedSeconds } = progress;
        //Interrupt if takeover ended and spotlight is ending or starting over
        if (this.isTakeover && !this.videoWillBeInterrupted && ((this.duration - playedSeconds) < 2 || this.playedSeconds > playedSeconds)) {
            this.checkInterruptVideo();
        }

        this.playedSeconds = playedSeconds;
        this.onProgress(progress);
    }

    onDurationV2 (duration) {
        this.duration = duration;
        this.onDuration(duration);
    }

    checkInterruptVideo () {
        const next_takeover_schedule = TakeoverService.getNextTakeOverSchedule();
        const isTakeover = 'takeover' === _get(this.state, 'state.takeoverData.behavior', 'script');
        const play_full_video = _get(this.state, 'state.takeoverData.play_full_video', false);
        const current_schedule_type = _get(this.state, 'state.takeoverData.schedule_type');
        const current_schedule_id = _get(this.state, 'state.takeoverData.id');
        const next_schedule_id = _get(next_takeover_schedule, 'id');
        const next_schedule_type = _get(next_takeover_schedule, 'schedule_type');

        if (typeof play_full_video === 'boolean' && play_full_video && isTakeover &&
            ((next_schedule_id !== current_schedule_id && next_schedule_type === current_schedule_type)
                || (next_schedule_type !== current_schedule_type))) {
            this.videoWillBeInterrupted = true;
            StateService.advanceState();
        }
    }

    _render () {
        const spotlight = _.cloneDeep(this.state.spotlight);
        let loop = this.isTakeover;

        if (spotlight.background && !['youtube', 'color'].includes(spotlight.background.type)) { //Backgrounds that are cacheable
            const teslaURL = teslaCacheXI(spotlight.background.url);
            spotlight.background.url = teslaURL.url;

            if (!teslaURL.from_cache) {
                loop = false;
            }
        }

        const reactPlayerConfig = {};

        if (getDeviceInfo().model === 'Skykit' || window.UPshowAndroidApp) {
            reactPlayerConfig.file = { attributes: { poster: 'https://cdn.upshow.tv/1x1.png' } };
        }
        const brandkit = SettingsService.getSetting('brandKit');

        ReactDOM.render(<UpshowContextProvider>
            <SpotlightPlayerWithVolume
                brandkit={brandkit}
                spotlight={spotlight}
                playing={this.state.playing}
                onPlay={this.raisePlaying}
                onReady={this.raiseReady}
                onPause={this.raisePaused}
                onEnded={this.raiseDone}
                onError={this.raiseError}
                onProgress={this.onProgressV2}
                onDuration={this.onDurationV2}
                muted={this.state.muted}
                loop={loop}
                organizationId={this.organizationId}
                deviceId={this.deviceId}
                useCors={window.sw_enabled}
                reactPlayerConfig={reactPlayerConfig}
                logger={UpshowLogger}
            /></UpshowContextProvider>, this.node);
    }
}

StateFactory.register('spotlight', SpotlightState, 10);

export default SpotlightState;
