import UpshowLogger from '../Logger';
import TrackerService from "./TrackerService";
import {v4 as uuidv4} from 'uuid';

export function testSpeed(uuid, resource = null, resourceLength = null) {
    if (!uuid) uuid = uuidv4();
    if (window.performance === undefined) {
        TrackerService.appEvents.logMetric({action: 'error', error: new Error("Browser does not support Web Performance"), uncaught: false})
        return;
    }

    window.performance.clearResourceTimings();

    const uri = cacheBustResource(!resource ? 'https://cdn.upshow.tv/10MB.jpg' : resource);
    const bytesToDownload = !resourceLength ? 10174706 : resourceLength;

    if (resource && !resourceLength) {
        UpshowLogger.error(['SpeedTest'], 'Speedtest resource provided but resource length not provided.');
        TrackerService.appEvents.logMetric({action: 'error', error: new Error("SpeedTest resource provided but resource length not provided."), uncaught: false})
        return;
    }

    const download = new Image();

    download.onload = function () {
        const perf = window.performance.getEntriesByName(download.src);

        const totalSeconds = perf[0].duration / 1000;
        const totalMBits = bytesToDownload * 8 / 1024 / 1024;

        const speed = totalMBits / totalSeconds;
        const speedTestResult = {
            uuid,
            speed,
            totalSeconds,
            totalMBits,
            resource: download.src
        };

        UpshowLogger.log(['SpeedTest'], {speedTestResult});
        TrackerService.appEvents.logMetric({action: 'speed_test', speedTestResult})
    };

    download.onerror = function (error, msg) {
        TrackerService.appEvents.logMetric({action: 'error', error, uncaught: false})
        console.error('Invalid image, or error downloading', error, msg);
    };

    download.src = uri;
}

const cacheBustResource = (resource) => `${resource}?upbust=${(new Date()).getTime()}`;
