import '../Containers.scss';
import getAnimator from './Animations';
import SettingsService from './SettingsService';
import UpshowLogger from '../Logger';
import FixedContentService from './FixedContentService';

const ContainerService = {
    rootWrapper: document.getElementById('root'),
    createContainer (name, cn) {
        const container = document.createElement('div');
        container.className = 'container hidden';
        container.setAttribute('correlative_number', cn);
        container.setAttribute('state_name', name);
        ContainerService.rootWrapper.appendChild(container);
        return container;
    },
    switchState (current, old) {
        if (SettingsService.hasTrueUiSetting('disable_transitions') ||
            SettingsService.hasTrueUiSetting('spotlight_only') ||
            FixedContentService.fixedContentOn
        ) return ContainerService.fallbackSwitch(current, old);

        let animation_name = current?.state?.state?.animation_name ?? SettingsService.getUiSetting('default_transition_animation', false);
        if (!animation_name) return ContainerService.fallbackSwitch(current, old);

        const animator = getAnimator(animation_name);
        if (!animator) return ContainerService.fallbackSwitch(current, old);

        return animator(current, old)
            .catch(error => {
                UpshowLogger.error(['ContainerService', 'switchState'], error);
                return ContainerService.fallbackSwitch(current, old);
            });
    },
    fallbackSwitch (current, old) {
        if (current.node) {
            current.node.classList.remove('hidden');
        }

        if (old.node) {
            old.node.classList.add('hidden');
        }
    },
};

window.containers = ContainerService;

export default ContainerService;
