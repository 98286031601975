import ReactDOM from 'react-dom';
import React from 'react';
import SettingsService from '../services/SettingsService';
import FixedContent from '@upshow/fixedpanel';
import ScreenService from './ScreenService';
import StateService from './StateService';
import ApplicationService from './ApplicationService';
import SpotlightService from './SpotlightService';
import UpshowLogger from '../Logger';
import * as UP from '../Upshow';
import DisplayRulesService from './DisplayRulesService';

const rootElement = document.getElementById('root');
const fixedContentElement = document.getElementById('fixed-content');

const FixedContentService = {

    campaigns: [],
    fixedContentOn: false,

    loadCampaigns (campaigns) {
        this.campaigns = campaigns.filter(campaign => campaign.metadata?.usages?.includes('FIXED_PANEL'));
    },

    async renderFixedContent () {
        const fixedContent = SettingsService.getSetting('fixedContent');
        let enabled = fixedContent?.enabled && !SettingsService.getUiSetting('disable_fixed_content');

        const currentIsoDate = UP.getCurrentISODate();
        const ruleListValidator = DisplayRulesService.getRulesValidator(currentIsoDate);

        this.fixedContentOn = enabled
            && !ScreenService.isVertical
            && (this.campaigns.length > 0 || fixedContent.use_campaign_hub)
            && SettingsService.getUiSetting('layout', { key: 'fullscreen' }).key === 'fullscreen'
            && ruleListValidator(fixedContent.time_based_display_rules);

        if (this.fixedContentOn) {
            const currentState = StateService.currentStateDef ?? {};
            let isHidden = false;
            switch (currentState.name) {
                case 'socialgrid':
                case 'socialzoom':
                    isHidden = SettingsService.hasFeature('social') && SettingsService.hasTrueSetting('direct_share');
                    break;
                case 'upshownow':
                    isHidden = true;
                    break;
                case 'live_event':
                    isHidden = true;
                    break;
                case 'application':
                    const appInstance = await ApplicationService.getApplicationInstance(currentState.itemId);
                    isHidden = appInstance && appInstance.settings.hide_fixedpanel;
                    break;
                case 'spotlight':
                    const spotlight = await SpotlightService.getSpotlight(currentState.itemId);
                    isHidden = spotlight && spotlight.campaign_id;
                    break;
            }

            const brandKit = SettingsService.getSetting('brandKit');
            const deviceId = SettingsService.getSetting('device_id');
            const organizationId = SettingsService.getSetting('organization_id');

            ReactDOM.render(
                <FixedContent
                    hidden={isHidden}
                    campaignHubURL={process.env.REACT_APP_CAMPAIGN_HUB}
                    brandKit={brandKit}
                    fixedPanelSettings={fixedContent}
                    campaigns={this.campaigns}
                    deviceId={deviceId}
                    organizationId={organizationId}
                    logger={UpshowLogger}
                />,
                fixedContentElement
            );

            if (isHidden) {
                rootElement.classList.remove('scaleDown');
                rootElement.classList.add('scaleUp');
            } else {
                rootElement.classList.remove('scaleUp');
                rootElement.classList.add('scaleDown');
            }
        } else {
            if (rootElement.classList.contains('scaleDown')) {
                rootElement.classList.remove('scaleDown');
                rootElement.classList.add('scaleUp');

            } else if (rootElement.classList.contains('scaleUp')) {
                rootElement.classList.remove('scaleUp');
            }

            ReactDOM.render(<></>, fixedContentElement);
        }
    }
};

export default FixedContentService;
