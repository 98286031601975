import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Textfit } from 'react-textfit';
import SettingsService from '../../../../services/SettingsService';

import './ZoomTextCta.scss';
import DefaultTextCta from '../../DefaultCta/DefaultTextCta/DefaultTextCta';
import { getSocialBrandKitStyle, alternateSocialCTA } from '../../../../libs/helpers';
import SayCheeseCta from '../../SayCheeseCta/SayCheeseCta';

class ZoomTextCta extends React.Component {

    constructor (props) {
        super(props);

        this.operator = SettingsService.getSetting('operator');
        this.lastCtaShown = alternateSocialCTA('lastSocialZoomCtaShown', this.props.hashtags);
    }

    render () {
        const { flipperContainerRef, flipperCtaRef, flipperDescriptionRef, hashtags, headerText, longCta, postDescription } = this.props;
        const { backgroundColor, color } = getSocialBrandKitStyle();
        const qrSize = this.props.isVertical ? 300 : 420;

        return (
            <div className="ZoomTextCta" style={{ backgroundColor, color }}>
                <div className="desc" ref={flipperContainerRef}>
                    {this.lastCtaShown === 'normal'
                        ? <>
                            <Textfit className="caption" ref={el => flipperDescriptionRef(ReactDOM.findDOMNode(el))}>
                                {postDescription.length > 100 ? postDescription.substring(0, 100) + '...' : postDescription}
                            </Textfit>
                            <DefaultTextCta
                                ref={el => flipperCtaRef(ReactDOM.findDOMNode(el))}
                                hidePowered={true}
                                hashtags={hashtags}
                                headerText={headerText}
                                operator={this.operator}
                                longCta={longCta}
                                type="zoom"
                                isAltCta={SettingsService.hasSetting('alt_cta')}
                            />
                        </>
                        : <SayCheeseCta qrSize={qrSize} onError={this.props.onError}/>
                    }

                </div>
                <div className="pwby-logo-container">
                    <div className="pwby-logo"/>
                </div>

            </div>
        );
    }
}

ZoomTextCta.propTypes = {
    postDescription: PropTypes.string,
    headerText: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    hashtags: PropTypes.arrayOf(PropTypes.string).isRequired,
    flipperContainerRef: PropTypes.func,
    flipperCtaRef: PropTypes.func,
    flipperDescriptionRef: PropTypes.func,
    longCta: PropTypes.bool,
};

ZoomTextCta.defaultProps = {
    postDescription: '',
    flipperContainerRef: () => null,
    flipperCtaRef: () => null,
    flipperDescriptionRef: () => null,
    longCta: false,
};

export default ZoomTextCta;