//very basic instrumentation lib. probably replace for something better/not build by me

const metrics = {};

export function inc(metric) {
    if (metrics[metric] === undefined) {
        metrics[metric] = 0;
    }

    return ++metrics[metric];
}

export function dec(metric) {
    if (metrics[metric] === undefined) {
        metrics[metric] = 0;
    }

    return --metrics[metric];
}

export function logMetrics() {
    console.table(metrics);
}