/**
 * A box object.
 * @typedef {Object} Box
 * @property {number} x - The box top left corner 'x' coordinate.
 * @property {number} y - The box top left corner 'y' coordinate.
 * @property {number} w - The box width.
 * @property {number} h - The box height.
 * @property {*} [content] - The box content.
 */

/**
 * A Point object.
 * @typedef {Object} Point
 * @property {number} x - The 'x' coordinate.
 * @property {number} y - The 'y' coordinate.
 */

/**
 * A Size object.
 * @typedef {Object} Size
 * @property {number} w - The width.
 * @property {number} h - The height.
 */

class TileGridService {

    /**
     * @param {Size} gridSize - The tile grid size.
     * @param {number} maxBoxSize - The maximum size for generated boxes.
     */
    constructor(gridSize, maxBoxSize = Math.min(gridSize.w, gridSize.h)) {
        // Store the grid size
        this.gridWidth = gridSize.w;
        this.gridHeight = gridSize.h;

        // Generate a new matrix filled with 'true', meaning that all boxes are available.
        this.availabilityMatrix = Array(this.gridWidth).fill().map(() => Array(this.gridHeight).fill(true));

        // Set the maximum size for the further generated boxes
        this.maxBoxSize = maxBoxSize < 1 ? 1 : maxBoxSize;

        // Here we will store the placed boxes
        this.boxes = [];
    }


    /**
     * Returns an array of points used by a box.
     * @param {Box} box - The box to check.
     * @returns {Point[]} The grid points used by the box.
     */
    static getBoxPoints(box) {
        const boxPoints = [];

        for (let x = box.x; x < box.x + box.w; x++) {
            for (let y = box.y; y < box.y + box.h; y++) {
                boxPoints.push({x, y});
            }
        }

        return boxPoints;
    }

    /**
     * Checks if a box overflows the defined grid
     * @param {Box} box - The box to check
     * @returns {Boolean}
     */
    boxOverflowsGrid(box) {
        if (box.x < 0) {
            // Left overflow
            return true;
        }

        if (box.y < 0) {
            // Top overflow
            return true;
        }

        if (box.x + box.w - 1 > this.gridWidth - 1) {
            // Right overflow
            return true;
        }

        if (box.y + box.h - 1 > this.gridHeight - 1) {
            // Bottom overflow
            return true;
        }

        return false;
    }

    /**
     * Checks if the area of the grid required by a box is available.
     * @param {Box} box - The box to check
     * @returns {Boolean} Whether all the points used by the box are available.
     */
    boxAreaIsAvailable(box) {
        const boxPoints = TileGridService.getBoxPoints(box);

        for (var point of boxPoints) {
            if (this.availabilityMatrix[point.x][point.y] === false) {
                return false;
            }
        }

        return true;
    }

    /**
     * Checks if a box touches a given area.
     * @param {Box} box - The box to check
     * @param {Box} area - The area to check
     * @returns {Boolean} Whether the box touches the area
     */
    boxOverlapsArea(box, area) {
        const boxPoints = TileGridService.getBoxPoints(box);
        const areaPoints = TileGridService.getBoxPoints(area);

        for (var boxPoint of boxPoints) {
            for (var areaPoint of areaPoints) {
                if (boxPoint.x === areaPoint.x && boxPoint.y === areaPoint.y) {
                    return true;
                }
            }
        }

        return false;
    }

    /**
     * Just for debugging purposes.
     */
    printAvailabilityMatrix() {
        let headerString = '    |';

        for (let column = 0; column < this.gridWidth; column++) {
            headerString += ` ${column} |`;
        }

        console.log(headerString);

        for (let y = 0; y < this.gridHeight; y++) {

            let rowString = `| ${y} |`;

            for (let x = 0; x < this.gridWidth; x++) {

                const isAvailable = this.availabilityMatrix[x][y];
                rowString += ` ${isAvailable ? ' ' : 'X'} |`;
            }

            console.log(rowString);
        }
    }

    /**
     * Places the passed box inside the grid.
     * If for any reason the box cannot be placed, it throws the proper error.
     * @param {Box} box - The box to place.
     * @returns {Box} The placed box.
     */
    placeBox(box) {
        // Check if box can be placed
        if (this.boxOverflowsGrid(box)) {
            throw new Error('Box overflows grid');
        }

        if (!this.boxAreaIsAvailable(box)) {
            throw new Error('Box area is not available');
        }

        // Mark the box area as not available
        for (let x = box.x; x < box.x + box.w; x++) {
            for (let y = box.y; y < box.y + box.h; y++) {
                this.availabilityMatrix[x][y] = false;
            }
        }

        // Save the box
        this.boxes.push(box);

        // Return it
        return box;
    }


    /**
     * For a given box size, return all the possible origins for a box of that size,
     * avoiding points that are whether not available or inside the optional disabled area.
     * @param {Size} boxSize - The box size.
     * @param {Box} [disabledArea] - Specific area to avoid.
     * @return {Point[]} An array of points where a box of the specified size can be placed.
     */
    getPossibleOrigins(boxSize, disabledArea) {
        const possibleOrigins = [];

        const {w, h} = boxSize;

        for (let x = 0; x <= this.gridWidth - boxSize.w; x++) {
            for (let y = 0; y <= this.gridHeight - boxSize.h; y++) {

                const box = {x, y, w, h};

                const boxIsAvailable = this.boxAreaIsAvailable(box);
                const avoidsDisabledArea = disabledArea ? !this.boxOverlapsArea(box, disabledArea) : true;

                if (avoidsDisabledArea && boxIsAvailable) {
                    possibleOrigins.push({x, y});
                }

            }
        }

        return possibleOrigins;
    }

    /**
     * Places a box of the specified size on a random available point, avoiding the option disabled area.
     * If the box is too big for the available space it throws an error.
     * @param {Size} boxSize - The box size.
     * @param {*} boxContent - The box content.
     * @param {Box} [disabledArea] - Specific area to avoid.
     * @returns {Box} The placed box.
     */
    placeBoxRandomly(boxSize, boxContent, disabledArea) {
        const possibleOrigins = this.getPossibleOrigins(boxSize, disabledArea);

        if (possibleOrigins.length === 0) {
            throw new Error('No room for that box size');
        } else {
            const origin = possibleOrigins[Math.floor(Math.random() * possibleOrigins.length)];

            const box = {
                x: origin.x,
                y: origin.y,
                w: boxSize.w,
                h: boxSize.h,
                content: boxContent,
            };

            this.placeBox(box);

            return box;
        }
    }

    /**
     * Fills the available points of the grid with square boxes, ignoring the optional disabled area.
     * On the first pass, the size of the generated boxes will be the one specified in the TileGridService constructor.
     * Then, as there's no space available for the current box size, it will decrease it until the size of 1 is hit,
     * stopping when no more points are available.
     *
     * @param {Box} [disabledArea] - Specific area to avoid.
     * @returns {Box[]} The generated boxes.
     */
    fillWithRandomBoxes(disabledArea) {
        const generatedBoxes = [];

        while (this.maxBoxSize >= 1) {
            const possibleOrigins = this.getPossibleOrigins({w: this.maxBoxSize, h: this.maxBoxSize}, disabledArea);

            if (possibleOrigins.length === 0) {
                this.maxBoxSize -= 1;
            } else {
                const origin = possibleOrigins[Math.floor(Math.random() * possibleOrigins.length)];

                const box = {
                    x: origin.x,
                    y: origin.y,
                    w: this.maxBoxSize,
                    h: this.maxBoxSize
                };

                this.placeBox(box);

                generatedBoxes.push(box);
            }
        }

        return generatedBoxes;
    }


}

export default TileGridService;
