import React from 'react';
import ReactDOM from 'react-dom';
import UPshowState from '../UPshowState';
import StateFactory from '../StateFactory';
import EmptyStateComponent from './EmptyStateComponent';


class EmptyState extends UPshowState {

  get name() {
    return 'empty_state';
  }


  preload() {
    ReactDOM.render(<EmptyStateComponent />, this.node);

    return super.preload();
  }

}

StateFactory.register('empty_state', EmptyState, 10);

export default EmptyState;
