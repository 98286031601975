import React from 'react';
import ReactDOM from 'react-dom';
import { default as UPshowNow } from '@upshow/upshownow';
import UPshowState from '../UPshowState';
import StateFactory from '../StateFactory';
import UPshowNowService from '../../services/UPshowNowService';
import MediaService from '../../services/MediaService';
import _ from 'lodash';
import ScreenService from '../../services/ScreenService';
import UpshowLogger from '../../Logger';
import { StatePreconditionError } from '../Errors';
import SettingsService from '../../services/SettingsService';
class UPshowNow2State extends UPshowState {

    get name () {
        return 'upshownow';
    }

    _render (resolve, watchdog = () => null) {

        ReactDOM.render(<UPshowNow
            ref={(el) => { this.component = el; }}
            content={this.state.upshownowContent}
            nextContent={this.state.nextUpshownowContent}
            onMount={(loaddata) => {
                this.raiseReady(loaddata);
                this.raisePlaying(loaddata);
            }}
            onError={this.raiseError}
            onEnd={this.raiseDone}
            title={this.media.channel.title}
            channel_thumbnail={this.media.channel.thumbnail}
            onEnded={this.raiseDone}
            playing={this.state.playing}
            feeds={this.extraData}
            disableIntro={false} //to-do, set this value right
            disableQR={false}
            logError={(message) => UpshowLogger.error(['upshownow', 'version2'], message)}
            logWarning={(message) => UpshowLogger.warn(['upshownow', 'version2'], message)}
            logDebug={(message) => UpshowLogger.debug(['upshownow', 'version2'], message)}
            watchdog={watchdog}
            qrUrl={this.qrUrl}
        />, this.node);

        return resolve;
    }

    async stop () {
        UpshowLogger.info(['UpshowNowState', 'pause'], 'Called stop');
        this.state.playing = false;
        try {
            await this._render(this.handlers.resolvePlay);
            this._pauseDuration();
            this.raisePaused();
        } catch (error) {
            UpshowLogger.info(['UpshowNowState', 'pause'], 'Error - calling raise error');
            this.raiseError(error);
        }

        this.metricData.stop = new Date();
        this.metricData.duration = this.metricData.stop - this.metricData.start;

        return this.state.pausePromise;
    }

    async preload () {
        const defaultSettings = {
            disableIntro: false,

            disableGrid: false,
            gridDuration: 10,

            zoomSteps: 5,
            zoomStepDuration: 12, //Only available for images, NOT video
            disableZoomVideos: false,

            disableOutro: false,
        };
        this.extraData = {};

        const channel_id = this.state.state.itemId;

        if (!channel_id) {
            throw new StatePreconditionError('No UPshowNow channel provided');
        }

        const channel = await UPshowNowService.getChannel(channel_id);

        this.media = { channel };
        if (!this.media) {
            throw new StatePreconditionError('No media for UPshowNow channel');
        }

        if (this.state.state.source === 'scheduled') {
            const [following] = MediaService.getNextMediaItems('script', 'upshownow');
            this.following = following;
        }

        defaultSettings.disableOutro = this.state.state.source !== 'scheduled';
        defaultSettings.disableIntro = this.state.state.source !== 'scheduled';

        this.state.settings = _.defaults(this.state.state, defaultSettings);

        if (this.state.settings.zoomStepDuration <= 0) {
            throw new StatePreconditionError('Invalid settings for zoomStepDuration');
        }

        this.metricData = { ...this.metricData, id: this.media.channel.id };

        this.state.upshownowContent = await UPshowNowService.getChannelContent(this.media.channel);
        if (this.state.upshownowContent.length === 0) {
            throw new StatePreconditionError('No content provided to upshownow');
        }

        if (this.following) {
            this.state.nextUpshownowContent = await UPshowNowService.getChannelContent(this.following);
        }
        const organizationId = SettingsService.getSetting('organization_id');
        this.qrUrl = `${process.env.REACT_APP_UPSHOWNOW_LANDING_URL}?id=${channel_id}&org=${organizationId}`;

        this._render();

        return this.state.readyPromise;
    }

    play () {
        this.metricData.start = new Date();

        const watchdog = (view, time, payload = {}) => {

            UpshowLogger.debug(['upshownow_watchdog', 'version2'], `view: ${view} - time: ${time}`);

            this.clearTimeout('upshownow_watchdog');

            this.setTimeout('upshownow_watchdog', () => {
                UpshowLogger.error(['upshownow', 'timeout'], `Timeout UPSHOWNOW in channel: ${_.get(this, 'media.channel.title')} - view: ${view} - id: ${payload.id} url: ${payload.url}`);
                this.raiseError(new Error('Upshownow timeout'));
            }, time);

        };

        watchdog('play_upshownow_state', 2 * 1000);

        UpshowLogger.info(['upshowstate', 'play'], 'Called play ' + this.name);

        this.state.playing = true;

        if (this.component) {
            //this.component.starrt();
        } else {
            this.raiseError(new Error('ERROR - upshownow tried to play, but component was not created - channel '
                + _.get(this, 'media.channel.title', 'channel_title_not_found')));
        }

        if (_.get(this.state, 'state.takeoverData.behavior') === 'takeover') {
            this.setInterval('checkschedule', () => {
                let takeOverSchedule = MediaService.getActiveItemsByBehavior('takeover', ['upshownow'])[0];
                if (!_.isEqual(_.get(takeOverSchedule, 'channel.id'), _.get(this.state, 'state.takeoverData.channel.id'))) {
                    this.raiseDone('Schedule has finished');
                }
            }, 10000);
        }

        this._render(this.handlers.resolvePlay, watchdog);

        return this.state.playPromise;
    }

    static appliesTo () {
        return !ScreenService.isVertical;
    }

}

StateFactory.register('upshownow', UPshowNow2State, 10);

export default UPshowNow2State;
