import HwFeatureService from '../services/HwFeatureService';
import ScreenService from '../services/ScreenService';
import { $_GET } from '../Upshow';
import _ from 'lodash';

export default () => {
    const overscan = window.overscan || false;
    const fit_to_screen = window.fit_to_screen || false;

    const width = _.get($_GET, 'width', window.innerWidth);
    const height = _.get($_GET, 'height', window.innerHeight);

    ScreenService.setScreenDimensions(width, height);

    let scale = 1;

    if (ScreenService.isVertical) {
        document.body.classList.add('vertical');
    } else {
        document.body.classList.remove('vertical');
    }

    if (ScreenService.screenRatio >= ScreenService.uiRatio) { // Wider or equal
        scale = ScreenService.screenHeight / ScreenService.uiHeight;
    } else { // Narrower
        scale = ScreenService.screenWidth / ScreenService.uiWidth;
    }

    let scaleX = fit_to_screen ? ScreenService.screenWidth / ScreenService.uiWidth : scale;
    let scaleY = fit_to_screen ? ScreenService.screenHeight / ScreenService.uiHeight : scale;

    if (overscan && HwFeatureService.isSupported('overscan')) {
        scaleX *= 0.9;
        scaleY *= 0.9;
    }

    const el = document.body;
    el.style['-ms-zoom'] = scaleX < scaleY ? scaleX : scaleY;

    let offsetX = Math.floor((ScreenService.screenWidth - (scaleX * ScreenService.uiWidth)) / 2);
    let offsetY = Math.floor((ScreenService.screenHeight - (scaleY * ScreenService.uiHeight)) / 2);

    let scaleStr = 'scale(' + scaleX + ',' + scaleY + ')';
    if (offsetX > 0 || offsetY > 0) {
        scaleStr = 'translateX(' + offsetX + 'px) translateY(' + offsetY + 'px) ' + scaleStr;
    }
    el.style['-moz-transform'] = scaleStr;
    el.style['-o-transform'] = scaleStr;
    el.style['-webkit-transform'] = scaleStr;
    el.style['transform'] = scaleStr;
    el.style['transform-origin'] = 'left top';
}
